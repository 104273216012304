import http from '../utils/Http';

export default class AssetInformationService {
  static getAssetInformationAssets() {
    return http.get('/api/asset-information/assets/').then((res) => res.data);
  }

  static updateAssetInformation(assetInformationToUpdate) {
    return http
      .put(`/api/asset-information/${assetInformationToUpdate.assetName}`, assetInformationToUpdate)
      .then((res) => res.data);
  }

  static saveAssetInformationComment(commentToSave) {
    return http.post('/api/asset-information/comment', commentToSave).then((res) => res.data);
  }

  static getAssetInformationComment(commentId) {
    return http.get(`/api/asset-information/comment/${commentId}`).then((res) => res.data);
  }

  static deleteAssetInformationComment(commentId) {
    return http.delete(`/api/asset-information/comment/${commentId}`);
  }

  static getAssetInformation(assetName) {
    return http.get(`/api/asset-information/${assetName}`).then((res) => res.data);
  }

  static saveAssetInformationFile(fileToSave) {
    return http.post(`/api/asset-information/file`, fileToSave).then((res) => res.data);
  }

  static getAssetInformationFileDetails(fileDetails) {
    return http.get(`/api/asset-information/file-details/${fileDetails}`).then((res) => res.data);
  }

  static deleteAssetInformationFileDetails(fileDetailsId, fileDataId) {
    return http.delete(`/api/asset-information/file-details/${fileDetailsId}/fileData/${fileDataId}`);
  }

  static getAssetInformationFileDataPreview(fileDataId) {
    return http.get(`/api/asset-information/file-data/preview/${fileDataId}`).then((res) => res.data);
  }

  static getLatestAssetObservationInformation(assetName) {
    return http.get(`/api/asset-information/latest/observation/${assetName}`);
  }

  static getAssetInformationClassification(level) {
    return http.get(`/api/asset-information/classification/level/${level}`);
  }

  static saveAssetInformationClassification(assetType) {
    return http.post(`/api/asset-information/classification/`, assetType);
  }

  static deleteAssetInformationClassification(classificationId) {
    return http.delete(`/api/asset-information/classification/${classificationId}`);
  }

  static getAssetMode(assetName) {
    return http.get(`/api/asset-information/assets/${assetName}/mode`).then((res) => res.data);
  }
}
