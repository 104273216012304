export default {
  props: {
    textType: {
      type: String,
      default: 'RESET',
    },
  },

  computed: {
    isReset() {
      return this.textType === 'RESET';
    },
    isDelete() {
      return this.textType === 'DELETE';
    },
  },
};
