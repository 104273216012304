<template>
  <button ref="target" :class="buttonClass" :title="title" :disabled="hideContent" @click="handleClick">
    <span :style="{ opacity: hideContent ? 0 : 1 }">
      <slot><span class="ladda-label">Submit</span></slot>
    </span>
  </button>
</template>

<script>
/*global Spinner*/
export default {
  name: 'YfLadda',
  props: {
    buttonClass: {
      type: [String, Object],
      default: 'ladda-button',
    },
    loading: {
      type: Boolean,
      required: true,
    },
    delay: {
      type: Number,
      default: 200,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hideContent: false,
      opts: {
        lines: 11, // The number of lines to draw
        length: 8, // The length of each line
        width: 3, // The line thickness
        radius: 7, // The radius of the inner circle
        //scale: 0.35, // Scales overall size of the spinner
        corners: 1, // Corner roundness (0..1)
        color: '#ffffff', // CSS color or array of colors
        fadeColor: 'transparent', // CSS color or array of colors
        speed: 1, // Rounds per second
        rotate: 0, // The rotation offset
        animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
        direction: 1, // 1: clockwise, -1: counterclockwise
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        className: 'spinner', // The CSS class to assign to the spinner
        //top: '50%', // Top position relative to parent
        //left: '50%', // Left position relative to parent
        //shadow: '0 0 1px transparent', // Box-shadow for the lines
        //position: 'absolute' // Element positioning
      },
    };
  },
  watch: {
    loading: function(loading) {
      if (this.spinner) {
        if (loading) {
          this.hideContent = true;
          this.spinner = new Spinner(this.opts).spin(this.$refs.target);
        } else {
          setTimeout(() => {
            this.spinner.stop();
            this.hideContent = false;
          }, this.delay);
        }
      } else {
        if (loading) {
          this.hideContent = true;

          this.spinner = new Spinner(this.opts).spin(this.$refs.target);
        }
      }
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
