import EventService from '../services/EventService';
import { deepClone, NaturalSortService } from '../utils/Utils';

export default {
  props: {
    condition: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      options: [],
      originalOptions: [],
      isLoading: false,
      debounceTimeout: null,
      addingNewCondition: false,
      conditionRangeTypes: ['DAY', 'HOUR', 'OBSERVATION', 'SUM'],
      rangeOperators: ['LT', 'GT'],
      isValueMultiple: true,
    };
  },

  computed: {
    columnConditions() {
      if (this.condition.column && this.condition.column.conditions) {
        return this.condition.column.conditions;
      }
      return [];
    },
    isAssetColumn() {
      if (!this.condition.column || !this.condition.column.originalDataField) {
        return false;
      }

      return this.condition.column.originalDataField === 'name';
    },
    isCategoryColumn() {
      if (!this.condition.column || !this.condition.column.originalDataField) {
        return false;
      }

      return (
        this.condition.column.originalDataField.includes('group') &&
        this.condition.column.originalDataField.includes('_name')
      );
    },
    isGeofenceAreaColumn() {
      if (!this.condition.column || !this.condition.column.originalDataField) {
        return false;
      }

      return this.condition.column.originalDataField.includes('geofenceArea');
    },
    hasExistingConditionRange() {
      return !!this.condition && !!this.condition.conditionRange;
    },
    isConditionColumnDefined() {
      return this.condition && this.condition.column;
    },
    isAlarmTypeApi() {
      return this.isConditionColumnDefined && this.condition.column.alarmType === 'Api';
    },
    isAlarmTypeSuggestion() {
      return this.isConditionColumnDefined && this.condition.column.alarmType === 'Suggestion';
    },
    isAlarmTypeText() {
      return this.isConditionColumnDefined && this.condition.column.alarmType === 'Text';
    },
    isAlarmTypeSet() {
      return this.isConditionColumnDefined && !!this.condition.column.alarmType;
    },
    isValid() {
      const isValuesValid = Array.isArray(this.condition.values)
        ? !!this.condition.values[0].name
        : !!this.condition.values.name;

      return this.isAlarmTypeSet && !!this.condition.operator.label && isValuesValid;
    },
    placeholder() {
      if (this.isAlarmTypeText) {
        if (this.condition.column.columnType === 'Number') {
          return this.$t('alarmsReports.vue.numberAndTextPlaceholder');
        }

        return this.$t('alarmsReports.vue.stringAndTextPlaceholder');
      }

      if (this.isAlarmTypeApi) {
        return this.$t('alarmsReports.vue.apiPlaceholder');
      }

      if (this.isAlarmTypeSuggestion) {
        return this.$t('alarmsReports.vue.suggestionPlaceholder');
      }

      return '';
    },
  },

  async mounted() {
    this.resetOptions();
    this.removeListIsEmptyLiFromMultiSelects();

    if (this.isCategoryColumn) {
      this.condition.values = this.condition.values.map((value) => {
        return { ...value, name: value.groupName };
      });
    }

    this.condition.values = this.condition.values.map((conditionValue) => {
      const text = conditionValue.text ? conditionValue.text.replace(/\*/g, '') : null;
      const name = conditionValue.name ? conditionValue.name.replace(/\*/g, '') : null;
      const value = conditionValue.value ? conditionValue.value.replace(/\*/g, '') : null;

      return { ...conditionValue, text, name, value };
    });

    this.$nextTick(() => {
      this.onOperatorChange();
      this.checkIfGeofenceAnyIsSelected();
    });
  },

  methods: {
    onTextValueChange() {
      this.$nextTick(() => {
        switch (this.condition.operator.operator) {
          case '<':
          case '<=':
          case '>':
          case '>=':
            this.condition.values = [{ ...this.condition.values }];
            break;
          default:
            break;
        }
      });
    },
    onValueChange() {
      this.$nextTick(() => {
        this.validateAssetIdLimit();

        if (this.isValueMultiple) {
          this.checkIfGeofenceAnyIsSelected();
        } else {
          this.isValueMultiple = true;
          this.condition.values = [{ ...this.condition.values }];
          this.focusOnValue();
        }
      });
    },
    checkIfGeofenceAnyIsSelected() {
      let geofenceFieldAttribute;

      switch (this.condition.column.dataField) {
        case 'geofenceArea':
          geofenceFieldAttribute = 'name';
          break;
        case 'geofenceType':
          geofenceFieldAttribute = 'text';
          break;
        default:
          return;
      }

      const geofenceFieldValueWithAny = this.condition.values.filter((conditionValue) => {
        return conditionValue[geofenceFieldAttribute] === 'generic.any';
      })[0];

      if (geofenceFieldValueWithAny && geofenceFieldValueWithAny[geofenceFieldAttribute]) {
        this.condition.values = [{ ...geofenceFieldValueWithAny }];
        this.blurValue();

        this.$nextTick(() => {
          this.isValueMultiple = false;
        });
      }
    },
    searchChangedForApi(query) {
      if (query && query.trim() !== '') {
        clearTimeout(this.debounceTimeout);

        const tag = {
          groupId: 0,
          groupName: query,
          name: query,
        };

        const multiselectFilteredOptions = this.$refs.valueRefApi.filteredOptions;

        if (multiselectFilteredOptions.some((option) => option.name === tag.name.trim())) {
          this.options = [...this.originalOptions];
        } else {
          this.options = [{ ...tag }, ...this.originalOptions];
        }
      } else {
        this.options = [...this.originalOptions];
      }
    },
    getSuggestionsForQuery(query) {
      this.isLoading = true;

      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        this.getSuggestions(this.condition.column, query);
        this.isLoading = false;
      }, 250);
    },
    async getSuggestions(column, query) {
      const suggestions = await EventService.getColumnSuggestions(column, query);
      const textKey = 'text';

      this.options = NaturalSortService.naturalSortArrayByKey(
        suggestions.filter((suggestion) => suggestion.text !== ''),
        textKey
      );

      if (column.dataField === 'geofenceType') {
        this.options.unshift({
          text: this.$t('generic.any'),
          filters: ['geofenceType=generic.any'],
        });
      }

      this.options = this.options.map((option) => {
        let translateKey = option.filters[0].split('=')[1];

        return {
          text: translateKey.replace(/\*/g, ''),
          filters: option.filters,
        };
      });
    },
    resetOperator() {
      this.showErrors();
      this.isValueMultiple = true;
      this.condition.operator = this.columnConditions[0];
      this.condition.values = [];
      this.resetOptions();
    },
    onOperatorChange() {
      this.$nextTick(() => {
        switch (this.condition.operator.operator) {
          case '<':
          case '<=':
          case '>':
          case '>=':
            this.isValueMultiple = false;
            if (this.condition.values.length > 0) {
              this.condition.values = [{ ...this.condition.values[0] }];
            }
            break;
          default:
            this.isValueMultiple = true;
            break;
        }
      });
    },
    focusOnValue() {
      let el;

      switch (this.condition.column.alarmType) {
        case 'Suggestion':
          el = this.$refs.valueRefSuggestion.$el;
          break;
        case 'Api':
          el = this.$refs.valueRefApi.$el;
          break;
        case 'Text':
        default:
          el = this.$refs.valueRefText.$el;
          break;
      }

      this.$nextTick(() => {
        el.focus();
      });
    },
    blurValue() {
      let el;

      switch (this.condition.column.alarmType) {
        case 'Suggestion':
          el = this.$refs.valueRefSuggestion;
          break;
        case 'Api':
          el = this.$refs.valueRefApi;
          break;
        case 'Text':
        default:
          el = this.$refs.valueRefText;
          break;
      }

      this.$nextTick(() => {
        el.$refs.search.blur();
      });
    },
    async resetOptions() {
      this.options = [];
      this.isLoading = true;
      switch (this.condition.column.alarmType) {
        case 'Suggestion':
          await this.getSuggestions(this.condition.column, '');
          break;

        case 'Api':
          await this.getApiOperationOptions(this.condition.column, '');
          break;

        case 'Text':
        default:
          break;
      }
      this.isLoading = false;
    },
    addTag(newTag) {
      const tag = {
        groupId: 0,
        groupName: newTag,
        name: newTag,
      };

      this.options.push(tag);
      this.condition.values.push(tag);
    },
    async getApiOperationOptions(column) {
      if (this.isAssetColumn) {
        this.options = await EventService.getAssets();
      } else if (this.isCategoryColumn) {
        const categoryIndex = this.getCategoryIndexFromColumn(column);
        const data = await EventService.getCategoryGroups(categoryIndex);
        this.options = data.groups.map((group) => {
          return { ...group, name: group.groupName };
        });
      } else if (this.isGeofenceAreaColumn) {
        const data = await EventService.getGeofenceAreas();
        const dataWithLabel = data.map((item) => ({ ...item, label: item.name }));

        this.options = NaturalSortService.naturalSortArrayByKey(dataWithLabel, 'name');
        this.options.unshift({
          name: 'generic.any',
        });
      }
      this.originalOptions = deepClone(this.options);
    },
    getAlarmTypeApiLabel(item) {
      return this.$t(item.name);
    },
    getCategoryIndexFromColumn(column) {
      let categoryIndex = column.originalDataField.replace('group', '');
      categoryIndex = categoryIndex.replace('_name', '');
      return parseInt(categoryIndex);
    },
    removeListIsEmptyLiFromMultiSelects() {
      const valueRefs = [this.$refs.valueRefSuggestion, this.$refs.valueRefText, this.$refs.valueRefApi];
      const listSelector = 'div.multiselect__content-wrapper ul';
      const listItemLastChildSelector = 'li:last-child';

      valueRefs.forEach((valueRef) => {
        const multiselect = valueRef.$el;
        const parentUl = multiselect.querySelector(listSelector);
        const emptyListItemLi = parentUl.querySelector(listItemLastChildSelector);
        parentUl.removeChild(emptyListItemLi);
      });
    },
    addQueryAsOnlyResult(query) {
      if (query === '') {
        this.options = [];
      } else {
        this.options = [{ value: query }];
      }
    },
    onDelete() {
      this.$emit('delete-condition');
    },
  },
};
