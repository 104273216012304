export default {
  data() {
    return {
      errors: {},
    };
  },

  computed: {
    isValid() {
      //eslint-disable-next-line
      throw new Error(`isValid is not overridden in component ${this.$options.name}`);
    },
  },

  watch: {
    isValid: {
      handler: function() {
        this.showErrors();
        this.$emit('validation-changed', this.isValid);
      },
      immediate: true,
    },
    isSubmitted() {
      this.showErrors();
    },
  },

  props: {
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    showErrors() {
      //eslint-disable-next-line
      throw new Error(`showErrors is not overridden in component ${this.$options.name}`);
    },
  },
};
