import XRegExp from 'xregexp';

export const UNICODE_ALPHANUMERIC_EXTENDED_XRegExp = new XRegExp("^[\\p{L}\\p{Nl}0-9 ',%&#!.-]*$");

export const FILE_PURPOSE = Object.freeze({
  ASSET_IMAGE: 'ASSET_IMAGE',
  FILE_LIST: 'FILE_LIST',
});

export const ACCEPTED_IMAGE_ENDINGS = '.jpg, .jpeg, .png, .gif';

export const ACCEPTED_FILE_ENDINGS = '.txt, .xls, .doc, .docx, .pdf, .xlsx';

export const ACCEPTED_IMAGE_ENDINGS_REGEX = new RegExp(/(jpg|jpeg|png|gif)$/i);

export const ACCEPTED_FILE_ENDINGS_REGEX = new RegExp(/(txt|xls|doc|docx|pdf|xlsx)$/i);

export const PREVIEWABLE_FILE_ENDINGS_REGEX = new RegExp(/(jpg|jpeg|png|gif|txt|pdf)$/i);

export const CLASSIFICATION_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
};

export const alarmDelivery = {
  ALWAYS: 'ALWAYS',
  ONCE: 'ONCE',
  ONCE_PER_DAY: 'ONCE_PER_DAY',
};

export const eventTriggerTypes = {
  OBSERVATION: 'OBS',
  TIME: 'TIME',
};

export const emptyTimeCondition = {
  type: 'TIME',
  column: {
    originalDataField: 'geofenceType',
    alarmType: 'Text',
  },
  operator: {
    operator: 'TIME',
  },
  values: [],
  conditionRange: null,
};

export const emptyCondition = {
  type: 'OBSERVATION',
  column: {},
  operator: {},
  values: [],
  conditionRange: null,
};

export const emptyForTheSameAssetConditionRange = { range: '1', type: 'OBSERVATION' };

export const emptyForAssetsConditionRange = { range: '0', operator: 'EQ', type: 'SUM' };

export const emptyAction = {
  type: 'EMAIL',
  additionalInformation: [],
  subscribers: [],
};

export const defaultAlarm = {
  name: '',
  description: '',
  triggerType: 'OBS',
  timezone: 'Etc/GMT',
  startDate: getDefaultStartDate(),
  endDate: getDefaultEndDate(),
  expiryNotification: false,
  alarmDelivery: 'ALWAYS',
  ifCondition: [{ ...emptyCondition }],
  andConditions: [],
  onlyWithGpsSignal: false,
  actions: [{ ...emptyAction }],
  type: 'ALARM',
  reportDesign: null,
};

export const emptyTimeCriterion = {
  column: {
    originalDataField: 'time',
    alarmType: 'Text',
  },
  operator: {
    operator: 'LATEST_MONTHS',
  },
  values: [{ value: 1 }],
  conditionRange: null,
};

export const emptyReportTimeCondition = {
  type: 'TIME',
  // PLACEHOLDER INFORMATION, do not remove
  column: {
    originalDataField: 'geofenceType',
    alarmType: 'Text',
  },
  operator: {
    operator: 'TIME',
  },
  values: [],
  conditionRange: null,
  timeCriterion: {
    ...emptyTimeCriterion,
  },
};

export const defaultReport = {
  name: '',
  description: '',
  triggerType: 'TIME',
  timezone: 'Etc/GMT',
  startDate: getDefaultStartDate(),
  endDate: getDefaultEndDate(),
  expiryNotification: false,
  alarmDelivery: 'ALWAYS',
  ifCondition: [{ ...emptyReportTimeCondition }],
  andConditions: [[{ ...emptyCondition }]],
  actions: [{ ...emptyAction }],
  type: 'REPORT',
  reportDesign: null,
};

export const eventTypes = {
  REPORT: 'REPORT',
  ALARM: 'ALARM',
};

export const defaultChartTile = {
  type: '',
  title: '',
  xAttribute: null,
  tableAttributes: [],
  limit: 10,
  countMethod: null,
  countAttribute: null,
  sortField: null,
  sortDesc: true,
  geofenceLevel: '1',
  includeTable: false,
  includeMinMax: false,
  rotate: false,
};

export const alphabetically = {
  columnName: 'Alphabetically (by attribute)',
  dataField: 'ALPHABETICALLY',
};

export const numerically = {
  columnName: 'Numerically (by value)',
  dataField: 'NUMERICALLY',
};

export const gridLayoutColumns = 2;
export const gridLayoutRowHeight = 54;
export const defaultGridItemWidth = gridLayoutColumns;
export const defaultGridItemHeight = 11;

export function getDefaultStartDate() {
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getDefaultEndDate() {
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setFullYear(date.getFullYear() + 1);
  return date;
}

export const arrayToFilter = [
  {
    name: 'hello',
    endDate: '2020-01-13',
  },
  {
    name: 'name1',
    endDate: '2020-02-14',
  },
  {
    name: 'name2',
    endDate: '2020-03-15',
  },
  {
    name: 'name3',
    endDate: '2020-04-16',
  },
  {
    name: 'name4',
    endDate: '2020-05-17',
  },
];
