<template>
  <div>
    <div v-show="isOpen" class="popover-overlay" @click="close"></div>

    <div class="wrapper">
      <div ref="popover" class="popover am-flip-x" :class="classes" :style="style">
        <div class="arrow"></div>
        <h3 class="popover-title">{{ title }}</h3>

        <div class="popover-content center-popup">
          <div>
            <button class="btn btn-fm-std inline-block right-buffer" @click="close">{{ $t('value.false') }}</button>
            <button
              class="btn btn-fm-std inline-block ladda-button"
              data-ladda-toggle="pendingRequest"
              data-style="slide-left"
              data-cy="yfPopoverSubmitButton"
              @click="submit()"
            >
              <span class="ladda-label">{{ $t('value.true') }} </span><span class="ladda-spinner"></span>
            </button>
          </div>
        </div>
      </div>

      <div ref="slotWrapper" class="slot-wrapper" @click="toggle">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'YfPopover',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      style: {},
      arrowDirection: 'right',
    };
  },

  computed: {
    classes() {
      const classes = {
        open: this.isOpen,
      };

      classes[this.arrowDirection] = true;

      return classes;
    },
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;

      this.$nextTick(() => {
        if (this.isOpen) {
          const slotRect = this.$refs.slotWrapper.getBoundingClientRect();
          const popoverRect = this.$refs.popover.getBoundingClientRect();

          const headerHeight = 61;
          const popOverHeight = popoverRect.height;
          const popOverWidth = popoverRect.width;

          this.arrowDirection = 'right';

          this.style = {
            position: 'absolute',
            top: slotRect.height / 2 + slotRect.top - headerHeight - popOverHeight / 2 + 'px',
            left: slotRect.right + 'px',
          };

          if (slotRect.right + 400 > window.innerWidth) {
            this.style.left = slotRect.left - popOverWidth + 'px';
            this.arrowDirection = 'left';
          }
        }
      });
    },
    close() {
      this.isOpen = false;
    },
    submit() {
      this.$emit('onSubmit');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  top: 50%;
}

.open {
  display: block;
  visibility: visible;
  overflow: visible;
}

.popover-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.1;
  background: transparent;
}

.popover-title {
  overflow: hidden;
}
</style>
