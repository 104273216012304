<template>
  <div class="report-template-2">
    <div ref="barChart" class="bar-chart">
      A bar chart
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { gridLayoutRowHeight } from '../utils/Constants';

export default {
  name: 'ReportTemplateBarChart',

  props: {
    item: {
      type: Object,
      required: true,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: {},
    };
  },

  watch: {
    resize: {
      handler: function() {
        if (this.item && this.item.h && this.item.w) {
          this.chart.element.svgContainer.htmlElement.style.height = this.item.h * (gridLayoutRowHeight * 1.1) + 'px';
          this.chart.element.svgContainer.htmlElement.style.width = 100 + '%';
        } else {
          this.chart.element.svgContainer.htmlElement.style.height = 300 + 'px';
          this.chart.element.svgContainer.htmlElement.style.width = 100 + '%';
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.initializeChart();
  },

  beforeDestroy() {
    if (this.chart.element) {
      this.chart.element.dispose();
    }
  },

  methods: {
    initializeChart() {
      if (this.item.rotate) {
        this.rotatedBarChart();
      } else {
        this.barChart();
      }
    },

    getTranslation(key) {
      return this.$t(`alarmsReports.vue.tableHeaders.${key}`);
    },

    barChart() {
      let chart = am4core.create(this.$refs.barChart, am4charts.XYChart);
      chart.language.locale['_decimalSeparator'] = ',';
      chart.language.locale['_thousandSeparator'] = ' ';

      chart.data = this.item.data.data;

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'title';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minGridDistance = 5;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.extraMax = 0.2;

      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;

      switch (this.item.countMethod) {
        case 'SUM':
          createSeries({ field: 'value', name: this.getTranslation('sum') });
          break;
        case 'COUNT_UNIQUE':
          createSeries({
            field: 'value',
            name: this.$t('alarmsReports.vue.tableHeaders.countUnique', {
              countAttribute: this.item.countAttribute.columnName,
            }),
          });
          break;
        case 'VISITS':
          createSeries({ field: 'value', name: this.getTranslation('numberOfVisits') });
          break;
        case 'DURATION':
          valueAxis.title.text = this.$t('alarmsReports.vue.days');
          if (this.item.includeMinMax) {
            createSeries({ field: 'min', name: this.getTranslation('minVisitDuration'), fontSize: '9px' });
            createSeries({
              field: 'average',
              name: this.getTranslation('averageVisitDuration'),
              fontSize: '9px',
              valueLabelText: '{valueY} ({visits})',
            });
            createSeries({ field: 'max', name: this.getTranslation('maxVisitDuration'), fontSize: '9px' });
          } else {
            createSeries({
              field: 'average',
              name: this.getTranslation('averageVisitDuration'),
              valueLabelText: '{valueY} ({visits})',
            });
          }
          break;
        case 'OTHER_DURATION':
          valueAxis.title.text = this.$t('alarmsReports.vue.days');
          if (this.item.includeMinMax) {
            createSeries({ field: 'min', name: this.getTranslation('minDuration'), fontSize: '9px' });
            createSeries({
              field: 'average',
              name: this.getTranslation('averageDuration'),
              fontSize: '9px',
              valueLabelText: '{valueY} ({visits})',
            });
            createSeries({ field: 'max', name: this.getTranslation('maxDuration'), fontSize: '9px' });
          } else {
            createSeries({
              field: 'average',
              name: this.getTranslation('averageDuration'),
              valueLabelText: '{valueY} ({visits})',
            });
          }
          break;
      }

      if (this.item && this.item.h && this.item.w) {
        chart.svgContainer.htmlElement.style.height = this.item.h * (gridLayoutRowHeight * 1.1) + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      } else {
        chart.svgContainer.htmlElement.style.height = 300 + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      }

      this.chart.element = chart;

      function createSeries({ field, name, fontSize = '12px', valueLabelText = '{valueY}' }) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = 'title';
        series.dataFields.valueY = field;
        series.dataFields.visits = 'visits';
        series.name = name;

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = valueLabelText;
        valueLabel.label.textAlign = 'start';
        valueLabel.label.horizontalCenter = 'left'
        valueLabel.label.dy = -5;
        valueLabel.label.hideOversized = false;
        valueLabel.label.truncate = false;
        valueLabel.label.fontSize = fontSize;
        valueLabel.label.rotation = -90;
      }
    },

    rotatedBarChart() {
      let chart = am4core.create(this.$refs.barChart, am4charts.XYChart);
      chart.language.locale['_decimalSeparator'] = ',';
      chart.language.locale['_thousandSeparator'] = ' ';

      // large max in valueAxis are not shown correctly, add below padding to make them visible
      chart.paddingRight = 50;
      chart.data = this.item.data.data;

      // Create axes
      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'title';
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;
      categoryAxis.renderer.minGridDistance = 5;

      const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.opposite = true;
      valueAxis.extraMax = 0.2;

      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;

      switch (this.item.countMethod) {
        case 'SUM':
          createSeries({ field: 'value', name: this.getTranslation('sum') });
          break;
        case 'COUNT_UNIQUE':
          createSeries({
            field: 'value',
            name: this.$t('alarmsReports.vue.tableHeaders.countUnique', {
              countAttribute: this.item.countAttribute.columnName,
            }),
          });
          break;
        case 'VISITS':
          createSeries({ field: 'value', name: this.getTranslation('numberOfVisits') });
          break;
        case 'DURATION':
          valueAxis.title.text = this.$t('alarmsReports.vue.days');
          if (this.item.includeMinMax) {
            createSeries({ field: 'min', name: this.getTranslation('minVisitDuration'), fontSize: '9px' });
            createSeries({
              field: 'average',
              name: this.getTranslation('averageVisitDuration'),
              fontSize: '9px',
              valueLabelText: '{valueX} ({visits})',
            });
            createSeries({ field: 'max', name: this.getTranslation('maxVisitDuration'), fontSize: '9px' });
          } else {
            createSeries({
              field: 'average',
              name: this.getTranslation('averageVisitDuration'),
              valueLabelText: '{valueX} ({visits})',
            });
          }
          break;
        case 'OTHER_DURATION':
          valueAxis.title.text = this.$t('alarmsReports.vue.days');
          if (this.item.includeMinMax) {
            createSeries({ field: 'min', name: this.getTranslation('minDuration'), fontSize: '9px' });
            createSeries({
              field: 'average',
              name: this.getTranslation('averageDuration'),
              fontSize: '9px',
              valueLabelText: '{valueX} ({visits})',
            });
            createSeries({ field: 'max', name: this.getTranslation('maxDuration'), fontSize: '9px' });
          } else {
            createSeries({
              field: 'average',
              name: this.getTranslation('averageDuration'),
              valueLabelText: '{valueX} ({visits})',
            });
          }
          break;
      }

      if (this.item && this.item.h && this.item.w) {
        chart.svgContainer.htmlElement.style.height = this.item.h * (gridLayoutRowHeight * 1.1) + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      } else {
        chart.svgContainer.htmlElement.style.height = 500 + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      }

      this.chart.element = chart;

      function createSeries({ field, name, fontSize = '12px', valueLabelText = '{valueX}' }) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = 'title';
        series.dataFields.visits = 'visits';
        series.name = name;
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = true;

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = valueLabelText;
        valueLabel.label.textAlign = 'start';
        valueLabel.label.horizontalCenter = 'left';
        valueLabel.label.dx = 5;
        valueLabel.label.dy = -1;
        valueLabel.label.hideOversized = false;
        valueLabel.label.truncate = false;

        valueLabel.label.fontSize = fontSize;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-chart {
  width: 100%;
  height: 500px;
}
</style>
