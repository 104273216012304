import {
  ACCEPTED_IMAGE_ENDINGS_REGEX,
  PREVIEWABLE_FILE_ENDINGS_REGEX,
  ACCEPTED_FILE_ENDINGS_REGEX,
  CLASSIFICATION_LEVEL,
} from '@/utils/Constants';
import AssetInformationService from '@/services/AssetInformationService';

export default {
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('sv-SE');
    },
    checkPermissions(data = {}) {
      return (
        this.me.accesses.includes('superadmin') || this.me.accesses.includes('admin') || this.me.name === data.authorId
      );
    },
    isFileAllowedImage(fileName) {
      return ACCEPTED_IMAGE_ENDINGS_REGEX.test(fileName.split('.').pop());
    },
    isFileAllowed(fileName) {
      return ACCEPTED_FILE_ENDINGS_REGEX.test(fileName.split('.').pop()) || this.isFileAllowedImage(fileName);
    },
    isFilePreviewable(file) {
      return PREVIEWABLE_FILE_ENDINGS_REGEX.test(file.fileName.split('.').pop());
    },
    async getAssetInformationClassification() {
      const classification1 = await AssetInformationService.getAssetInformationClassification(
        CLASSIFICATION_LEVEL.LEVEL_1
      );
      this.assetClassification.classification1 = classification1.data;
      const classification2 = await AssetInformationService.getAssetInformationClassification(
        CLASSIFICATION_LEVEL.LEVEL_2
      );
      this.assetClassification.classification2 = classification2.data;
      const classification3 = await AssetInformationService.getAssetInformationClassification(
        CLASSIFICATION_LEVEL.LEVEL_3
      );
      this.assetClassification.classification3 = classification3.data;
    },
    formatNumberToThousands(value) {
      if (value === '') {
        return value;
      }
      return new Intl.NumberFormat('sv-SE').format(value.replace(/\s/g, '')).replace(/(?=\s)[^\r\n\t]/g, ' ');
    },
    isPressedKeyNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      const numberCharCodeStart = 48;
      const numberCharCodeEnd = 57;
      if (this.isNumberInRange(charCode, numberCharCodeStart, numberCharCodeEnd)) {
        return true;
      }
      evt.preventDefault();
    },
    isNumberInRange(number, min, max) {
      return number >= min && number <= max;
    },
    isNumberPasteEvent(evt) {
      if (
        !isNaN(
          +evt.clipboardData
            .getData('text')
            .toString()
            .replace(/ /g, '')
        )
      ) {
        return true;
      }
      evt.preventDefault();
    },
  },
};
