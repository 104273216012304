<template>
  <div class="report-template-list row">
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h4 class="panel-title">{{ $t('menu.vue.reportDesigns') }}</h4>
        </div>
        <div class="panel-body">
          <div>
            <div class="row">
              <div class="col-md-2 no-padding">
                <report-template-popover
                  id="createReportTemplatePopover"
                  :report-templates="reportTemplates"
                  @on-submit="showReportTemplateCreateModal"
                >
                  <button
                    id="createReportTemplate"
                    type="reset"
                    class="btn btn-fm-std btn-sm bottom-buffer"
                    :title="$t('generic.new')"
                    data-cy="reportTemplateListNewDesignButton"
                  >
                    <i class="glyphicons glyphicons-plus"></i>
                  </button>
                </report-template-popover>
              </div>
              <div class="col-md-8 text-center fm-pagination-md">
                <yf-paginator :items="filteredTemplates" @paginated-items="updateItems"></yf-paginator>
              </div>
              <div class="col-md-2 text-right total small pull-right">
                <span>
                  <div>{{ $t('vue.generic.found', { x: filteredTemplates.length, y: reportTemplates.length }) }}</div>
                </span>
              </div>
            </div>
            <table
              v-tooltip="deleteEventMessageOptions('alarmsReports.vue.notAllowedToDeleteReportDesign')"
              class="table table-striped table-hover table-condensed table-bordered table-no-break event-table"
              data-cy="reportTemplateListTable"
            >
              <thead>
                <tr>
                  <th class="filter-table-header">
                    <div class="pointer" :title="$t('header.name')" @click="setSort('name')">
                      {{ $t('header.name') }}
                      <span
                        v-if="sort === 'name'"
                        class="fa hidden-print"
                        :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                      >
                      </span>
                    </div>
                    <input
                      v-model="filters['name']"
                      type="text"
                      class="form-control input-sm inline-block"
                      data-cy="reportTemplateListNameSearchInput"
                    />
                    <span v-if="filters['name']" @click="clearFilter('name')">
                      <i class="fa fa-times"></i>
                    </span>
                  </th>
                  <th class="filter-table-header" data-cy="reportTemplateListAuthorSearchWrapper">
                    <div class="pointer" :title="$t('header.createdBy')" @click="setSort('author')">
                      {{ $t('header.createdBy') }}
                      <span
                        v-if="sort === 'author'"
                        class="fa hidden-print"
                        :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                      >
                      </span>
                    </div>
                    <input v-model="filters['author']" type="text" class="form-control input-sm inline-block" />
                    <span v-if="filters['author']" @click="clearFilter('author')">
                      <i class="fa fa-times"></i>
                    </span>
                  </th>
                  <th class="filter-table-header">
                    <div class="pointer" :title="$t('header.editedBy')" @click="setSort('editedBy')">
                      {{ $t('header.editedBy') }}
                      <span
                        v-if="sort === 'editedBy'"
                        class="fa hidden-print"
                        :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                      >
                      </span>
                    </div>
                    <input v-model="filters['editedBy']" type="text" class="form-control input-sm inline-block" />
                    <span v-if="filters['editedBy']" @click="clearFilter('editedBy')">
                      <i class="fa fa-times"></i>
                    </span>
                  </th>
                  <th class="th-min-width"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="filteredReportTemplate in paginatedTemplates" :key="filteredReportTemplate.id">
                  <td>
                    <a
                      class="pointer"
                      :title="filteredReportTemplate.name"
                      @click="showReportTemplateCreateModal(filteredReportTemplate, true)"
                    >
                      {{ filteredReportTemplate.name }}
                    </a>
                  </td>
                  <td data-cy="reportTemplateListAuthor">{{ $t(filteredReportTemplate.author) }}</td>
                  <td data-cy="reportTemplateListEditedBy">{{ $t(filteredReportTemplate.editedBy) }}</td>
                  <td>
                    <yf-popover
                      :title="$t('generic.delete') + ': ' + filteredReportTemplate.name"
                      data-cy="reportTemplateListDeleteTemplateButton"
                      @onSubmit="deleteReportTemplate(filteredReportTemplate)"
                    >
                      <i
                        class="glyphicons glyphicons-bin before-black pointer"
                        :title="$t('generic.delete') + ': ' + filteredReportTemplate.name"
                      ></i>
                    </yf-popover>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <report-template-delete-modal
      v-if="showModal"
      :reports-used="reportsUsed"
      :style="{ display: showModal ? 'block' : 'none' }"
      @close="closeModal"
    />

    <report-template-create-modal
      v-if="reportTemplateCreateModal"
      :style="{ display: reportTemplateCreateModal ? 'block' : 'none' }"
      :existing-report-template="reportTemplate"
      page="TEMPLATE"
      @submit="templateModalSubmitted"
      @close="closeTemplateModal"
    ></report-template-create-modal>
  </div>
</template>
<style lang="scss" scoped>
.filter-table-header {
  & + div {
    width: 100%;
  }

  input {
    width: calc(100% - 15px);
  }
}
</style>
<script>
import EventService from '../services/EventService';
import ReportTemplateDeleteModal from './ReportTemplateDeleteModal.vue';
import YfPopover from '../yf-components/YfPopover';

import YfPaginator from '../yf-components/YfPaginator';
import ReportTemplatePopover from './ReportTemplatePopover';
import ReportTemplateCreateModal from './ReportTemplateCreateModal';
import FilterMixin from '../mixins/FilterMixin';
import EventMixin from '../mixins/EventMixin';

export default {
  name: 'ReportTemplateList',

  components: {
    ReportTemplatePopover,
    YfPopover,
    YfPaginator,
    ReportTemplateDeleteModal,
    ReportTemplateCreateModal,
  },

  mixins: [FilterMixin, EventMixin],

  data() {
    return {
      isLoading: false,
      reportsUsed: [],
      reportTemplates: [],
      reportTemplateCreateModal: false,
      reportTemplate: {},
      paginatedTemplates: [],
    };
  },

  computed: {
    showModal() {
      return (this.reportsUsed || []).length >= 1;
    },
    filteredTemplates: function() {
      return this.filter(this.reportTemplates);
    },
  },

  async created() {
    this.isLoading = true;
    this.reportTemplates = await EventService.getReportDesigns();
    this.isLoading = false;
  },

  methods: {
    closeModal() {
      this.reportsUsed = [];
    },
    async deleteReportTemplate(reportTemplate) {
      try {
        await EventService.deleteReportTemplate(reportTemplate.id);
        if (this.hasFilter) {
          this.filters = {};
        }
        this.reportTemplates = await EventService.getReportDesigns();
      } catch (e) {
        if (e.response.status === 403) {
          this.showDeleteEventMessage();
        }
        this.reportsUsed = e.response.data;
      }
    },
    showReportTemplateCreateModal(reportTemplate, edit) {
      this.reportTemplate = reportTemplate;
      this.reportTemplate.edit = edit;
      this.reportTemplateCreateModal = true;
    },
    async templateModalSubmitted() {
      this.reportTemplateCreateModal = false;
      this.reportTemplates = await EventService.getReportDesigns();
    },
    closeTemplateModal() {
      this.reportTemplateCreateModal = false;
    },
    updateItems(items) {
      this.paginatedTemplates = items;
    },
  },
};
</script>
