import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Alarm from './alarm/Alarm.vue';
import AlarmList from './alarm/AlarmList.vue';
import i18n from './utils/i18n';
import ReportTemplateList from './report-template/ReportTemplateList.vue';
import ReportList from './report/ReportList.vue';
import Report from './report/Report.vue';
import PrintReport from './print-report/PrintReport.vue';
import UserPrintReport from './print-report/UserPrintReport';
import AssetInformation from './asset-information/AssetInformation';
import AssetInformationList from './asset-information/AssetInformationList';
import AssetClassification from './asset-classification/AssetClassification';
import { VTooltip, VClosePopover } from 'v-tooltip';

require('vue-multiselect/dist/vue-multiselect.min.css');
require('./glyphicons/glyphicons.scss');
require('./utils/WkhtmltopdfPolyfill');

Vue.config.productionTip = false;

const router = new VueRouter({
  routes: [
    {
      name: 'ReportList',
      path: '/vue/reports',
      component: ReportList,
    },
    {
      name: 'ReportDetail',
      path: '/vue/reports/:reportId',
      component: Report,
    },
    {
      name: 'ReportDetailWithTemplate',
      path: '/vue/reports/:reportId/templates/:templateId',
      beforeEnter: (to, from, next) => {
        if (!isNaN(Number(to.params.reportId)) && Number(to.params.reportId) === 0) {
          next();
        } else if (!isNaN(Number(to.params.templateId)) && Number(to.params.templateId) === 0) {
          window.location.replace('#/vue/reports/0');
        } else {
          window.location.replace('#/vue/reports/0');
        }
      },
      component: Report,
    },
    {
      name: 'AlarmList',
      path: '/vue/alarms',
      component: AlarmList,
    },
    {
      name: 'AlarmDetail',
      path: '/vue/alarms/:alarmId',
      component: Alarm,
    },
    {
      name: 'AlarmDetailWithTemplate',
      path: '/vue/alarms/:alarmId/templates/:templateId',
      beforeEnter: (to, from, next) => {
        if (!isNaN(Number(to.params.alarmId)) && Number(to.params.alarmId) === 0) {
          next();
        } else if (!isNaN(Number(to.params.scenarioId)) && Number(to.params.scenarioId) === 0) {
          window.location.replace('#/vue/alarms/0');
        } else {
          window.location.replace('#/vue/alarms/0');
        }
      },
      component: Alarm,
    },
    {
      name: 'ReportTemplateList',
      path: '/vue/report-designs',
      component: ReportTemplateList,
    },
    {
      name: 'UserPrintReport',
      path: '/vue/preview/:reportId',
      component: UserPrintReport,
    },
    {
      name: 'PrintReport',
      path: '/vue/print-report/:reportId',
      component: PrintReport,
    },
    {
      name: 'AssetInformationList',
      path: '/vue/asset-information',
      component: AssetInformationList,
    },
    {
      name: 'AssetInformation',
      path: '/vue/asset-information/:assetName',
      component: AssetInformation,
    },
    {
      name: 'AssetClassification',
      path: '/vue/asset-classification',
      component: AssetClassification,
    },
  ],
});

Vue.use(VueRouter);

Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);

new Vue({
  i18n,
  router,
  el: '#app',
  render: (h) => h(App),
});
