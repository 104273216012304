<template>
  <div class="yf-panel-heading-multiselect form-line no-wrap" style="margin: 0">
    <span class="unselectable">
      {{ textBefore }}
    </span>

    <span class="stop-accidental-clicking-on-parent" @click="stopPropagation">
      <multiselect
        v-model="aValue"
        :options="options"
        :custom-label="customLabel"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        open-direction="bottom"
        placeholder=""
        :style="style"
        class="sentance-multiselect"
        data-cy="panel-heading-multiselect"
        @input="onInput"
      >
      </multiselect>
    </span>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'YfPanelHeadingMultiselect',

  components: {
    Multiselect,
  },

  props: {
    textBefore: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    customLabel: {
      type: Function,
      required: true,
    },
    width: {
      type: String,
      default: '100px',
    },
  },

  data() {
    return {
      aValue: this.value,
    };
  },

  computed: {
    style() {
      return {
        width: this.width,
      };
    },
  },

  watch: {
    value() {
      this.aValue = this.value;
    },
  },

  methods: {
    stopPropagation(evt) {
      evt.stopPropagation();
    },
    onInput() {
      this.$emit('input', this.aValue);
    },
  },
};
</script>

<style lang="scss">
.yf-panel-heading-multiselect {
  .unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  // Creates larger surface around the multiselect that prevents toggling of panel
  .stop-accidental-clicking-on-parent {
    margin-top: -10px;
    padding-top: 10px;
    margin-bottom: -15px;
  }

  .sentance-multiselect {
    background: transparent;
    border: none;
    margin-left: 6px;
    margin-right: 6px;

    input {
      color: white !important;
    }

    .multiselect__tags {
      background: transparent;
      border: none !important;
      border-bottom: 2px solid white !important;
      padding-right: 0 !important;
    }

    .multiselect__single {
      background: transparent;
      color: white;
      font-size: 17px !important;
      padding-left: 0;
    }

    .multiselect__select {
      right: -2px;
      top: 2px;
    }

    .multiselect__select:before {
      border-color: white transparent transparent;
    }
  }
}
</style>
