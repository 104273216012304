<template>
  <yf-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        :title="$t('generic.close')"
        @click="closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 id="myModalLabel" class="modal-title">
        <span v-if="oneTime">{{ $t('alarmsReports.vue.oneTimeDesign') }}</span> {{ getModalTitle() }}
      </h4>
    </div>

    <div class="modal-body">
      <div v-if="show" class="row">
        <div class="col-sm-12">
          <div class="form-group" :class="{ 'has-error': (errors.name || errors.duplicateName) && isSubmitted }">
            <input
              id="reportTemplateName"
              ref="reportTemplateName"
              v-model="reportTemplate.name"
              class="report-template-name-input form-control"
              type="text"
              :placeholder="$t('alarmsReports.vue.designName')"
              required
              data-cy="reportTemplateDesignName"
            />
            <span v-if="errors.duplicateName && isSubmitted" class="help-block error-text">
              {{ $t('alarmsReports.vue.reportDesignDuplicateNameErrorText') }}
            </span>
            <span v-if="errors.invalidReportDesignName && isSubmitted" class="help-block error-text">
              {{ $t('settings.bad.name') }}
            </span>
          </div>

          <report-template-tile-create
            v-for="(item, index) in reportTemplate.data"
            :id="`reportTemplateTile${index}`"
            :ref="`reportTemplateTile${index}`"
            :key="item.i"
            :tile="item"
            :available-attributes="availableAttributes"
            :is-submitted="isSubmitted"
            :table-added="reportTemplate.data.some((inlineItem) => inlineItem.type === 'TABLE')"
            :csv-added="reportTemplate.data.some((inlineItem) => inlineItem.type === 'CSV')"
            class="list-item"
            @redraw-chart="redrawReportTemplate(item.i, item.type, item.includeTable)"
            @delete="deleteTile(index)"
            @validation-changed="(isReportTileValid) => $set(reportTemplateTilesValidation, index, isReportTileValid)"
          ></report-template-tile-create>

          <div v-if="reportTemplate.data.length < 6" class="form-group text-center">
            <button type="button" class="btn btn-fm-std" :title="$t('alarmsReports.vue.addBlock')" @click="addTile">
              <i class="glyphicons glyphicons-plus"></i>
              {{ $t('alarmsReports.vue.addBlock') }}
            </button>
          </div>
        </div>

        <div class="col-sm-12">
          <report-template-preview
            v-if="!isPreviewLoading"
            ref="preview"
            :report-tiles="reportTemplate.data"
            :is-editable="true"
            :is-loading="isPreviewLoading"
            style="margin-bottom: 500px;"
          ></report-template-preview>
        </div>
      </div>
      <div v-else class="loader">{{ $t('generic.loading') }}</div>
    </div>

    <div class="modal-footer button-group" :class="{ 'has-more': reportTemplate.data.length > 0 }">
      <yf-ladda
        id="reportTemplateSubmit"
        button-class="btn btn-success col-md-1 pull-right button"
        :loading="isSubmittedLoading"
        :delay="1000"
        type="button"
        :title="$t('generic.save')"
        data-cy="reportTemplateSubmitButton"
        @click="saveReportTemplate"
      >
        <i class="glyphicons glyphicons-ok-2"></i>
      </yf-ladda>

      <button
        id="reportTemplateExit"
        class="btn pull-right col-md-1 btn-danger button pull-left"
        :title="$t('generic.close')"
        @click="closeModal"
      >
        <i class="glyphicons glyphicons-remove-2"></i>
      </button>
    </div>
  </yf-modal>
</template>

<script>
import ReportTemplatePreview from './ReportTemplatePreview';
import ReportTemplateTileCreate from './ReportTemplateTileCreate.vue';
import { deepClone, NaturalSortService } from '../utils/Utils';
import EventService from '../services/EventService';
import {
  defaultChartTile,
  defaultGridItemHeight,
  defaultGridItemWidth,
  UNICODE_ALPHANUMERIC_EXTENDED_XRegExp,
} from '../utils/Constants';
import YfModal from '../yf-components/YfModal';
import YfLadda from '@/yf-components/YfLadda';
import XRegExp from 'xregexp';

const ReportTemplateCreateModalValidationMixin = {
  data() {
    return {
      errors: {},
      isSubmitted: false,
      isSubmittedLoading: false,
      reportTemplateTilesValidation: [],
      existingReportTemplateNames: [],
    };
  },

  computed: {
    reportTemplateName() {
      return this.reportTemplate.name.trim();
    },
    isReportTemplateTilesSet() {
      return this.reportTemplateTilesValidation.length > 0;
    },
    isReportTemplateTilesValid() {
      return this.isReportTemplateTilesSet ? this.reportTemplateTilesValidation.every((isValid) => isValid) : false;
    },
    isValid() {
      return (
        this.reportTemplateName.length >= 1 &&
        this.reportTemplateName.length <= 256 &&
        !this.isNameDuplicate() &&
        this.isReportTemplateTilesValid &&
        this.isReportTitleValid
      );
    },
    isReportTitleValid() {
      return XRegExp.test(this.reportTemplate.name, UNICODE_ALPHANUMERIC_EXTENDED_XRegExp);
    },
  },

  watch: {
    isValid() {
      this.showErrors();
    },
    reportTemplateName() {
      this.showErrors();
    },
  },

  methods: {
    async getReportDesignNames() {
      this.existingReportTemplateNames = await EventService.getReportDesignNames();
    },
    constructValidationArrays() {
      this.reportTemplateTilesValidation = this.reportTemplate.data.map(() => true);
    },
    isNameDuplicate() {
      if (!this.reportTemplate || !this.reportTemplateName) {
        return false;
      }

      const reportTemplateName = this.reportTemplateName.toLowerCase();

      if (this.existingReportTemplate && this.existingReportTemplate.name) {
        const existingReportTemplateName = this.existingReportTemplate.name.toLowerCase();

        if (existingReportTemplateName === reportTemplateName && this.reportTemplate.edit) {
          return false;
        }
      }

      return this.existingReportTemplateNames //
        .map((existingReportTemplateName) => existingReportTemplateName.toLowerCase())
        .some((existingReportTemplateName) => existingReportTemplateName === reportTemplateName);
    },
    showErrors() {
      const errors = {};

      errors.name =
        this.reportTemplateName.length < 1 || this.reportTemplateName.length > 256 || !this.isReportTitleValid;
      errors.duplicateName = this.isNameDuplicate();
      errors.invalidReportDesignName = !this.isReportTitleValid;
      this.errors = errors;
    },
  },
};

export default {
  name: 'ReportTemplateCreateModal',

  components: {
    YfModal,
    YfLadda,
    ReportTemplatePreview,
    ReportTemplateTileCreate,
  },

  mixins: [ReportTemplateCreateModalValidationMixin],

  props: {
    oneTime: {
      type: Boolean,
      default: false,
    },
    existingReportTemplate: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      reportTemplate: {
        name: '',
        data: [],
      },
      resize: false,
      assetObservations: [],
      availableAttributes: [],
      show: true,
      isPreviewLoading: false,
    };
  },

  computed: {
    isCreatedViewWithExistingReportTemplate() {
      return this.existingReportTemplate && this.existingReportTemplate.id > 0 && !this.existingReportTemplate.edit;
    },
    isEdit() {
      return this.existingReportTemplate && this.existingReportTemplate.edit;
    },
  },

  async created() {
    this.show = false;
    this.availableAttributes = await this.getColumnDefinitions();
    await this.getReportDesignNames();
    this.constructValidationArrays();

    if (this.isCreatedViewWithExistingReportTemplate) {
      this.copyExistingReportTemplate();
    } else if (this.isEdit) {
      this.reportTemplate = deepClone(this.existingReportTemplate);

      if (typeof this.reportTemplate.data !== 'object') {
        this.reportTemplate.data = JSON.parse(this.existingReportTemplate.data);
      }
    } else {
      this.addTile();
    }

    this.$nextTick().then(() => {
      this.show = true;
      this.redrawReportTemplate();
    });
  },

  methods: {
    copyExistingReportTemplate() {
      this.reportTemplate = deepClone(this.existingReportTemplate);
      this.reportTemplate.name = `${this.existingReportTemplate.name} - copy`;
      this.reportTemplate.data = JSON.parse(this.existingReportTemplate.data);
      delete this.reportTemplate.id;
    },

    async saveReportTemplate() {
      this.isSubmitted = true;
      this.isSubmittedLoading = true;
      if (this.reportTemplate.data.length === 0) {
        this.addTile();
      }
      this.showErrors();

      if (this.isValid) {
        delete this.reportTemplate.edit;
        this.reportTemplate.oneTime = this.oneTime;

        const reportTemplateToSave = {
          ...this.reportTemplate,
          name: this.reportTemplateName,
          data: this.getSaveableReportTemplateData(),
        };

        this.$emit('submit', await EventService.saveReportDesign(reportTemplateToSave, this.isEdit));
      }
      setTimeout(() => {
        this.isSubmittedLoading = false;
      }, 1000);

      this.scrollToTheElement();
    },

    getSaveableReportTemplateData() {
      return JSON.stringify([
        ...this.reportTemplate.data.map((tile) => {
          const tileToSave = {
            ...tile,
          };

          if (tile.type !== 'TABLE' && tile.type !== 'CSV') {
            delete tileToSave.tableAttributes;
          }

          delete tileToSave.data.data;
          delete tileToSave.data.lineKeys;
          delete tileToSave.moved;

          return tileToSave;
        }),
      ]);
    },

    getModalTitle() {
      if (this.isCreatedViewWithExistingReportTemplate) {
        return this.reportTemplateName;
      } else if (this.isEdit) {
        return this.reportTemplateName;
      } else {
        return this.reportTemplateName !== '' ? this.reportTemplateName : `<${this.$t('generic.name')}>`;
      }
    },

    async getColumnDefinitions() {
      const columnDefinitions = await EventService.getColumnDefinitions();
      const columns = columnDefinitions.columns
        .filter((column) => column.geofenceLevel <= 1)
        .map(this.translateColumnName);

      const groups = [
        'alarmsReports.vue.assetSelections',
        'alarmsReports.vue.geographicalSelections',
        'alarmsReports.vue.customSelections',
        'alarmsReports.vue.stillTimeSelections',
        'alarmsReports.vue.sensorSelections',
        'alarmsReports.vue.otherSelections',
      ];

      return groups.map((group) => {
        let groupColumns = columns.filter((column) => column.groupedBy === group);

        if (group === 'alarmsReports.vue.customSelections') {
          const columnNameKey = 'columnName';
          groupColumns = NaturalSortService.naturalSortArrayByKey(groupColumns, columnNameKey);
        }

        return {
          groupedBy: this.$t(group),
          columns: groupColumns,
        };
      });
    },

    translateColumnName(column) {
      return {
        ...column,
        columnName: this.$t(column.columnName),
      };
    },

    async addTile() {
      const lastTile = this.reportTemplate.data[this.reportTemplate.data.length - 1];
      const newIndex = lastTile ? lastTile.i + 1 : 0;

      const chartTiles = this.reportTemplate.data.filter(
        (tile) =>
          tile.type === 'BAR_CHART' || tile.type === 'LINE_CHART' || tile.type === 'DONUT_CHART' || tile.type === ''
      );

      let maxY = 0;

      if (chartTiles.length > 0) {
        const yValues = chartTiles.map((chartTile) => Number(chartTile.y) + Number(chartTile.h));
        maxY = Math.max(...yValues);
      }

      this.reportTemplate.data.push({
        x: 0,
        y: maxY,
        w: defaultGridItemWidth,
        h: defaultGridItemHeight,
        i: newIndex,
        ...deepClone(defaultChartTile),
        title: this.$t('alarmsReports.vue.defaultBlockTitle') + (newIndex + 1),
      });

      this.reportTemplateTilesValidation.push(null);

      if (this.reportTemplate.data.length === 1) {
        this.isPreviewLoading = true;

        setTimeout(() => {
          this.isPreviewLoading = false;
        }, 100);
      }
    },

    deleteTile(index) {
      this.reportTemplate.data.splice(index, 1);
      this.reportTemplateTilesValidation.splice(index, 1);
    },

    redrawReportTemplate(itemId, itemType) {
      if (this.$refs.preview) {
        if (itemType === 'AGGREGATION_TABLE') {
          this.$refs.preview.redrawTables();
        } else {
          this.$refs.preview.redrawChart(itemId);
          this.$refs.preview.redrawTables();
        }
      }
    },

    closeModal() {
      this.reportTemplate = {
        name: '',
        data: [],
      };

      this.$emit('close');
    },
    scrollToTheElement() {
      if (this.errors.name || this.errors.duplicateName) {
        return this.$refs['reportTemplateName'].scrollIntoView({ behavior: 'smooth' });
      }
      this.$nextTick(() => {
        const tileIndex = this.reportTemplateTilesValidation.findIndex((value) => !value);
        if (tileIndex !== -1) {
          this.$refs[`reportTemplateTile${tileIndex}`][0].$el.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  height: 75vh;
  overflow-y: scroll;
  .print-report {
    width: 100%;
    margin: 0;
  }
}

.has-more {
  box-shadow: 0 -5px 5px -5px #333;
  position: relative;
}

.add-tile-button {
  margin-bottom: 15px;
}

.error-text {
  color: #f04124;
}
</style>
