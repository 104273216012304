<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h4 class="panel-title">
        {{ $t('menu.latest.full') }}
      </h4>
    </div>
    <div class="panel-body latest-observation-zone">
      <div
        v-for="(observation, index) in latestObservations"
        :key="index"
        class="row latest-observation-text-vertical-center has-no-padding-left"
      >
        <a
          class="btn btn-fm margin-bottom margin-right"
          :title="$t('generic.titles.map')"
          :href="'map.html#/' + $route.params.assetName + '/13?observationId=' + observation.assetObservationId"
          target="_blank"
        >
          <i class="glyphicons glyphicons-google-maps"></i>
        </a>
        <div :class="['col-md-11 has-no-padding-right has-no-padding-left', observation.gps ? '' : 'include-text']">
          {{ showObservation(observation) }}
        </div>
      </div>
      <a class="btn btn-fm" :title="$t('menu.all.full')" :href="'#/all?filter=name%3D' + $route.params.assetName">
        {{ $t('menu.all.full') }}
        <i class="glyphicon glyphicon-arrow-right"></i>
      </a>
      <a
        v-if="me.accesses.includes('mileage')"
        class="btn btn-fm margin-all-sides"
        :title="$t('mileage.mileage')"
        :href="'#/mileage?filter=name%3D' + $route.params.assetName"
      >
        {{ $t('mileage.mileage') }}
        <i class="glyphicon glyphicon-arrow-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetInformationLatestObservations',
  props: {
    latestObservations: {
      type: Array,
      required: true,
    },
    me: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    showObservation(observation) {
      if (!observation.town) {
        observation.town = this.$t('assetInformation.vue.latestObservation.noTownName');
      }
      if (!observation.country) {
        observation.country = this.$t('assetInformation.vue.latestObservation.noCountryName');
      }
      return `${observation.timeAsDate} ${observation.timeAsTime.slice(0, -3)} ${observation.town}, ${
        observation.country
      }`;
    },
  },
};
</script>

<style scoped>
.latest-observation-text-vertical-center {
  display: flex;
  align-items: center;
}
.include-text {
  font-style: italic;
}
.margin-all-sides {
  margin: 5px;
}
.margin-right {
  margin-right: 5px;
}
.margin-bottom {
  margin-bottom: 5px;
}
.latest-observation-zone {
  min-height: 255px;
  position: relative;
}
.btn {
  padding: 3px 6px;
}
</style>
