<template>
  <div id="app" class="vue">
    <div class="row">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import EventService from './services/EventService';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

export default {
  name: 'App',

  async created() {
    am4core.options.commercialLicense = true;

    const me = await EventService.getMe();

    Sentry.init({
      dsn: 'https://673bec83a2d84375a9c2ff82c6bcbb12@sentry.io/2117398',
      integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
      environment: me.environment,
    });

    Sentry.setTag('version', me.version);
    Sentry.setTag('node', me.node);
    Sentry.setTag('timezone', me.timezone.id);

    Sentry.configureScope(function(scope) {
      scope.setUser({
        username: me.name,
        email: `${me.name.toLowerCase()}@${me.loginSuffix}`,
      });
    });
  },
};
</script>

<style lang="scss">
@import 'styles/variables';
@import 'styles/v-tooltip';

#app.vue {
  $default-bootstrap-panel-heading-height: 39px;

  .yf-panel-heading-default-panel-style {
    height: $default-bootstrap-panel-heading-height;
    padding-top: 5px;

    .action-close {
      margin-top: -33px;
    }
  }

  .btn-sm-with-text {
    line-height: 20px;
  }

  hr {
    margin-top: $margin;
    margin-bottom: $margin;
  }

  .form-line {
    display: flex;
    margin-bottom: 10px;
    line-height: 28px;

    &.nowrap {
      white-space: nowrap;
    }

    &.flex-end {
      justify-content: flex-end;
    }
  }

  // padding helpers

  .has-no-padding-right {
    padding-right: 0 !important;
  }

  .has-no-padding-left {
    padding-left: 0 !important;
  }

  .has-no-padding {
    padding: 0 !important;
  }

  // margin helpers

  .has-no-margin {
    margin: 0;
  }

  .has-no-margin-bottom {
    margin-bottom: 0;
  }

  .has-margin-left {
    margin-left: $margin;
  }

  .has-small-margin-left {
    margin-left: $small-margin;
  }

  .has-margin-bottom {
    margin-bottom: $margin;
  }

  .vdp-datepicker {
    input {
      background: white;
    }

    input:hover {
      cursor: default;
    }
  }
  .th-min-width {
    width: 1%;
  }

  .event-table {
    margin-bottom: 0;
  }

  .event-general-info-panel-body {
    padding: $padding 0 0 0;
  }

  .action-close {
    margin-top: -28px;
  }

  .action-close.with-select {
    margin-top: -32px;
  }

  .alarm,
  .report {
    margin-bottom: $margin;

    .add-or-condition-button {
      margin-bottom: 21px; // same as bootstrap panel has
    }

    .panel-heading {
      position: relative;
    }

    .configuration-panels {
      .panel-title-select {
        color: black;
      }
    }
  }
}
</style>
