<template>
  <tr>
    <th>
      {{ $t('header.assetId') }}
      <span
        v-if="item.sortField && item.sortField.dataField === 'name'"
        class="fa"
        :class="{ 'fa-chevron-down': item.sortDesc, 'fa-chevron-up': !item.sortDesc }"
      >
      </span>
    </th>
    <th>
      {{ $t('header.date') }}
      <span
        v-if="item.sortField && item.sortField.dataField === 'time'"
        class="fa"
        :class="{ 'fa-chevron-down': item.sortDesc, 'fa-chevron-up': !item.sortDesc }"
      >
      </span>
    </th>
    <th>
      {{ $t('header.time') }}
      <span
        v-if="item.sortField && item.sortField.dataField === 'time'"
        class="fa"
        :class="{ 'fa-chevron-down': item.sortDesc, 'fa-chevron-up': !item.sortDesc }"
      >
      </span>
    </th>
    <th v-for="attribute in attributes" :key="attribute.columnName" class="nowrap">
      {{ $t(attribute.columnName) }}
      <span
        v-if="item.sortField && item.sortField.dataField === attribute.dataField"
        class="fa"
        :class="{ 'fa-chevron-down': item.sortDesc, 'fa-chevron-up': !item.sortDesc }"
      >
      </span>
    </th>
  </tr>
</template>
<script>
export default {
  name: 'ReportTemplatePreviewTableHeader',

  props: {
    item: {
      type: Object,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';

.form-group div[class^='col-'] {
  padding-right: 0;

  &:first-child {
    padding-left: 0;
  }
}

.nowrap {
  white-space: nowrap;
}
</style>
