<template>
  <form class="form-horizontal">
    <div class="form-line nowrap">
      {{ $t('alarmsReports.vue.onEach') }} <event-space></event-space>
      <multiselect
        v-model="freq"
        :options="frequencies"
        :allow-empty="false"
        :custom-label="getFrequencyLabel"
        placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
        @input="reset"
      ></multiselect>

      <span v-show="isWeeklyFrequency"
        ><event-space></event-space> {{ $t('alarmsReports.vue.on') }} <event-space></event-space>
      </span>

      <multiselect
        v-show="isWeeklyFrequency"
        v-model="byweekday"
        :class="{ 'has-error': errors.byweekday && isSubmitted }"
        class="inline"
        :options="weekdays"
        :custom-label="getWeekdayLabel"
        track-by="weekday"
        :multiple="true"
        :taggable="false"
        :clear-on-select="false"
        :close-on-select="false"
        :hide-selected="false"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        @open="resetWeekdaysOnOpen"
        @input="sortWeekDays"
      >
        <template slot="selection" slot-scope="{ values }">
          <span v-if="values.length === 7" class="multiselect__single">
            {{ $t('alarmsReports.vue.allDays') }}
          </span>

          <span v-else-if="values.length" class="multiselect__single">
            {{ values.map((val) => getWeekdayLabel(val)).join(', ') }}
          </span>
        </template>
      </multiselect>

      <span v-show="isMonthlyFrequency">
        <event-space></event-space> {{ $t('alarmsReports.vue.onThe') }} <event-space></event-space>
      </span>

      <multiselect
        v-show="isMonthlyFrequency"
        v-model="bysetpos"
        class="inline"
        :options="possiblePositions"
        :allow-empty="false"
        :custom-label="getSetPosLabel"
        placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
      ></multiselect>

      <event-space></event-space>

      <multiselect
        v-show="isMonthlyFrequency"
        v-model="byweekday"
        class="inline"
        :options="weekdays"
        track-by="weekday"
        :allow-empty="false"
        :custom-label="getWeekdayLabel"
        placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
        @input="byweekday = [byweekday]"
      ></multiselect>
    </div>

    <div class="form-line nowrap">
      {{ $t('alarmsReports.vue.at') }} <event-space></event-space>

      <select v-model="byhour[0]" class="time-select">
        <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
      </select>

      <event-space></event-space> : <event-space></event-space>

      <select v-model="byminute[0]" class="time-select">
        <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
      </select>

      <event-space></event-space> {{ $t('alarmsReports.vue.oClock') }}
    </div>

    <div :class="basedOnDataWrapper">
      {{ $t('alarmsReports.vue.withDataForThe') }}

      <event-space></event-space>
      <event-space></event-space>

      <input
        v-model="timeCondition.timeCriterion.values[0].value"
        class="form-control"
        type="number"
        min="1"
        :max="basedOnDataMaxValue(basedOnDataOperator)"
        :title="basedOnDataTitle"
        :disabled="timeCondition.timeCriterion.operator.operator === 'LATEST'"
        style="width: 100px;"
      />

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        v-model="timeCondition.timeCriterion.operator.operator"
        class="inline"
        :options="timeCriterionOptions"
        placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
        :allow-empty="false"
        open-direction="bottom"
        :custom-label="getTimeCriterionOptionLabel"
        style="width: 200px;"
        @input="timeCriterionOptionsChange"
      ></multiselect>
    </div>

    <div class="form-line flex-end">
      <small @click.prevent="$emit('delete-block')">
        <a v-show="isReset" href="#" :title="$t('alarmsReports.vue.resetTimeCondition')">{{
          $t('alarmsReports.vue.resetTimeCondition')
        }}</a>
        <a v-show="isDelete" href="#" :title="$t('alarmsReports.vue.deleteTimeCondition')">{{
          $t('alarmsReports.vue.deleteTimeCondition')
        }}</a>
      </small>
    </div>
  </form>
</template>

<script>
import { RRule } from 'rrule';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import EventSpace from '../event/EventSpace';
import Multiselect from 'vue-multiselect';
import EventBlockTextTypeMixin from '../mixins/EventBlockTextTypeMixin';
import { deepClone, NaturalSortService } from '../utils/Utils';

export default {
  name: 'ReportTimeConditionBlock',

  components: {
    EventSpace,
    Multiselect,
  },

  mixins: [EventBlockValidationMixin, EventBlockTextTypeMixin],

  props: {
    timeCondition: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      freq: RRule.MONTHLY,
      interval: 1,
      byhour: ['00'],
      byminute: ['00'],
      bysetpos: [1],
      byweekday: [RRule.MO],

      frequencies: [RRule.MONTHLY, RRule.WEEKLY],
      possiblePositions: [1, 2, 3, 4, -1],
      weekdays: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
      hours: [],
      minutes: [],
      timeCriterionOptions: ['LATEST_HOURS', 'LATEST_DAYS', 'LATEST_WEEKS', 'LATEST_MONTHS', 'LATEST'],
      basedOnDataValueError: false,
    };
  },

  computed: {
    basedOnDataWrapper() {
      let classes = ['form-line'];
      if (this.basedOnDataValueError && this.isSubmitted) {
        classes.push('has-error');
      }
      return classes;
    },
    basedOnDataValue() {
      return this.timeCondition.timeCriterion.values[0].value;
    },
    basedOnDataOperator() {
      return this.timeCondition.timeCriterion.operator.operator;
    },
    basedOnDataTitle() {
      const mouseoverText = this.$t('alarmsReports.vue.reportBasedOnDataValue', {
        number: this.basedOnDataMaxValue(this.basedOnDataOperator),
      });
      return this.basedOnDataValueError ? mouseoverText : '';
    },
    isMonthlyFrequency() {
      return this.freq === RRule.MONTHLY;
    },
    isWeeklyFrequency() {
      return this.freq === RRule.WEEKLY;
    },
    options() {
      return {
        freq: this.freq,
        interval: this.interval,
        byhour: this.byhour,
        byminute: this.byminute,
        bysetpos: this.bysetpos,
        byweekday: this.byweekday,
      };
    },
    isValid() {
      const byweekday = this.byweekday.length > 0;

      return (
        !this.basedOnDataValueError &&
        !!this.freq &&
        !!this.interval &&
        this.byhour.length > 0 &&
        this.byminute.length > 0 &&
        byweekday &&
        this.timeCondition.values.length > 0 &&
        !!this.timeCondition.timeCriterion.values[0].value &&
        !!this.timeCondition.timeCriterion.operator.operator
      );
    },
  },

  watch: {
    options: {
      handler() {
        if (this.timeCondition) {
          this.timeCondition.values = [{ value: new RRule({ ...this.options }).toString() }];
        }
      },
      deep: true,
    },
    basedOnDataValue(newValue) {
      this.basedOnDataValueError = !this.isBasedOnDataValueInRange(this.basedOnDataOperator, newValue);
    },
    basedOnDataOperator(newValue) {
      this.basedOnDataValueError = !this.isBasedOnDataValueInRange(newValue, this.basedOnDataValue);
    },
  },

  mounted() {
    this.generateHours();
    this.generateMinutes();

    if (this.timeCondition) {
      if (this.timeCondition.values && this.timeCondition.values.length > 0) {
        this.basedOnDataValueError = !this.isBasedOnDataValueInRange(this.basedOnDataOperator, this.basedOnDataValue);

        const rrule = RRule.fromString(this.timeCondition.values[0].value);
        const rruleOptions = rrule.options;
        let { freq, interval, byhour, byminute, bysetpos, byweekday } = rruleOptions;

        if (byhour[0] < 10) {
          byhour[0] = `0${byhour[0]}`;
        }

        if (byminute[0] < 10) {
          byminute[0] = `0${byminute[0]}`;
        }

        byweekday = this.convertWeekDay(byweekday);

        this.freq = freq;
        this.interval = interval;
        this.byhour = byhour;
        this.byminute = byminute;
        this.bysetpos = bysetpos;
        this.byweekday = byweekday;
      } else {
        this.timeCondition.values = [{ value: new RRule({ ...this.options }).toString() }];
      }
    }

    this.sortWeekDays();
  },
  methods: {
    isBasedOnDataValueInRange(operator, basedOnDataValue) {
      const maxValue = this.basedOnDataMaxValue(operator);
      return basedOnDataValue <= maxValue && basedOnDataValue >= 1;
    },
    basedOnDataMaxValue(basedOnDataOperator) {
      switch (basedOnDataOperator) {
        case 'LATEST_HOURS':
          return 336;
        case 'LATEST_DAYS':
          return 365;
        case 'LATEST_WEEKS':
          return 52;
        case 'LATEST_MONTHS':
          return 12;
        default:
          return 100;
      }
    },
    sortWeekDays() {
      this.byweekday = NaturalSortService.naturalSortNumberArrayByKey(this.byweekday, 'weekday');
    },
    showErrors() {
      const errors = {};

      errors.byweekday = this.isWeeklyFrequency && this.byweekday.length === 0;

      this.errors = errors;
    },
    convertWeekDay(byweekday) {
      return byweekday.map((weekday) => this.weekdays[weekday]);
    },
    getFrequencyLabel(frequency) {
      switch (frequency) {
        case RRule.WEEKLY:
          return this.$t('alarmsReports.vue.WEEKLY');

        case RRule.MONTHLY:
        default:
          return this.$t('alarmsReports.vue.MONTHLY');
      }
    },
    getSetPosLabel(setPos) {
      switch (setPos) {
        case 1:
          return this.$t('alarmsReports.vue.FIRST');

        case 2:
          return this.$t('alarmsReports.vue.SECOND');

        case 3:
          return this.$t('alarmsReports.vue.THIRD');

        case 4:
          return this.$t('alarmsReports.vue.FOURTH');

        case -1:
          return this.$t('alarmsReports.vue.LAST');
      }
    },
    getWeekdayLabel(weekday) {
      return this.$t(`alarmsReports.vue.${weekday}`);
    },
    getTimeCriterionOptionLabel(option) {
      return this.$t(`alarmsReports.vue.${option}`);
    },
    generateHours() {
      const hoursInADay = 24;
      this.hours = [];
      for (let i = 0; i < hoursInADay; i++) {
        let hour = i;

        if (i < 10) {
          hour = `0${i}`;
        }

        this.hours.push(hour);
      }
    },
    generateMinutes() {
      const minutesInAnHour = 59;
      const step = 5;
      this.minutes = [];

      for (let i = 0; i < minutesInAnHour; i += step) {
        let minute = i;

        if (i < 10) {
          minute = `0${i}`;
        }

        this.minutes.push(minute);
      }
    },
    reset() {
      this.showErrors();
      if (this.isMonthlyFrequency) {
        this.interval = 1;
        this.bysetpos = [1];
        this.byweekday = [RRule.MO];
      } else if (this.isWeeklyFrequency) {
        this.interval = 1;
        this.bysetpos = [];
        this.byweekday = [];
      }
    },
    timeCriterionOptionsChange() {
      if (this.timeCondition.timeCriterion.operator.operator === 'LATEST') {
        this.timeCondition.timeCriterion.values[0].value = 1;
      }
    },
    resetWeekdaysOnOpen() {
      const byweekdayCopy = deepClone(this.byweekday);
      this.byweekday = [];

      this.$nextTick(() => {
        this.byweekday = byweekdayCopy.map((day) => this.weekdays[day.weekday]);
        this.sortWeekDays();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.time-select {
  height: 30px;
}
</style>
