import EventService from '../services/EventService';
import { NaturalSortService, timezones } from '../utils/Utils';

export default {
  data() {
    return {
      columns: [],
      timezones: timezones(),
      hideTimeConditions: false,
      generalInformationValidation: null,
      ifConditionsValidation: [null],
      andConditionsValidation: [],
      actionsValidation: [],
      isSubmitted: false,
      deleteEvent: {
        messageOpen: false,
        timeoutTriggered: false,
      },
    };
  },

  computed: {
    isIfConditionSet() {
      return this.ifConditionsValidation.length > 0;
    },
    isAndConditionsSet() {
      return this.andConditionsValidation.length > 0;
    },
    isActionsSet() {
      return this.actionsValidation.length > 0;
    },
    isIfConditionsValid() {
      return this.isIfConditionSet ? this.ifConditionsValidation.every((isValid) => isValid) : false;
    },
    isAndConditionsValid() {
      return this.isAndConditionsSet
        ? this.andConditionsValidation.every((andCondition) => andCondition.every((isValid) => isValid))
        : true;
    },
    isActionsValid() {
      return this.isActionsSet ? this.actionsValidation.every((isValid) => isValid) : false;
    },
    isValid() {
      return (
        !!this.generalInformationValidation &&
        this.isIfConditionsValid &&
        this.isAndConditionsValid &&
        this.isActionsValid
      );
    },
  },

  methods: {
    isAndConditionValid(index) {
      return this.andConditionsValidation[index].every((isValid) => isValid);
    },
    async getColumnDefinitions() {
      try {
        const columnDefinitions = await EventService.getColumnDefinitions();
        const columns = columnDefinitions.columns
          .filter((column) => column.geofenceLevel <= 1)
          .map(this.translateColumnName);

        const groups = [
          'alarmsReports.vue.assetSelections',
          'alarmsReports.vue.geographicalSelections',
          'alarmsReports.vue.customSelections',
          'alarmsReports.vue.stillTimeSelections',
          'alarmsReports.vue.sensorSelections',
          'alarmsReports.vue.otherSelections',
        ];

        this.columns = groups.map((group) => {
          let groupColumns = columns
            .filter((column) => column.groupedBy === group)
            .filter((column) => column.dataField !== 'longLat');

          if (group === 'alarmsReports.vue.customSelections') {
            const columnNameKey = 'columnName';
            groupColumns = NaturalSortService.naturalSortArrayByKey(groupColumns, columnNameKey);
          }

          return {
            groupedBy: this.$t(group),
            columns: groupColumns,
          };
        });

        //eslint-disable-next-line
      } catch (err) {}
    },
    translateColumnName(column) {
      return {
        ...column,
        columnName: this.$t(column.columnName),
      };
    },
    constructValidationArrays(event) {
      this.generalInformationValidation = true;
      this.ifConditionsValidation = event.ifCondition.map(() => true);
      this.andConditionsValidation = event.andConditions.map((andCondition) => andCondition.map(() => true));
      this.actionsValidation = event.actions.map(() => true);
    },
    constructInitialValidationArrays(event) {
      this.generalInformationValidation = false;
      this.ifConditionsValidation = event.ifCondition.map(() => null);
      this.andConditionsValidation = event.andConditions.map((andCondition) => andCondition.map(() => null));
      this.actionsValidation = event.actions.map(() => null);
    },
    setGeneralInformationValidation(isValid) {
      this.generalInformationValidation = isValid;
    },
    deleteEventMessageOptions(messageKey) {
      return {
        content: this.$t(messageKey),
        show: this.deleteEvent.messageOpen,
        trigger: 'manual',
        placement: 'top',
        classes: 'tooltip-info',
        offset: '10',
      };
    },
    showDeleteEventMessage() {
      this.deleteEvent.messageOpen = true;
      if (!this.deleteEvent.timeoutTriggered) {
        this.deleteEvent.timeoutTriggered = true;
        setTimeout(() => {
          this.deleteEvent.messageOpen = false;
          this.deleteEvent.timeoutTriggered = false;
        }, 6000);
      }
    },
  },
};
