<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h4 class="panel-title">
        {{ $t('assetInformation.vue.detailedInformation') }}
      </h4>
    </div>
    <div class="panel-body">
      <form class="asset-info-zone" data-cy="generalInfoForm" @submit.prevent="saveGeneralInfo">
        <div v-if="loading">
          <div class="loader">{{ $t('generic.loading') }}</div>
        </div>
        <div v-else>
          <div class="col-md-4 has-no-padding-left padding-right">
            <label>
              {{ $t('assetInformation.vue.assetClassification1') }}
            </label>
            <div :class="isClassification1Disabled ? 'multiselect-wrapper-disabled' : ''">
              <multiselect
                v-model="generalInfo.assetClassification1"
                :options="assetClassification.classification1"
                :disabled="isClassification1Disabled"
                label="classification"
                track-by="classification"
                :allow-empty="true"
                select-label=""
                selected-label=""
                deselect-label=""
                placeholder=""
                open-direction="bottom"
                style="flex: 2 0 0"
                data-cy="generalInfoSelectClassification1"
                @input="onAssetClassification1Input"
              >
              </multiselect>
            </div>

            <label>
              {{ $t('assetInformation.vue.assetClassification2') }}
            </label>
            <div :class="isClassification2Disabled ? 'multiselect-wrapper-disabled' : ''">
              <multiselect
                v-model="generalInfo.assetClassification2"
                :options="localAssetClassification2"
                :disabled="isClassification2Disabled"
                label="classification"
                track-by="classification"
                :allow-empty="true"
                select-label=""
                selected-label=""
                deselect-label=""
                placeholder=""
                open-direction="bottom"
                style="flex: 2 0 0"
                data-cy="generalInfoSelectClassification2"
                @input="onAssetClassification2Input"
              >
              </multiselect>
            </div>

            <label>
              {{ $t('assetInformation.vue.assetClassification3') }}
            </label>
            <div :class="isClassification3Disabled ? 'multiselect-wrapper-disabled' : ''">
              <multiselect
                v-model="generalInfo.assetClassification3"
                :options="localAssetClassification3"
                :disabled="isClassification3Disabled"
                label="classification"
                track-by="classification"
                :allow-empty="true"
                select-label=""
                selected-label=""
                deselect-label=""
                placeholder=""
                open-direction="bottom"
                style="flex: 2 0 0"
                data-cy="generalInfoSelectClassification3"
              >
              </multiselect>
            </div>
          </div>

          <div class="col-md-4 has-no-padding-left padding-right">
            <label>
              {{ $t('assetInformation.vue.owner') }}
            </label>
            <input
              v-model="generalInfo.owner"
              :disabled="isDisabled"
              type="text"
              class="form-control"
              :placeholder="$t('generic.maxLength32')"
              maxlength="32"
              data-cy="generalInfoOwnerInput"
            />

            <label>
              {{ $t('assetInformation.vue.manufacturer') }}
            </label>
            <input
              v-model="generalInfo.manufacturer"
              :disabled="isDisabled"
              type="text"
              class="form-control"
              :placeholder="$t('generic.maxLength32')"
              maxlength="32"
              data-cy="generalInfoManufacturerInput"
            />

            <label>
              {{ $t('assetInformation.vue.product') }}
            </label>
            <input
              v-model="generalInfo.product"
              :disabled="isDisabled"
              type="text"
              class="form-control"
              :placeholder="$t('generic.maxLength32')"
              maxlength="32"
              data-cy="generalInfoProductInput"
            />
          </div>

          <div class="col-md-4 has-no-padding-left has-no-padding-right">
            <label>
              {{ $t('assetInformation.vue.tareWeight') }}
            </label>
            <input
              v-model="generalInfo.tareWeight"
              :disabled="isDisabled"
              type="text"
              class="form-control"
              maxlength="12"
              data-cy="generalInfoTareWeightInput"
              @keypress="isPressedKeyNumber"
              @paste="isNumberPasteEvent"
            />

            <label>
              {{ $t('assetInformation.vue.maximumGrossWeight') }}
            </label>
            <input
              v-model="generalInfo.maxGrossWeight"
              :disabled="isDisabled"
              type="text"
              class="form-control"
              maxlength="12"
              data-cy="generalInfoMaxGrossWeightInput"
              @keypress="isPressedKeyNumber"
              @paste="isNumberPasteEvent"
            />
          </div>

          <div class="col-md-2 has-no-padding-left padding-right">
            <label>
              {{ $t('assetInformation.vue.buildYear') }}
            </label>
            <input
              v-model="generalInfo.buildYear"
              :disabled="isDisabled"
              type="text"
              :class="['form-control', errorList.buildYearError ? 'error-border' : '']"
              placeholder="YYYY"
              maxlength="4"
              data-cy="generalInfoBuildYearInput"
              @keypress="isPressedKeyNumber"
              @paste.prevent
            />
          </div>

          <div class="col-md-2 has-no-padding-left has-no-padding-right">
            <label>
              {{ $t('assetInformation.vue.nextRevision') }}
            </label>
            <v-popover trigger="manual" :open="isNextRevisionError()" offset="16" :auto-hide="false" placement="top">
              <div data-cy="nextRevisionWrapper" :class="nextRevisionWrapperClass">
                <div class="year">
                  <input
                    ref="year"
                    v-model="nextRevisionYear"
                    class="form-control"
                    type="text"
                    placeholder="YYYY"
                    maxlength="4"
                    :disabled="isDisabled"
                    data-cy="nextRevisionYearInput"
                    @keypress="isPressedKeyNumber"
                    @paste.prevent
                  />
                </div>
                <div class="dash">
                  <input class="form-control" type="text" value="-" disabled />
                </div>
                <div class="month">
                  <input
                    ref="month"
                    v-model="nextRevisionMonth"
                    class="form-control"
                    type="text"
                    placeholder="MM"
                    maxlength="2"
                    :disabled="isDisabled"
                    data-cy="nextRevisionMonthInput"
                    @keydown="monthKeyDownEvent"
                    @paste.prevent
                  />
                </div>
              </div>
              <template slot="popover">
                <div class="popover-title" data-cy="nextRevisionErrorMessage">
                  {{ $t('assetInformation.vue.nextRevisionErrorTitle') }}
                </div>
                <div class="popover-content">
                  <ul>
                    <li v-for="item in nextRevisionErrorContent" :key="item.id">{{ item }}</li>
                  </ul>
                </div>
              </template>
            </v-popover>
          </div>

          <div v-if="!isDisabled" class="has-no-padding-left has-no-padding-right pull-right margin-top">
            <button
              :disabled="isError()"
              class="btn btn-success"
              :title="$t('assetInformation.vue.saveAssetInformation')"
              data-cy="generalInfoSubmitButton"
            >
              <i class="glyphicons glyphicons-ok-2"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AssetInformationMixin from '@/mixins/AssetInformationMixin';
import { VPopover } from 'v-tooltip';

export default {
  name: 'AssetInformationGeneralInfo',
  components: {
    Multiselect,
    VPopover,
  },
  mixins: [AssetInformationMixin],
  props: {
    generalInfo: {
      type: Object,
      required: true,
    },
    assetClassification: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      localAssetClassification2: [],
      localAssetClassification3: [],
      errorList: {
        buildYearError: false,
        nextRevisionError: {
          year: false,
          month: false,
        },
      },
      nextRevisionYear: '',
      nextRevisionMonth: '',
    };
  },
  computed: {
    getAssetClassification1Id() {
      return this.generalInfo.assetClassification1 ? this.generalInfo.assetClassification1.id : null;
    },
    getAssetClassification2Id() {
      return this.generalInfo.assetClassification2 ? this.generalInfo.assetClassification2.id : null;
    },
    getAssetClassification3Id() {
      return this.generalInfo.assetClassification3 ? this.generalInfo.assetClassification3.id : null;
    },
    isDisabled() {
      return this.permissions.isReadOnly || this.permissions.isDisabled;
    },
    isClassification1Disabled() {
      return this.isDisabled;
    },
    isClassification2Disabled() {
      return this.localAssetClassification2.length === 0 || this.isDisabled;
    },
    isClassification3Disabled() {
      return this.localAssetClassification3.length === 0 || this.isDisabled;
    },
    formatNextRevisionField() {
      let nextRevision = null;
      const fieldLength = this.nextRevisionYear.length + this.nextRevisionMonth.length;
      switch (fieldLength) {
        case 4:
          nextRevision = this.nextRevisionYear;
          break;
        case 6:
          nextRevision = `${this.nextRevisionYear}-${this.nextRevisionMonth}`;
          break;
      }
      return nextRevision;
    },
    nextRevisionErrorContent() {
      return this.$t('assetInformation.vue.nextRevisionErrorContent')
        .toString()
        .split('/');
    },
    nextRevisionWrapperClass() {
      let classes = ['tooltip-target', 'next-revision-wrapper'];
      if (this.isNextRevisionError()) {
        classes.push('error-border');
      }
      return classes;
    },
  },
  watch: {
    'generalInfo.tareWeight': function(newValue) {
      this.generalInfo.tareWeight = this.formatNumberToThousands(newValue);
    },
    'generalInfo.maxGrossWeight': function(newValue) {
      this.generalInfo.maxGrossWeight = this.formatNumberToThousands(newValue);
    },
    'generalInfo.buildYear': function(newValue) {
      this.errorList.buildYearError = newValue.length !== 0 && newValue.length !== 4;
    },
    nextRevisionYear(newValue, oldValue) {
      const currentYear = new Date().getFullYear();
      const yearLimit = 2099;
      this.errorList.nextRevisionError.year = !(
        this.isNumberInRange(newValue, currentYear, yearLimit) ||
        (newValue.length === 0 && this.nextRevisionMonth.length === 0)
      );
      if (newValue.length === 4 && oldValue) {
        this.$refs.month.focus();
      }
    },
    nextRevisionMonth(newValue, oldValue) {
      if (isNaN(newValue) && !newValue.match('(^\\d+$)|(^(q|Q)$|q\\d|Q\\d)')) {
        return (this.nextRevisionMonth = oldValue);
      }
      this.errorList.nextRevisionError.month = !new RegExp('(^$)|(0[1-9]|1[0-2]|Q[1-4]|q[1-4])').test(newValue);

      if (!this.nextRevisionYear) {
        this.errorList.nextRevisionError.year = !!newValue;
      }
      this.nextRevisionMonth = newValue.toUpperCase();
    },
  },
  created() {
    this.setClassificationNames();
    this.populateClassification2();
    this.populateClassification3();
    this.setNextRevisionFields();
  },
  methods: {
    async saveGeneralInfo() {
      if (this.isError()) {
        return;
      }
      this.generalInfo.nextRevision = this.formatNextRevisionField;
      let detailedInfoToSave = { ...this.removeWhiteSpace(this.generalInfo) };
      detailedInfoToSave.assetClassification1 = this.getAssetClassification1Id;
      detailedInfoToSave.assetClassification2 = this.getAssetClassification2Id;
      detailedInfoToSave.assetClassification3 = this.getAssetClassification3Id;
      this.loading = true;
      await this.$emit('saveGeneralInfo', detailedInfoToSave);
      setTimeout(() => (this.loading = false), 1000);
    },
    removeWhiteSpace(obj) {
      const keyList = ['owner', 'manufacturer', 'product'];
      keyList.forEach((key) => (obj[key] ? (obj[key] = obj[key].trim()) : obj[key]));
      return obj;
    },
    setClassificationNames() {
      this.generalInfo.assetClassification1 = this.setClassificationName(
        this.assetClassification.classification1,
        this.generalInfo.assetClassification1
      );
      this.generalInfo.assetClassification2 = this.setClassificationName(
        this.assetClassification.classification2,
        this.generalInfo.assetClassification2
      );
      this.generalInfo.assetClassification3 = this.setClassificationName(
        this.assetClassification.classification3,
        this.generalInfo.assetClassification3
      );
    },
    setClassificationName(classificationData, assetClassificationId) {
      return classificationData.find((classification) => classification.id === assetClassificationId);
    },
    populateClassification2() {
      this.localAssetClassification2 = this.assetClassification.classification2.filter(
        (classification) => classification.parentId === this.getAssetClassification1Id
      );
    },
    populateClassification3() {
      this.localAssetClassification3 = this.assetClassification.classification3.filter(
        (classification) => classification.parentId === this.getAssetClassification2Id
      );
      this.localAssetClassification3.sort((a, b) =>
        a.classification.toLowerCase().localeCompare(b.classification.toLowerCase())
      );
    },
    onAssetClassification1Input() {
      this.localAssetClassification2 = [];
      this.localAssetClassification3 = [];
      this.generalInfo.assetClassification2 = null;
      this.generalInfo.assetClassification3 = null;
      this.populateClassification2();
    },
    onAssetClassification2Input() {
      this.localAssetClassification3 = [];
      this.generalInfo.assetClassification3 = null;
      this.populateClassification3();
    },
    isNextRevisionError() {
      return this.errorList.nextRevisionError.year || this.errorList.nextRevisionError.month;
    },
    isError() {
      return this.isNextRevisionError() || this.errorList.buildYearError;
    },
    monthKeyDownEvent(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      const backSpaceCharCode = 8;
      if (this.nextRevisionMonth.length === 0 && charCode === backSpaceCharCode) {
        this.$refs.year.focus();
      }
    },
    setNextRevisionFields() {
      if (this.generalInfo.nextRevision) {
        const nextRevision = this.generalInfo.nextRevision.split('-');
        this.nextRevisionYear = nextRevision[0] ? nextRevision[0] : '';
        this.nextRevisionMonth = nextRevision[1] ? nextRevision[1] : '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-info-zone {
  min-height: 225px;
  position: relative;
}
.margin-top {
  margin-top: 25px;
}
.error-info {
  margin-right: 25px;
  color: red;
}
.error-border {
  outline: 1px solid red !important;
  border-color: red !important;
  box-shadow: none !important;
}
.btn {
  padding: 3px 6px;
}
input {
  color: black;
}
input:disabled {
  background: white;
}
.multiselect-wrapper-disabled {
  cursor: not-allowed;
}
.multiselect {
  color: black !important;
  &.multiselect--disabled {
    opacity: 1 !important;
    pointer-events: none;
  }
}

.next-revision-wrapper {
  padding: 0;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  .form-control:focus {
    outline-style: none;
    box-shadow: none;
    border-color: inherit;
  }
  input {
    box-shadow: none;
    border: none;
    height: 28px;
  }
  .year {
    min-width: 52px;
    max-width: 52px;
    display: table-cell;
    input {
      padding-right: 0;
    }
    input:disabled {
      cursor: not-allowed;
    }
  }
  .dash {
    min-width: 10px;
    max-width: 10px;
    display: table-cell;
    input:disabled {
      padding: 0;
      cursor: default;
    }
  }
  .month {
    width: 100%;
    display: table-cell;
    input {
      padding-left: 0;
    }
    input:disabled {
      cursor: not-allowed;
    }
  }
}
.next-revision-wrapper:focus-within {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}

.tooltip {
  ul {
    display: block;
    padding-left: 20px;
    margin-bottom: 0;
    font-family: Tahoma, sans-serif;
    li {
      list-style: initial;
    }
  }
  .popover-title {
    font-family: Tahoma, sans-serif;
  }
  .popover-content {
    text-align: start;
    color: white;
  }
}

@media (max-width: 992px) {
  .padding-right {
    padding-right: 0 !important;
  }
  .asset-info-zone {
    min-height: 605px;
    position: relative;
  }
}
</style>
