<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else class="row alarm">
      <div v-if="alarm" class="col-md-10 col-md-offset-1">
        <div id="ifCondition" ref="ifCondition" class="panel panel-primary">
          <div class="panel-heading yf-panel-heading-default-panel-style">
            <h4 id="triggerType" class="panel-title">
              <yf-panel-heading-multiselect
                v-model="alarm.triggerType"
                :text-before="$t('alarmsReports.vue.anAlarmWillBeSent')"
                :options="triggerTypes"
                :custom-label="(actionType) => $t('alarmsReports.vue.' + actionType)"
                :width="'270px'"
                @input="onTriggerTypeChange"
              ></yf-panel-heading-multiselect>
            </h4>

            <event-validation-box :is-valid="isIfConditionsValid" :is-submitted="isSubmitted" />
          </div>

          <div class="panel-body">
            <div v-if="isTriggerTypeObservation">
              <alarm-condition-block
                v-for="(ifCondition, index) in alarm.ifCondition"
                :key="index"
                :condition="ifCondition"
                :columns="alarmColumns"
                :is-submitted="isSubmitted"
                @delete-block="deleteIfCondition"
                @validation-changed="
                  (isConditionBlockValid) => $set(ifConditionsValidation, index, isConditionBlockValid)
                "
              />

              <div class="form-group">
                <label>
                  <span>{{ $t('alarmsReports.vue.theObservationMustHaveANewGPSFix') }}</span
                  >&nbsp;
                  <input v-model="alarm.onlyWithGpsSignal" type="checkbox" class="only-gps-checkbox" />
                </label>
              </div>
            </div>

            <div v-if="isTriggerTypeTime && !hideTimeConditions">
              <div v-for="(ifCondition, index) in alarm.ifCondition" :key="index">
                <alarm-time-condition-block
                  :id="`alarmTimeConditionBlock${index}`"
                  :time-condition="ifCondition"
                  :index="index"
                  :is-submitted="isSubmitted"
                  :text-type="alarm.ifCondition.length > 1 ? 'DELETE' : 'RESET'"
                  @delete-block="deleteTimeCondition(index)"
                  @validation-changed="
                    (isTimeConditionBlockValid) => $set(ifConditionsValidation, index, isTimeConditionBlockValid)
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <div v-for="(andCondition, index) in alarm.andConditions" :key="index">
          <div
            :id="`alarmAndConditionBlock${index}`"
            :ref="`alarmAndConditionBlock${index}`"
            class="panel panel-primary and-condition-panel"
          >
            <div class="panel-heading">
              <h4 class="panel-title">
                <span v-if="index === 0">
                  {{ $t('alarmsReports.vue.andAllOfTheConditionsAreFulfilled') }}
                  <span v-if="isTriggerTypeTime"
                    >{{ $t('alarmsReports.vue.FOR_ASSETS') }} {{ $t('alarmsReports.vue.where') }}</span
                  >
                </span>
                <span v-else>{{ $t('alarmsReports.vue.orAllOfTheConditionsAreMet') }}</span>
              </h4>

              <event-validation-box :is-valid="isAndConditionValid(index)" :is-submitted="isSubmitted" />

              <button
                type="button"
                class="close action-close"
                :style="{ 'margin-right': isSubmitted ? '25px' : '0' }"
                @click="deleteOrCondition(index)"
              >
                <i class="glyphicons glyphicons-remove-2 before-black"></i>
              </button>
            </div>

            <div v-if="!hideTimeConditions" class="panel-body">
              <div
                v-for="(condition, childIndex) in andCondition"
                :id="`alarmAndConditionBlock${index}${childIndex}`"
                :key="childIndex"
              >
                <alarm-and-condition-block
                  :condition="condition"
                  :columns="alarmColumns"
                  :trigger-type="alarm.triggerType"
                  :is-submitted="isSubmitted"
                  :index="childIndex"
                  :text-type="andCondition.length > 1 ? 'DELETE' : 'RESET'"
                  @delete-block="deleteCondition(index, childIndex)"
                  @validation-changed="
                    (isConditionBlockValid) => $set(andConditionsValidation[index], childIndex, isConditionBlockValid)
                  "
                ></alarm-and-condition-block>
              </div>

              <alarm-and-condition-range-picker-block
                v-if="isTriggerTypeTime"
                :condition="andCondition[0]"
                :is-alarm-trigger-type-observation="false"
                :mode="'FOR_ASSETS'"
                data-cy="alarmAndConditionRangePicker"
                @validation-changed="
                  (isAndConditionRangeValid) => $set(andConditionsRangesValidation, index, isAndConditionRangeValid)
                "
              />

              <hr />

              <button
                :id="`alarmAndConditionAddCondition${index}`"
                class="btn btn-fm-std btn-sm btn-sm-with-text"
                :title="$t('alarmsReports.vue.addACondition')"
                data-cy="alarmAndConditionAddConditionInner"
                @click="addAndConditionBlockTo(index)"
              >
                <i class="glyphicons glyphicons-plus"></i>
                {{ $t('alarmsReports.vue.addACondition') }}
              </button>
            </div>
          </div>

          <div v-if="alarm.andConditions.length === index + 1" class="text-center add-or-condition-button">
            <button
              type="button"
              class="btn btn-fm-std"
              :title="$t('alarmsReports.vue.addAnOrCondition')"
              @click="addOrConditionBlock"
            >
              <i class="glyphicons glyphicons-plus"></i>
              {{ $t('alarmsReports.vue.addAnOrCondition') }}
            </button>
          </div>
        </div>

        <div v-if="alarm.andConditions && alarm.andConditions.length === 0" class="text-center add-or-condition-button">
          <button
            type="button"
            class="btn btn-fm-std"
            :title="$t('alarmsReports.vue.addCondition')"
            :data-cy="`alarmAndConditionAddCondition`"
            @click="addOrConditionBlock"
          >
            <i class="glyphicons glyphicons-plus"></i>
            {{ $t('alarmsReports.vue.addCondition') }}
          </button>
        </div>

        <div ref="alarmActionBlock" class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.thenSendAlarmNotificationBy') }}</h4>

            <event-validation-box :is-valid="isActionsValid" :is-submitted="isSubmitted" />
          </div>

          <div class="panel-body">
            <div
              v-for="(action, actionIndex) in alarm.actions"
              :id="`alarmActionBlock${actionIndex}`"
              :key="actionIndex"
            >
              <alarm-action-block
                :action="action"
                :action-types="availableActionTypes"
                :is-submitted="isSubmitted"
                :index="actionIndex"
                :trigger-type="alarm.triggerType"
                :text-type="alarm.actions.length > 1 ? 'DELETE' : 'RESET'"
                @delete-block="deleteAction(actionIndex)"
                @validation-changed="(isActionBlockValid) => $set(actionsValidation, actionIndex, isActionBlockValid)"
              />

              <hr v-show="actionIndex < initialActionTypes.length - 1" />
            </div>

            <button
              v-show="alarm && alarm.actions && alarm.actions.length < initialActionTypes.length"
              class="btn btn-fm-std btn-sm btn-sm-with-text"
              :title="$t('alarmsReports.vue.addAnAction')"
              @click="addAction"
            >
              <i class="glyphicons glyphicons-plus"></i>
              {{ $t('alarmsReports.vue.addAnAction') }}
            </button>
          </div>
        </div>

        <div id="generalInformation" class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.generalAlarmInformation') }}</h4>

            <event-validation-box :is-valid="generalInformationValidation" :is-submitted="isSubmitted" />
          </div>

          <div ref="eventGeneralInfo" class="panel-body event-general-info-panel-body">
            <event-general-info
              :event="alarm"
              :original-event="originalAlarm"
              :is-template="isCreateAlarmFromScenarioView"
              :is-submitted="isSubmitted"
              @validation-changed="setGeneralInformationValidation"
            ></event-general-info>
          </div>
        </div>

        <div class="button-group text-right">
          <yf-ladda
            id="saveAlarm"
            data-cy="saveAlarm"
            button-class="btn col-md-1 pull-right btn-success"
            :loading="loading"
            :title="$t('alarmsReports.vue.saveAlarm')"
            :delay="1000"
            @click="saveAlarm"
          >
            <i class="glyphicons glyphicons-ok-2"></i>
          </yf-ladda>
          <a
            id="backToMainMenu"
            href="#/vue/alarms"
            class="btn pull-right col-md-1 btn-danger pull-left"
            :title="$t('alarmsReports.vue.backToMainMenu')"
          >
            <i class="glyphicons glyphicons-remove-2"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlarmActionBlock from './AlarmActionBlock';
import AlarmConditionBlock from './AlarmConditionBlock';
import AlarmAndConditionRangePickerBlock from './AlarmAndConditionRangePickerBlock';
import AlarmAndConditionBlock from './AlarmAndConditionBlock';
import EventService from '../services/EventService';
import AlarmTimeConditionBlock from './AlarmTimeConditionBlock';
import EventGeneralInfo from '../event/EventGeneralInfo';
import {
  defaultAlarm,
  emptyAction,
  emptyCondition,
  emptyTimeCondition,
  eventTriggerTypes,
  emptyForAssetsConditionRange,
  eventTypes,
  getDefaultStartDate,
  getDefaultEndDate,
} from '../utils/Constants';
import EventMixin from '../mixins/EventMixin';
import EventValidationBox from '../event/EventValidationBox';
import { deepClone } from '../utils/Utils';
import YfLadda from '../yf-components/YfLadda';
import YfPanelHeadingMultiselect from '../yf-components/YfPanelHeadingMultiselect';

export default {
  name: 'Alarm',

  components: {
    EventValidationBox,
    AlarmActionBlock,
    AlarmConditionBlock,
    AlarmAndConditionBlock,
    AlarmAndConditionRangePickerBlock,
    AlarmTimeConditionBlock,
    EventGeneralInfo,
    YfLadda,
    YfPanelHeadingMultiselect,
  },

  mixins: [EventMixin],

  data() {
    return {
      isLoading: false,
      showModal: false,
      alarm: {},
      originalAlarm: {},
      triggerTypes: [eventTriggerTypes.OBSERVATION, eventTriggerTypes.TIME],
      andConditionsRangesValidation: [null],
      loading: false,
      initialActionTypes: ['EMAIL'],
    };
  },

  computed: {
    availableActionTypes() {
      const actionTypes = deepClone(this.initialActionTypes);
      const actions = this.alarm.actions.map((action) => action.type);

      return actionTypes.filter((actionType) => !actions.includes(actionType));
    },
    isUpdateAlarmView() {
      return this.$route && this.$route.params && this.$route.params.alarmId + '' !== '0';
    },
    isCreateAlarmFromScenarioView() {
      return (
        this.$route &&
        this.$route.params &&
        this.$route.params.alarmId + '' === '0' &&
        this.$route.params.templateId &&
        this.$route.params.templateId + '' !== '0'
      );
    },
    isTriggerTypeObservation() {
      return this.alarm && this.alarm.triggerType === eventTriggerTypes.OBSERVATION;
    },
    isTriggerTypeTime() {
      return this.alarm && this.alarm.triggerType === eventTriggerTypes.TIME;
    },
    isAndConditionRangesValid() {
      if (this.isTriggerTypeObservation) {
        return true;
      }

      if (this.isAndConditionsSet) {
        return this.andConditionsRangesValidation.every((isValid) => isValid);
      }

      return true;
    },
    isValid() {
      return (
        !!this.generalInformationValidation &&
        this.isIfConditionsValid &&
        this.isAndConditionsValid &&
        this.isAndConditionRangesValid &&
        this.isActionsValid
      );
    },
    alarmColumns() {
      return [
        ...this.columns.map((columnGroup) => ({
          ...columnGroup,
          columns: columnGroup.columns.filter((attribute) => attribute.dataField !== 'newPosition'),
        })),
      ];
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.getAlarm();
    await this.getColumnDefinitions();
    this.isLoading = false;
  },

  methods: {
    isAndConditionValid(index) {
      if (!this.andConditionsValidation[index]) {
        return false;
      }

      const isAndConditionsRangesValid = this.isTriggerTypeTime ? this.andConditionsRangesValidation[index] : true;

      return this.andConditionsValidation[index].every((isValid) => isValid) && isAndConditionsRangesValid;
    },
    async getAlarm() {
      try {
        if (this.isUpdateAlarmView) {
          this.alarm = await EventService.getAlarm(this.$route.params.alarmId);
          this.alarm.startDate = new Date(this.alarm.startDate);
          this.alarm.endDate = new Date(this.alarm.endDate);
          this.constructValidationArrays(this.alarm);
          this.andConditionsRangesValidation = this.alarm.andConditions.map(() => true);
          this.isSubmitted = true;
        } else {
          if (this.isCreateAlarmFromScenarioView) {
            const chosenAlarmScenario = await EventService.getEventTemplate(
              eventTypes.ALARM,
              this.$route.params.templateId
            );

            const chosenAlarmScenarioWithCorrectDates = {
              ...chosenAlarmScenario.data,
              startDate: getDefaultStartDate(),
              endDate: getDefaultEndDate(),
            };

            this.alarm = deepClone(chosenAlarmScenarioWithCorrectDates);
            this.constructValidationArrays(this.alarm);
            this.isSubmitted = true;
          } else {
            this.alarm = deepClone(defaultAlarm);
          }

          this.alarm.startDate = new Date(this.alarm.startDate);
          this.alarm.endDate = new Date(this.alarm.endDate);
          const timezone = await EventService.getMeTimezone();
          this.alarm.timezone = timezone.id;
        }

        this.originalAlarm = deepClone(this.alarm);
      } catch (err) {
        window.location.replace('/#/vue/alarms/0');
      }
    },
    onTriggerTypeChange(val) {
      this.$set(this.alarm, 'triggerType', val);

      switch (val) {
        case eventTriggerTypes.OBSERVATION:
          this.addIfConditionBlock();
          break;

        case eventTriggerTypes.TIME:
          this.alarm.ifCondition = [];
          this.ifConditionsValidation = [];
          this.addTimeConditionBlock();
          break;

        default:
          throw Error(`Unknown trigger type ${this.alarm.triggerType}`);
      }

      this.hideTimeConditions = true;
      this.alarm.andConditions = [];
      this.andConditionsValidation = [];

      this.$nextTick(() => {
        window.scrollTo(0, 0);
        this.hideTimeConditions = false;
      });
    },
    addIfConditionBlock() {
      this.alarm.ifCondition = [{ ...emptyCondition }];
      this.$set(this.ifConditionsValidation, 0, null);
    },
    deleteIfCondition() {
      this.addIfConditionBlock();
    },
    addTimeConditionBlock() {
      this.alarm.ifCondition.push({ ...emptyTimeCondition });
      this.ifConditionsValidation.push(true);
    },
    deleteTimeCondition(index) {
      this.$delete(this.alarm.ifCondition, index);
      this.$delete(this.ifConditionsValidation, index);

      if (this.alarm.ifCondition.length === 0) {
        this.addTimeConditionBlock();
      }

      // For some reason the timeconditions are not rerendered when removing a time block.
      this.hideTimeConditions = true;
      this.$nextTick(() => {
        this.hideTimeConditions = false;
      });
    },
    addOrConditionBlock() {
      if (!this.alarm.andConditions) {
        this.alarm.andConditions = [];
      }

      if (this.isTriggerTypeTime) {
        this.alarm.andConditions.push([{ ...emptyCondition, conditionRange: { ...emptyForAssetsConditionRange } }]);
      } else {
        this.alarm.andConditions.push([{ ...emptyCondition }]);
      }

      this.andConditionsValidation.push([null]);
    },
    addFirstTimeAndConditionTo(andConditionIndex) {
      this.alarm.andConditions[andConditionIndex].push({
        ...emptyCondition,
        conditionRange: { ...emptyForAssetsConditionRange },
      });
      this.andConditionsValidation[andConditionIndex].push(null);
    },
    addAndConditionBlockTo(andConditionIndex) {
      this.alarm.andConditions[andConditionIndex].push({ ...emptyCondition });
      this.andConditionsValidation[andConditionIndex].push(null);
    },
    deleteCondition(index, childIndex) {
      const conditionRange = deepClone(this.alarm.andConditions[index][childIndex].conditionRange);

      this.$delete(this.alarm.andConditions[index], childIndex);
      this.$delete(this.andConditionsValidation[index], childIndex);

      if (this.alarm.andConditions.length === 0) {
        this.addOrConditionBlock();
      } else if (this.alarm.andConditions[index].length === 0) {
        if (this.isTriggerTypeTime) {
          this.addFirstTimeAndConditionTo(index);
        } else {
          this.addAndConditionBlockTo(index);
        }
      } else if (this.isConditionRangeForTheFirstElement(this.alarm.andConditions[index], conditionRange)) {
        this.alarm.andConditions[index][0].conditionRange = conditionRange;
      }

      this.hideTimeConditions = true;
      this.$nextTick(() => {
        this.hideTimeConditions = false;
      });
    },
    isConditionRangeForTheFirstElement(andCondition, conditionRange) {
      return andCondition.length > 0 && conditionRange != null;
    },
    deleteOrCondition(index) {
      this.$delete(this.alarm.andConditions, index);
      this.$delete(this.andConditionsValidation, index);

      // For some reason the timeconditions are not rerendered when removing a time block.
      this.hideTimeConditions = true;
      this.$nextTick(() => {
        this.hideTimeConditions = false;
      });
    },
    addAction() {
      this.alarm.actions.push({ ...emptyAction, type: this.availableActionTypes[0] });
      this.actionsValidation.push(null);
    },
    deleteAction(actionIndex) {
      this.$delete(this.alarm.actions, actionIndex);
      this.$delete(this.actionsValidation, actionIndex);

      if (this.alarm.actions.length === 0) {
        this.addAction();
      }
    },
    isLastAndCondition(index) {
      return this.alarm.andConditions.length - 1 === index;
    },
    async saveAlarm() {
      this.isSubmitted = true;
      this.loading = true;

      if (this.isValid) {
        if (this.isTriggerTypeObservation && !(this.alarm.ifCondition[0].values instanceof Array)) {
          this.alarm.ifCondition[0].values = [this.alarm.ifCondition[0].values];
        }
        this.alarm.name = this.alarm.name.trim();
        if (this.isUpdateAlarmView) {
          await EventService.updateEvent(this.alarm);
        } else {
          await EventService.createEvent(this.alarm);
        }
        this.loading = false;
        window.location.replace('/#/vue/alarms');
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.scrollToTheElement();
      }
    },
    scrollToTheElement() {
      let elementToScrollTo;
      if (!this.isIfConditionsValid) {
        elementToScrollTo = 'ifCondition';
      } else if (!this.isAndConditionsValid) {
        this.alarm.andConditions.find((element, index) => {
          elementToScrollTo = 'alarmAndConditionBlock' + index;
          return !this.isAndConditionValid(index);
        });
      } else if (!this.isActionsValid) {
        elementToScrollTo = 'alarmActionBlock';
      } else if (!this.generalInformationValidation) {
        elementToScrollTo = 'eventGeneralInfo';
      } else {
        return;
      }

      if (Array.isArray(this.$refs[elementToScrollTo])) {
        this.$refs[elementToScrollTo][0].scrollIntoView({ behavior: 'smooth' });
      } else {
        this.$refs[elementToScrollTo].scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alarm {
  .only-gps-checkbox {
    vertical-align: bottom;
  }
}
</style>
