<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-overlay"></div>
    <div class="modal-dialog" :class="size" role="document">
      <div class="modal-content" :class="type">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YfModal',

  props: {
    modalSize: {
      type: String,
      default: 'modal-dialog-xl',
    },
    modalType: {
      type: String,
      default: '',
    },
  },

  computed: {
    size() {
      return {
        [this.modalSize]: true,
      };
    },
    type() {
      return {
        [this.modalType]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.4;
  background: grey;
}

.modal-content {
  border-radius: 1px;
}
</style>
