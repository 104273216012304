export default {
  data() {
    return {
      filters: {},
      sort: 'name',
      desc: false,
    };
  },
  methods: {
    hasFilter: function() {
      return this.filters['name'] || this.filters['endDate'] || this.filters['author'] || this.filters['editedBy'];
    },
    clearFilter(key) {
      this.$delete(this.filters, key);
    },
    setSort(key) {
      this.desc = this.sort === key ? !this.desc : false;
      this.sort = key;
    },
    filterArray(data, key) {
      let searchString = this.filters[key];

      if (key === 'tareWeight' || key === 'maxGrossWeight') {
        return data.filter((value) => {
          const valueToFilter = this.$t(value[key].replace(/\s/g, ''));
          return valueToFilter.indexOf(searchString.replace(/\s/g, '')) > -1;
        });
      }
      return data.filter((value) => {
        const valueToFilter = this.$t(value[key]).toLowerCase();
        return valueToFilter.indexOf(searchString.toLowerCase()) > -1;
      });
    },
    filter(data) {
      let filteredArray = [];
      if (Object.keys(this.filters).filter((key) => this.filters[key]).length > 0) {
        data = Object.keys(this.filters)
          .filter((key) => this.filters[key])
          .map((key, index) => {
            if (index > 0) {
              return (filteredArray = this.filterArray(filteredArray, key));
            }
            return (filteredArray = this.filterArray(data, key));
          });
        data = data[data.length - 1];
      }

      let sorted = [];
      if (this.sort === 'tareWeight' || this.sort === 'maxGrossWeight') {
        sorted = data.sort((a, b) => a[this.sort].replace(/\s/g, '') - b[this.sort].replace(/\s/g, ''));
      } else {
        sorted = data.sort((a, b) => a[this.sort].localeCompare(b[this.sort]));
      }

      if (this.desc) {
        return sorted.reverse();
      }
      return sorted;
    },
  },
};
