<template>
  <yf-modal modal-size="modal-dialog-lg" modal-type="panel-default">
    <div class="modal-header panel-heading" @click.stop>
      <h4 class="modal-title">
        {{ fileDetailsToBePreviewed.fileName }}
      </h4>
    </div>
    <div @click.stop>
      <div v-if="isFileLoading" class="modal-area-loader">
        <div class="loader">{{ $t('generic.loading') }}</div>
      </div>
      <div v-else class="modal-body panel-body">
        <img
          v-if="isFileAllowedImage(fileDetailsToBePreviewed.fileName)"
          class="position-center image-preview"
          :src="fileDetailsToBePreviewed.blobUrl"
        />
        <embed v-else class="position-center file-preview" :src="fileDetailsToBePreviewed.blobUrl" />
      </div>
      <div class="panel-footer same-row">
        <table>
          <tr>
            <th class="th-min-width">{{ $t('assetInformation.vue.tableHeader.addedBy') }}</th>
            <th class="th-min-width">{{ $t('assetInformation.vue.tableHeader.addedOn') }}</th>
            <th class="th-min-width-15">{{ $t('header.description') }}</th>
            <th class="th-min-width"></th>
          </tr>
          <tbody>
            <tr>
              <td>{{ fileDetailsToBePreviewed.authorId }}</td>
              <td>{{ formatDate(fileDetailsToBePreviewed.creationDate) }}</td>
              <td class="break-word">{{ fileDetailsToBePreviewed.description }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger pull-right"
                  data-cy="previewModalCloseButton"
                  @click="closePreviewModal"
                >
                  <i class="glyphicons glyphicons-remove-2" :title="$t('generic.close')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </yf-modal>
</template>

<script>
import YfModal from '../yf-components/YfModal';
import AssetInformationMixin from '@/mixins/AssetInformationMixin';

export default {
  name: 'AssetInformationPreviewModal',
  components: {
    YfModal,
  },
  mixins: [AssetInformationMixin],
  props: {
    isPreview: Boolean,
    isFileLoading: Boolean,
    fileDetailsToBePreviewed: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closePreviewModal() {
      this.$emit('closePreviewModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.position-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.image-preview {
  max-width: 100%;
  max-height: 66vh;
  transform: translateZ(0);
}
.file-preview {
  width: 100%;
  height: 66vh;
}
.modal-body {
  height: 70vh;
}
.modal-area-loader {
  position: relative;
  height: 70vh;
}
.align-left {
  text-align: left;
}
.break-word {
  word-break: break-word;
}
.btn {
  padding: 3px 6px;
}
.th-min-width-15 {
  width: 15%;
}
</style>
