<template>
  <form class="form-horizontal">
    <div class="form-line">
      <span v-if="index !== 0">{{ $t('alarmsReports.vue.and').toLowerCase() }} <event-space></event-space></span>

      <multiselect
        ref="subscribersRef"
        v-model="action.subscribers"
        :options="availableCustomerUsersForType"
        :class="{ 'has-error': errors.subscribers && isSubmitted }"
        label="name"
        track-by="name"
        select-label=""
        selected-label=""
        deselect-label=""
        :multiple="true"
        :taggable="true"
        :clear-on-select="false"
        :close-on-select="false"
        :hide-selected="true"
        open-direction="bottom"
        placeholder=""
        style="flex: 3"
        data-cy="alarmActionBlockSubscribers"
      >
      </multiselect>
    </div>

    <div class="form-line">
      <span v-show="isActionTypeEmail"
        >{{ $t('alarmsReports.vue.andIncludeInformationAbout') }} <event-space></event-space
      ></span>

      <multiselect
        v-show="isActionTypeEmail"
        v-model="chosenAdditionalInformation"
        :options="availableAttributes"
        :custom-label="({ columnName }) => $t(columnName)"
        group-values="columns"
        group-label="groupedBy"
        label="columnName"
        track-by="dataField"
        :multiple="true"
        :taggable="true"
        :clear-on-select="false"
        :close-on-select="false"
        :hide-selected="true"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="flex: 3"
      >
      </multiselect>
    </div>

    <div v-show="isActionTypeEmail" class="form-line form-line-area-level" style="margin-left: 194px;">
      <div v-show="isGeofenceAreaChosen || isGeofenceTypeChosen">
        <label class="radio-inline radio-inline-label">{{ $t('geofence.level') }}</label>

        <label class="radio-inline">
          <input v-model="geofenceLevel" type="radio" name="geofenceLevel" value="1" /> 1
        </label>

        <label class="radio-inline">
          <input v-model="geofenceLevel" type="radio" name="geofenceLevel" value="2" /> 2
        </label>

        <label class="radio-inline">
          <input v-model="geofenceLevel" type="radio" name="geofenceLevel" value="3" /> 3
        </label>
      </div>
    </div>

    <div class="form-line flex-end">
      <small @click.prevent="deleteAction">
        <a v-show="isReset" href="#" :title="$t('alarmsReports.vue.resetAction')">{{
          $t('alarmsReports.vue.resetAction')
        }}</a>
        <a v-show="isDelete" href="#" :title="$t('alarmsReports.vue.deleteAction')">{{
          $t('alarmsReports.vue.deleteAction')
        }}</a>
      </small>
    </div>
  </form>
</template>
<script>
import EventService from '../services/EventService';
import { deepClone, NaturalSortService } from '../utils/Utils';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import EventSpace from '../event/EventSpace';
import Multiselect from 'vue-multiselect';
import EventBlockTextTypeMixin from '../mixins/EventBlockTextTypeMixin';
import { GeofenceFieldsService } from '../services/GeofenceFieldsService';

export default {
  name: 'AlarmActionBlock',

  components: {
    EventSpace,
    Multiselect,
  },

  mixins: [EventBlockValidationMixin, EventBlockTextTypeMixin],

  props: {
    action: {
      type: Object,
      required: true,
    },
    actionTypes: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    triggerType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      availableCustomerUsers: [],
      availableAttributes: [],
      chosenAdditionalInformation: [],
      geofenceLevel: 1,
    };
  },

  computed: {
    isGeofenceAreaChosen() {
      return (
        this.chosenAdditionalInformation &&
        this.chosenAdditionalInformation.some((attribute) => attribute.dataField === 'geofenceArea')
      );
    },
    isGeofenceTypeChosen() {
      return (
        this.chosenAdditionalInformation &&
        this.chosenAdditionalInformation.some((attribute) => attribute.dataField === 'geofenceType')
      );
    },
    additionalInformation() {
      let additionalInformation = this.chosenAdditionalInformation ? [...this.chosenAdditionalInformation] : [];

      if (this.isGeofenceAreaChosen && this.isGeofenceTypeChosen) {
        additionalInformation = [
          ...additionalInformation.filter((attribute) => {
            return attribute.dataField !== 'geofenceArea' && attribute.dataField !== 'geofenceType';
          }),
          ...GeofenceFieldsService.generateGeofenceFields(this.geofenceLevel),
        ];
      } else if (this.isGeofenceAreaChosen) {
        additionalInformation = [
          ...additionalInformation.filter((attribute) => attribute.dataField !== 'geofenceArea'),
          ...GeofenceFieldsService.generateGeofenceAreaFields(this.geofenceLevel),
        ];
      } else if (this.isGeofenceTypeChosen) {
        additionalInformation = [
          ...additionalInformation.filter((attribute) => attribute.dataField !== 'geofenceType'),
          ...GeofenceFieldsService.generateGeofenceTypeFields(this.geofenceLevel),
        ];
      }

      return additionalInformation;
    },
    availableCustomerUsersForType() {
      switch (this.action.type) {
        case 'EMAIL':
          return this.availableCustomerUsers.filter((user) => user.mail !== '' && user.mail !== null);
        case 'SMS':
        case 'SMS_8_17':
          return this.availableCustomerUsers.filter((user) => user.mobilephone !== '' && user.mobilephone !== null);
        default:
          return this.availableCustomerUsers;
      }
    },
    isActionTypeEmail() {
      return this.action.type === 'EMAIL';
    },
    isValid() {
      return !!this.action.type && this.action.subscribers.length > 0;
    },
  },

  watch: {
    additionalInformation() {
      this.action.additionalInformation = this.additionalInformation;
    },
  },

  async created() {
    await this.getAvailableSubscribers();
    await this.getColumnDefinitions();

    if (this.isActionTypeEmail) {
      let additionalInformation = deepClone(this.action.additionalInformation);

      const includeGeofenceArea = (attribute) => attribute.dataField.includes('geofenceArea');
      const excludeGeofenceArea = (attribute) => !attribute.dataField.includes('geofenceArea');

      let geofenceAreaFields = additionalInformation.filter(includeGeofenceArea);

      if (geofenceAreaFields.length > 0) {
        additionalInformation = [
          ...additionalInformation.filter(excludeGeofenceArea),
          {
            columnName: 'geofenceArea.base',
            dataField: 'geofenceArea',
          },
        ];

        this.geofenceLevel = GeofenceFieldsService.generateGeofenceLevelFromGeofenceFields(geofenceAreaFields);
      }

      const includeGeofenceType = (attribute) => attribute.dataField.includes('geofenceType');
      const excludeGeofenceType = (attribute) => !attribute.dataField.includes('geofenceType');

      let geofenceTypeFields = additionalInformation.filter(includeGeofenceType);

      if (geofenceTypeFields.length > 0) {
        additionalInformation = [
          ...additionalInformation.filter(excludeGeofenceType),
          {
            columnName: 'geofenceType.base',
            dataField: 'geofenceType',
          },
        ];

        this.geofenceLevel = GeofenceFieldsService.generateGeofenceLevelFromGeofenceFields(geofenceTypeFields);
      }

      this.chosenAdditionalInformation = deepClone(additionalInformation);
    }
  },

  methods: {
    async getAvailableSubscribers() {
      const availableCustomerUsers = await EventService.getCustomerUsers();
      this.availableCustomerUsers = NaturalSortService.naturalSortArrayByKey(availableCustomerUsers, 'name');
    },

    async getColumnDefinitions() {
      const columnDefinitions = await EventService.getColumnDefinitions();
      const columns = columnDefinitions.columns
        .filter((column) => column.geofenceLevel <= 1)
        .map(this.translateColumnName);

      const groups = [
        'alarmsReports.vue.assetSelections',
        'alarmsReports.vue.geographicalSelections',
        'alarmsReports.vue.customSelections',
        'alarmsReports.vue.stillTimeSelections',
        'alarmsReports.vue.sensorSelections',
        'alarmsReports.vue.otherSelections',
      ];

      this.availableAttributes = groups.map((group) => {
        let groupColumns = columns
          .filter((column) => column.groupedBy === group)
          .filter((column) => column.dataField !== 'name');

        if (group === 'alarmsReports.vue.customSelections') {
          const columnNameKey = 'columnName';
          groupColumns = NaturalSortService.naturalSortArrayByKey(groupColumns, columnNameKey);
        }

        return {
          groupedBy: this.$t(group),
          columns: groupColumns,
        };
      });
    },

    translateColumnName(column) {
      return {
        ...column,
        columnName: this.$t(column.columnName),
      };
    },

    showErrors() {
      const errors = {};

      errors.subscribers = this.action.subscribers.length === 0;

      this.errors = errors;
    },

    deleteAction() {
      this.$emit('delete-block');
      this.chosenAdditionalInformation = [];
      this.geofenceLevel = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-line-area-level {
  $alignVerticallyWithAdditionalMultiselect: -10px;
  $alignWithAdditionalMultiselect: 194px;
  margin-top: $alignVerticallyWithAdditionalMultiselect;
  margin-left: $alignWithAdditionalMultiselect;
  .radio-inline {
    padding-top: 0;

    input[type='radio'] {
      margin-top: 8px;
    }
  }
}
</style>
