<template>
  <div>
    <div v-show="isOpen" class="popover-overlay" @click="close"></div>

    <div class="wrapper">
      <div ref="popover" class="popover am-flip-x" :class="classes" :style="style">
        <div class="arrow"></div>
        <h3 class="popover-title">{{ $t('alarmsReports.vue.createReportFrom') }}</h3>

        <div class="popover-content center-popup">
          <div>
            <button
              v-show="!showMultiselect"
              class="btn btn-fm-std inline-block right-buffer"
              type="button"
              @click="createNewWithEmptyReport"
            >
              {{ $t('alarmsReports.vue.emptyReport') }}
            </button>
            <button
              v-show="!showMultiselect"
              type="button"
              class="btn btn-fm-std inline-block ladda-button"
              data-ladda-toggle="pendingRequest"
              data-style="slide-left"
              @click="showMultiselect = true"
            >
              {{ $t('alarmsReports.vue.reportTemplate') }}
            </button>

            <multiselect
              v-show="showMultiselect"
              v-model="reportScenario"
              :options="reportScenarios"
              :allow-empty="false"
              :show-labels="false"
              placeholder=""
              label="name"
              track-by="name"
              select-label=""
              deselect-label=""
              open-direction="bottom"
              class="report-template-multiselect"
              @input="createNewWithReportTemplate"
            >
            </multiselect>
          </div>
        </div>
      </div>

      <div ref="slotWrapper" class="slot-wrapper" @click="toggle">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { eventTypes } from '../utils/Constants';
import EventService from '../services/EventService';
import { NaturalSortService } from '../utils/Utils';

export default {
  name: 'ReportPopover',

  components: {
    Multiselect,
  },

  data() {
    return {
      isOpen: false,
      style: {},
      arrowDirection: 'right',
      showMultiselect: false,
      reportScenario: {},
      reportScenarios: [],
    };
  },

  computed: {
    classes() {
      const classes = {
        open: this.isOpen,
      };

      classes[this.arrowDirection] = true;

      return classes;
    },
  },

  async mounted() {
    this.reportScenarios = await EventService.getEventTemplates(eventTypes.REPORT);
    this.reportScenarios = NaturalSortService.naturalSortArrayByKey(this.reportScenarios, 'name');
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.showMultiselect = false;

      this.$nextTick(() => {
        if (this.isOpen) {
          const slotRect = this.$refs.slotWrapper.getBoundingClientRect();
          const popoverRect = this.$refs.popover.getBoundingClientRect();

          this.style = {
            position: 'fixed',
            top: slotRect.top + slotRect.height / 2 - popoverRect.height / 2 + 'px',
            left: slotRect.right + 'px',
          };
        }
      });
    },
    close() {
      this.showMultiselect = false;
      this.isOpen = false;
    },
    createNewWithEmptyReport() {
      this.$emit('on-submit', { action: 'CREATE_NEW' });
      this.close();
    },
    createNewWithReportTemplate() {
      this.$emit('on-submit', { action: 'CREATE_NEW_FROM_SCENARIO', reportScenario: this.reportScenario });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  max-width: 400px;
}

.report-template-multiselect {
  width: 300px;
}
.arrow {
  top: 50%;
}
.open {
  display: block;
  visibility: visible;
  overflow: visible;
}

.wrapper {
  display: flex;
}

.slot-wrapper {
  flex-shrink: initial;
}

.popover-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: transparent;
}
</style>
