export default {
  data() {
    return {
      assetIdLimit: {
        messageOpen: false,
        timeoutTriggered: false,
      },
    };
  },
  methods: {
    validateValues(values, columnType) {
      const isValuesValid = values ? this.validateStringValues(values) : false;
      const shouldValidateNumbers = columnType === 'Number';
      const isNumberValuesValid = shouldValidateNumbers ? this.validateNumberValues(values) : true;

      return isValuesValid && isNumberValuesValid;
    },
    validateStringValues(values) {
      return this.validateString(values) || values.length > 0;
    },
    validateNumberValues(values) {
      if (Array.isArray(values)) {
        return values.every(this.validateNumber.bind(this));
      } else {
        return this.validateNumber(values);
      }
    },
    validateNumber(value) {
      return this.isNumber(value.name) || this.isNumber(value.text) || this.isNumber(value.value);
    },
    isNumber(attribute) {
      return attribute !== '' && !isNaN(Number(attribute));
    },
    validateString(value) {
      return !!value.name || !!value.text || !!value.value;
    },
    validateAssetIdLimit() {
      const ASSET_ID_LIMIT = 100;
      if (this.condition.column.dataField === 'name' && this.condition.values.length > ASSET_ID_LIMIT) {
        this.condition.values.pop();
        this.assetIdLimit.messageOpen = true;
        if (!this.assetIdLimit.timeoutTriggered) {
          this.assetIdLimit.timeoutTriggered = true;
          setTimeout(() => {
            this.assetIdLimit.messageOpen = false;
            this.assetIdLimit.timeoutTriggered = false;
          }, 6000);
        }
        return;
      }
    },
  },
};
