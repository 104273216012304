<template>
  <form class="form-horizontal">
    <div class="form-line">
      <span v-if="index === 0"> {{ $t('alarmsReports.vue.where') }} <event-space></event-space> </span>
      <span v-else>{{ $t('alarmsReports.vue.and') }} <event-space></event-space> </span>

      <multiselect
        v-model="condition.column"
        :class="{ 'has-error': errors.column && isSubmitted }"
        :options="columns"
        :custom-label="({ columnName }) => $t(columnName)"
        group-values="columns"
        group-label="groupedBy"
        label="columnName"
        track-by="dataField"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="flex: 2 0 0"
        data-cy="reportAndConditionBlockGroupedBy"
        @input="resetOperator"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        ref="operatorRef"
        v-model="condition.operator"
        :options="columnConditions"
        :custom-label="({ label }) => (label ? $t('alarmsReports.vue.' + label) : '')"
        :allow-empty="false"
        :disabled="columnConditions.length === 0"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="flex: 2"
        @input="onOperatorChange"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        v-if="!isAlarmTypeText && !isAlarmTypeSuggestion && !isAlarmTypeApi"
        :options="[]"
        :disabled="columnConditions.length === 0"
        placeholder=""
        class="value-multiselect"
      >
      </multiselect>

      <multiselect
        v-show="isAlarmTypeText"
        ref="valueRefText"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :multiple="isValueMultiple"
        :taggable="isValueMultiple"
        :clear-on-select="isValueMultiple"
        :close-on-select="!isValueMultiple"
        :allow-empty="false"
        label="value"
        track-by="value"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        :placeholder="placeholder"
        class="value-multiselect"
        @select="onTextValueChange"
        @search-change="addQueryAsOnlyResult"
      >
      </multiselect>

      <span
        v-tooltip="{
          content: $t('categoriesGroups.maxSelected'),
          show: assetIdLimit.messageOpen,
          trigger: 'manual',
          placement: 'left',
          classes: 'tooltip-info',
          offset: '10',
        }"
      ></span>

      <multiselect
        v-show="isAlarmTypeSuggestion"
        ref="valueRefSuggestion"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :loading="isLoading"
        :multiple="isValueMultiple"
        :taggable="isValueMultiple"
        :clear-on-select="isValueMultiple"
        :close-on-select="!isValueMultiple"
        :hide-selected="true"
        open-direction="bottom"
        :custom-label="(value) => $t(value.text)"
        track-by="text"
        :placeholder="placeholder"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        class="value-multiselect"
        @select="onValueChange"
        @search-change="getSuggestionsForQuery"
      >
      </multiselect>

      <multiselect
        v-show="isAlarmTypeApi"
        ref="valueRefApi"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :loading="isLoading"
        :multiple="isValueMultiple"
        :taggable="isValueMultiple"
        :clear-on-select="isValueMultiple"
        :close-on-select="!isValueMultiple"
        :hide-selected="true"
        open-direction="bottom"
        :custom-label="getAlarmTypeApiLabel"
        track-by="name"
        :placeholder="placeholder"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        class="value-multiselect"
        data-cy="reportAndConditionBlockValues"
        @select="onValueChange"
        @search-change="searchChangedForApi"
      >
      </multiselect>
    </div>

    <div class="form-line flex-end">
      <small @click.prevent="$emit('delete-block')">
        <a v-show="isReset" href="#" :title="$t('alarmsReports.vue.resetCondition')">{{
          $t('alarmsReports.vue.resetCondition')
        }}</a>
        <a v-show="isDelete" href="#" :title="$t('alarmsReports.vue.deleteCondition')">{{
          $t('alarmsReports.vue.deleteCondition')
        }}</a>
      </small>
    </div>
  </form>
</template>

<script>
import EventBlockMixin from '../mixins/EventBlockMixin';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import EventSpace from '../event/EventSpace';
import Multiselect from 'vue-multiselect';
import EventConditionValueValidationMixin from '../mixins/EventConditionValueValidationMixin';
import EventBlockTextTypeMixin from '../mixins/EventBlockTextTypeMixin';

export default {
  name: 'ReportAndConditionBlock',

  components: {
    EventSpace,
    Multiselect,
  },

  mixins: [EventBlockMixin, EventBlockValidationMixin, EventConditionValueValidationMixin, EventBlockTextTypeMixin],

  computed: {
    isValid() {
      return (
        this.isAlarmTypeSet &&
        !!this.condition.operator.label &&
        this.validateValues(this.condition.values, this.condition.column.columnType)
      );
    },
  },

  methods: {
    showErrors() {
      const errors = {};

      errors.column = !this.condition.column.alarmType;
      errors.values = !this.validateValues(this.condition.values, this.condition.column.columnType);

      this.errors = errors;
    },
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: row;
}

.padding-top {
  padding: 15px 0 0 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.button-group {
  .button:not(:last-child) {
    margin-right: 10px;
  }
}

input.form-control {
  height: 30px;
}
</style>
