<template>
  <div class="report-template-2">
    <div ref="lineChart" class="line-chart"></div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { gridLayoutRowHeight } from '../utils/Constants';

export default {
  name: 'ReportTemplateLineChart',

  props: {
    item: {
      type: Object,
      required: true,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: {},
    };
  },

  watch: {
    resize: {
      handler: function() {
        if (this.item && this.item.h && this.item.w) {
          this.chart.element.svgContainer.htmlElement.style.height = this.item.h * gridLayoutRowHeight + 'px';
          this.chart.element.svgContainer.htmlElement.style.width = 100 + '%';
        } else {
          this.chart.element.svgContainer.htmlElement.style.height = 300 + 'px';
          this.chart.element.svgContainer.htmlElement.style.width = 100 + '%';
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.lineChart();
  },

  beforeDestroy() {
    if (this.chart.element) {
      this.chart.element.dispose();
    }
  },

  methods: {
    lineChart() {
      let chart = am4core.create(this.$refs.lineChart, am4charts.XYChart);
      chart.responsive.enabled = false;
      chart.language.locale['_decimalSeparator'] = ',';
      chart.language.locale['_thousandSeparator'] = ' ';

      chart.legend = new am4charts.Legend();
      chart.cursor = new am4charts.XYCursor();
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

      dateAxis.dateFormats.setKey('day', 'yyMMdd');
      dateAxis.dateFormats.setKey('week', 'yyMMdd');
      dateAxis.dateFormats.setKey('month', 'yyMMdd');
      dateAxis.dateFormats.setKey('year', 'yyMMdd');
      dateAxis.periodChangeDateFormats.setKey('day', 'yyMMdd');
      dateAxis.periodChangeDateFormats.setKey('week', 'yyMMdd');
      dateAxis.periodChangeDateFormats.setKey('month', 'yyMMdd');
      dateAxis.periodChangeDateFormats.setKey('year', 'yyMMdd');

      dateAxis.renderer.minGridDistance = 90;
      dateAxis.renderer.grid.template.location = 0.5;
      dateAxis.renderer.labels.template.location = 0.5;

      chart.colors.list = [
        am4core.color('#264478'),
        am4core.color('#5B9BD5'),
        am4core.color('#ED7D31'),
        am4core.color('#FFC000'),
        am4core.color('#70AD47'),
        am4core.color('#A5A5A5'),
        am4core.color('#4472C4'),
      ];

      let first = true;
      this.item.data.lineKeys.forEach((key) => {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        valueAxis.min = 0;
        if (first) {
          valueAxis.title.text = this.$t('generic.observations');
          first = false;
        } else {
          valueAxis.renderer.labels.template.disabled = true;
        }

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = key;
        series.dataFields.dateX = 'date';
        series.strokeWidth = 2;
        series.name = this.$t(key);
        series.tooltipText = '{name}: [bold]{valueY}[/]';
      });

      chart.data = this.item.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));

      if (this.item && this.item.h && this.item.w) {
        chart.svgContainer.htmlElement.style.height = this.item.h * gridLayoutRowHeight + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      } else {
        chart.svgContainer.htmlElement.style.height = 300 + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      }

      this.chart.element = chart;
    },
  },
};
</script>

<style lang="scss" scoped>
.line-chart {
  width: 100%;
}
</style>
