<template>
  <table
    v-if="item.xAttribute && item.xAttribute.columnName"
    class="table table-striped table-hover table-condensed table-bordered table-no-break"
  >
    <thead>
      <tr v-if="item.countMethod === 'SUM'">
        <th class="half-width">{{ $t(item.xAttribute.columnName) }}</th>
        <th>{{ $t('alarmsReports.vue.tableHeaders.sum') }}</th>
      </tr>

      <tr v-else-if="item.countMethod === 'COUNT_UNIQUE'">
        <th class="half-width">{{ $t(item.xAttribute.columnName) }}</th>
        <th>
          {{ $t('alarmsReports.vue.tableHeaders.countUnique', { countAttribute: item.countAttribute.columnName }) }}
        </th>
      </tr>

      <tr v-else-if="item.countMethod === 'VISITS'">
        <th class="half-width">{{ $t(item.xAttribute.columnName) }}</th>
        <th>{{ $t('alarmsReports.vue.tableHeaders.numberOfVisits') }}</th>
      </tr>

      <tr v-else-if="item.countMethod === 'DURATION'">
        <th class="half-width">{{ $t(item.xAttribute.columnName) }}</th>

        <th>{{ $t('alarmsReports.vue.tableHeaders.averageVisitDuration') }}</th>
        <th v-if="item.includeMinMax">{{ $t('alarmsReports.vue.tableHeaders.minVisitDuration') }}</th>
        <th v-if="item.includeMinMax">{{ $t('alarmsReports.vue.tableHeaders.maxVisitDuration') }}</th>
      </tr>

      <tr v-else-if="item.countMethod === 'OTHER_DURATION'">
        <th class="half-width">{{ $t(item.xAttribute.columnName) }}</th>

        <th>{{ $t('alarmsReports.vue.tableHeaders.averageDuration') }}</th>
        <th v-if="item.includeMinMax">{{ $t('alarmsReports.vue.tableHeaders.minDuration') }}</th>
        <th v-if="item.includeMinMax">{{ $t('alarmsReports.vue.tableHeaders.maxDuration') }}</th>
      </tr>
    </thead>

    <tbody v-if="item.countMethod === 'SUM' || item.countMethod === 'COUNT_UNIQUE' || item.countMethod === 'VISITS'">
      <tr v-for="(row, index) in item.data.data" :key="index">
        <th>
          {{ row.title }}
        </th>
        <th>
          {{ formatNumberToThousands(row.value) }}
        </th>
      </tr>
    </tbody>

    <tbody
      v-else-if="(item.countMethod === 'DURATION' || item.countMethod === 'OTHER_DURATION') && !item.includeMinMax"
    >
      <tr v-for="(row, index) in item.data.data" :key="index">
        <th>
          {{ row.title }}
        </th>
        <th>{{ formatNumberToThousands(row.average) }} ({{ formatNumberToThousands(row.visits) }})</th>
      </tr>
    </tbody>

    <tbody v-else-if="(item.countMethod === 'DURATION' || item.countMethod === 'OTHER_DURATION') && item.includeMinMax">
      <tr v-for="(row, index) in item.data.data" :key="index">
        <th>
          {{ row.title }}
        </th>
        <th>{{ formatNumberToThousands(row.average) }} ({{ formatNumberToThousands(row.visits) }})</th>
        <th>
          {{ formatNumberToThousands(row.min) }}
        </th>
        <th>
          {{ formatNumberToThousands(row.max) }}
        </th>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss">
.half-width {
  width: 50%;
}

table > tbody > tr > th {
  font-weight: normal;
}
</style>

<script>
export default {
  name: 'ReportTemplateAggregationTable',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatNumberToThousands(value) {
      if (value === '' || value === null) {
        return '';
      }
      const parts = value.toString().split('.');
      const numberWithoutEmptySpace = parts[0].toString().replace(/\s/g, '');
      parts[0] = numberWithoutEmptySpace.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join(',');
    },
  },
};
</script>
