<template>
  <form class="form-horizontal">
    <div class="form-line has-no-margin-bottom">
      <multiselect
        v-model="action.subscribers"
        :options="availableCustomerUsersForType"
        :class="{ 'has-error': errors.subscribers && isSubmitted }"
        label="name"
        track-by="name"
        :allow-empty="true"
        select-label=""
        selected-label=""
        deselect-label=""
        :multiple="true"
        :taggable="true"
        :clear-on-select="true"
        :close-on-select="false"
        :hide-selected="true"
        open-direction="bottom"
        placeholder=""
        data-cy="reportActionBlockSubscribers"
        style="flex: 3"
      >
      </multiselect>
    </div>
  </form>
</template>
<script>
import EventService from '../services/EventService';
import { NaturalSortService } from '../utils/Utils';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ReportActionBlock',

  components: {
    Multiselect,
  },

  mixins: [EventBlockValidationMixin],

  props: {
    action: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      actionTypes: ['EMAIL'],
      availableCustomerUsers: [],
    };
  },

  computed: {
    availableCustomerUsersForType() {
      switch (this.action.type) {
        case 'EMAIL':
        default:
          return this.availableCustomerUsers.filter((user) => user.email !== '');
      }
    },
    isValid() {
      return !!this.action.type && this.action.subscribers.length > 0;
    },
  },

  created() {
    this.getAvailableSubscribers();
    this.action.type = this.actionTypes[0];
  },

  methods: {
    async getAvailableSubscribers() {
      const availableCustomerUsers = await EventService.getCustomerUsers();
      this.availableCustomerUsers = NaturalSortService.naturalSortArrayByKey(availableCustomerUsers, 'name');
    },

    onDelete() {
      this.$emit('delete-action');
    },

    showErrors() {
      const errors = {};

      errors.subscribers = this.action.subscribers.length === 0;

      this.errors = errors;
    },
  },
};
</script>
