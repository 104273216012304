<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else class="print-report">
      <div class="content">
        <div class="cover-sheet">
          <img src="/images/cover-sheet-line.png" class="cover-sheet-line" />

          <img src="/images/cover-sheet-logo.png" class="cover-sheet-logo" />

          <div class="cover-sheet-text">
            <p class="cover-sheet-title">Name: {{ report.name }}</p>

            <p class="cover-sheet-paragraph">Description: {{ report.description }}</p>

            <p class="cover-sheet-paragraph">Report generated: {{ report.nowInTimezone }}</p>

            <p class="cover-sheet-paragraph">Report time scope: {{ report.timeScope }}</p>

            <p class="cover-sheet-paragraph">
              Number of observations: {{ formatNumber(report.numberOfObservations) }}
              <span v-if="report.numberOfObservations > 2000000">{{ $t('alarmsReports.vue.betaLimit') }}</span>
            </p>

            <p class="cover-sheet-paragraph">
              Created by: {{ $t(report.author) }} ({{ report.creationDateInTimezone }})
            </p>

            <p class="cover-sheet-paragraph">
              Edited by: {{ $t(report.editedBy) }} ({{ report.editedDateInTimezone }})
            </p>
          </div>

          <div class="cover-sheet-fleemonitor">
            www.fleetmonitor.com
          </div>
        </div>

        <div class="page-break"></div>

        <div class="navbar-default">
          <img v-if="!me.logoData" class="logo" src="images/FM_OneColor_White_350.png" />
          <img v-if="me.logoData" class="logo" :src="me.logoData" />
          <div class="report-name">{{ report.name }}</div>
          <!-- Invisible logo used for centering text and ensure text overflows correctly -->
          <img v-if="!me.logoData" class="logo invisible" src="images/FM_OneColor_White_350.png" />
          <img v-if="me.logoData" class="logo invisible" :src="me.logoData" />
          <div v-if="me.logoData" class="report-powered-by">
            powered by Fleetmonitor
          </div>
        </div>

        <div
          v-for="item in sortedChartTiles"
          :key="item.i"
          :class="{ 'col-md-6 col-print-6': item.w === 1, 'col-md-12 col-print-12': item.w === 2 }"
          :style="{ 'margin-bottom': '15px' }"
        >
          <div v-if="!hasData(item)" class="text-center">
            <h4>{{ item.title }}</h4>
            {{ $t('alarmsReports.vue.noDataForReportCriteria') }}
          </div>
          <div v-else class="text-center chart-item">
            <h4 class="item-title">
              {{ item.title }}

              <span v-if="item.type === 'LINE_CHART'" class="item-shown-matched">
                {{
                  $t('alarmsReports.vue.showingMatchedFirstPartLineChart', {
                    number: formatNumber(item.data.numberOfAfterLimit),
                  })
                }}
              </span>

              <span v-if="item.type !== 'LINE_CHART'" class="item-shown-matched">
                {{
                  $t('alarmsReports.vue.showingMatchedFirstPart', {
                    number: formatNumber(item.data.numberOfAfterLimit),
                  })
                }}
                <br />
                {{ showingMatchedSecondPartTranslation(item) }}
              </span>
            </h4>

            <report-template-bar-chart v-if="item.type === 'BAR_CHART'" :item="item"></report-template-bar-chart>

            <report-template-line-chart v-if="item.type === 'LINE_CHART'" :item="item"></report-template-line-chart>

            <report-template-donut-chart
              v-if="item.type === 'DONUT_CHART'"
              :item="item"
              :resize="resize"
            ></report-template-donut-chart>
          </div>
        </div>

        <div v-if="sortedChartTiles.length > 0 && aggregationTableTiles.length > 0" class="page-break"></div>

        <div
          v-for="(item, tableTileIndex) in aggregationTableTiles"
          :key="'table-' + tableTileIndex"
          class="chart-item text-center col-md-12 col-print-12"
          style="break-before: page;"
        >
          <h4>{{ item.title }}</h4>

          <report-template-aggregation-table :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-print-6 {
  width: 50%;
  float: left;
}
.col-print-12 {
  width: 100%;
  float: left;
}

.print-report {
  width: 289mm;
  margin: 0 auto;
  padding: 30px;
  outline: 2px solid #eeeeee;
  position: relative;

  &.not-wide {
    overflow-x: scroll;
  }

  .header,
  .header-space,
  .footer,
  .footer-space {
    height: 60px;
  }
  .header {
    position: absolute;
    top: 30px;
  }
  .footer {
    position: absolute;
    bottom: 30px;
  }
  .navbar-default {
    position: relative;
    height: 61px;
    display: flex;
    justify-content: space-between;
    .report-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      line-height: 61px;
      vertical-align: bottom;
      font-size: 30px;
      color: #ffffff;
    }
  }
  .report-powered-by {
    font-size: 10px;
    float: right;
    color: #fff;
    margin-right: 5px;
    position: absolute;
    right: 0;
    bottom: 4px;
  }

  .item-title {
    position: relative;
  }

  .item-shown-matched {
    position: absolute;
    top: 0;
    right: 0.5cm;
    font-size: 10px;
    text-align: right;
  }
}

@media print {
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .print-report {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    .chart-item {
      page-break-inside: avoid;
      display: inline-block;
      width: 100%;
    }
    .page-break {
      clear: both;
      page-break-after: always;
    }
    .navbar-default {
      .report-name {
        color: #ffffff !important;
      }
    }
    .table-striped > tbody > tr:nth-child(odd),
    .table-striped > tbody > tr:nth-child(odd) th {
      background-color: #ededed !important;
    }
    .header,
    .header-space,
    .footer,
    .footer-space {
      height: 60px;
    }
    .header {
      position: fixed;
      top: 0;
    }
    .footer {
      position: fixed;
      bottom: 0;
    }
  }

  .cover-sheet {
    position: relative;
    height: 38cm;

    .cover-sheet-line {
      position: absolute;
      top: 1.5cm;
      left: 1.5cm;
      height: 36cm;
    }

    .cover-sheet-logo {
      position: absolute;
      width: 15cm;
      top: 4cm;
      right: 1.5cm;
    }

    .cover-sheet-text {
      position: absolute;
      top: 20cm;
      left: 4cm;
      width: 20cm;

      h2 {
        margin-bottom: 10px;
      }
    }

    .cover-sheet-title {
      font-size: 35px;
    }

    .cover-sheet-paragraph {
      font-size: 25px;
    }

    .cover-sheet-fleemonitor {
      position: absolute;
      bottom: 0.5cm;
      left: 4cm;
      font-size: 15px;
      color: #cccccc !important;
    }
  }

  * {
    color-adjust: exact;
  }
}
</style>

<script>
import EventService from '../services/EventService';
import { GroupByService, NaturalSortService } from '../utils/Utils';
import ReportTemplateLineChart from '../report-template/ReportTemplateLineChart.vue';
import ReportTemplateBarChart from '../report-template/ReportTemplateBarChart.vue';
import ReportTemplateDonutChart from '../report-template/ReportTemplateDonutChart.vue';
import ReportTemplateAggregationTable from '../report-template/ReportTemplateAggregationTable';

export default {
  name: 'PrintReport',

  components: {
    ReportTemplateAggregationTable,
    ReportTemplateDonutChart,
    ReportTemplateBarChart,
    ReportTemplateLineChart,
  },

  data() {
    return {
      reportTiles: [],
      reportName: '',
      report: {},
      isLoading: false,
      me: {},
      resize: false,
    };
  },

  computed: {
    sortedChartTiles() {
      const chartTiles = this.reportTiles.filter(
        (tile) => tile.type !== 'TABLE' && tile.type !== 'AGGREGATION_TABLE' && tile.type !== 'CSV'
      );

      const chartTilesGroupedOnYAxis = GroupByService.groupByKey(chartTiles, 'y');
      const groupedYAxes = Object.keys(chartTilesGroupedOnYAxis);

      const sortYAxisGroupedChartTilesByXAxis = (yAxis) => {
        const chartTilesForYAxis = chartTilesGroupedOnYAxis[yAxis];

        return NaturalSortService.naturalSortNumberArrayByKeyAndSortOrder(chartTilesForYAxis, 'x', false);
      };

      return groupedYAxes.flatMap(sortYAxisGroupedChartTilesByXAxis);
    },

    aggregationTableTiles() {
      return this.reportTiles.filter((tile) => tile.includeTable || tile.type === 'AGGREGATION_TABLE');
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      this.me = await EventService.getMe();
      this.report = await EventService.getReportCoverSheetByReportId(this.$route.params.reportId);

      const reportTiles = await EventService.getReportTemplateTilesByReportId(this.$route.params.reportId);

      const resolvedReportTiles = reportTiles.map((reportTile) => {
        switch (reportTile.type) {
          case 'BAR_CHART':
          case 'DONUT_CHART':
          case 'AGGREGATION_TABLE':
            return this.transformChartData(reportTile);
          case 'LINE_CHART':
            return this.transformLineChartData(reportTile);
          default:
            return reportTile;
        }
      });

      this.reportTiles = await Promise.all(resolvedReportTiles);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      const delayToEnsureTilesHasLoadedWhenPrintingInWkhtmlToPdf = 2500 + this.reportTiles.length * 1000;
      setTimeout(() => {
        // Used by wkhtmltopdf to know when report is ready to print
        window.status = 'ready';
      }, delayToEnsureTilesHasLoadedWhenPrintingInWkhtmlToPdf);
    }
  },

  methods: {
    async transformChartData(reportTile) {
      const dataWithSuggestedTitlesPromises = await reportTile.data.data.map((item) =>
        this.toItemWithCorrectTitle(item, reportTile)
      );

      const dataWithSuggestedTitles = await Promise.all(dataWithSuggestedTitlesPromises);

      return {
        ...reportTile,
        data: { ...reportTile.data, data: dataWithSuggestedTitles, lineKeys: reportTile.data.lineKeys },
      };
    },

    async toItemWithCorrectTitle(item, reportTile) {
      const titleSuggestions = await EventService.getColumnSuggestions(reportTile.xAttribute, item.title);
      const probablyCorrectTitleSuggestion = titleSuggestions
        .filter((titleSuggestion) => titleSuggestion.text !== item.title)
        .filter((titleSuggestion) => titleSuggestion.text.toLowerCase() === item.title)[0];

      if (probablyCorrectTitleSuggestion) {
        return { ...item, title: probablyCorrectTitleSuggestion.text };
      } else {
        return { ...item, title: this.$t(item.title) };
      }
    },

    async transformLineChartData(reportTile) {
      const lineKeyPromises = await reportTile.data.lineKeys.map((key) =>
        this.toLineKeyWithCorrectValue(key, reportTile)
      );

      const lineKeysWithSuggestedValues = NaturalSortService.naturalSortArray(await Promise.all(lineKeyPromises));

      const dataWithSuggestedLineKeyValues = reportTile.data.data.map((item) =>
        this.transformItemLowerCaseAttributesToSuggestedAttributes(item, lineKeysWithSuggestedValues)
      );

      return {
        ...reportTile,
        data: {
          data: dataWithSuggestedLineKeyValues,
          lineKeys: lineKeysWithSuggestedValues,
          numberOfAfterLimit: reportTile.data.numberOfAfterLimit,
        },
      };
    },

    async toLineKeyWithCorrectValue(key, reportTile) {
      const lineKeySuggestions = await EventService.getColumnSuggestions(reportTile.xAttribute, key);
      const probablyCorrectLineKeySuggestion = lineKeySuggestions
        .filter((l) => l.text !== key)
        .filter((l) => l.text.toLowerCase() === key)[0];

      if (probablyCorrectLineKeySuggestion) {
        return probablyCorrectLineKeySuggestion.text;
      } else {
        return key;
      }
    },

    // replace lineKeys based on the response from suggestions, for example:
    // { 'value': 2, 'other val': 3, 'special': 4 } with { 'Value': 2, 'Other VAL': 3, 'SPECIAL': 4 }
    transformItemLowerCaseAttributesToSuggestedAttributes(item, lineKeysWithSuggestedValues) {
      return {
        date: item.date,
        ...lineKeysWithSuggestedValues.reduce((prev, lineKey) => {
          return {
            ...prev,
            [lineKey]: item[lineKey.toLowerCase()],
          };
        }, {}),
      };
    },

    hasData(item) {
      return item.data && item.data.data && item.data.data.length > 0;
    },

    showingMatchedSecondPartTranslation(item) {
      switch (item.countMethod) {
        case 'SUM':
        case 'COUNT_UNIQUE':
          return this.$t('alarmsReports.vue.showingMatchedSecondPartObservations', {
            number: this.formatNumber(item.data.numberOfBeforeLimit),
          });

        case 'OTHER_DURATION':
          return this.$t('alarmsReports.vue.showingMatchedSecondPartOtherDuration', {
            number: this.formatNumber(item.data.numberOfBeforeLimit),
          });

        case 'VISITS':
        case 'DURATION':
          return this.$t('alarmsReports.vue.showingMatchedSecondPartVisits', {
            number: this.formatNumber(item.data.numberOfBeforeLimit),
          });
        default:
          break;
      }
    },

    formatNumber(num) {
      if (!num) {
        return num;
      }
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    },
  },
};
</script>
