import { NaturalSortService } from '../utils/Utils';

export default class ChartPlaceholderDataService {
  static defaultValues(item) {
    let limit = item.limit ? parseInt(item.limit) : 1;

    if (limit < 1) {
      limit = 1;
    }

    switch (item.type) {
      case 'LINE_CHART':
        limit = ChartPlaceholderDataService.getCurrentLimitOrMaxLimit(limit, 7);
        break;
      case 'DONUT_CHART':
        limit = ChartPlaceholderDataService.getCurrentLimitOrMaxLimit(limit, 15);
        break;
      case 'BAR_CHART':
      case 'AGGREGATION_TABLE':
      default:
        if (item.includeMinMax) {
          limit = ChartPlaceholderDataService.getCurrentLimitOrMaxLimit(limit, 15);
        }
        limit = ChartPlaceholderDataService.getCurrentLimitOrMaxLimit(limit, 50);
        break;
    }

    let data = [];
    let translatedXAttribute = '';

    let lineKeys = [];

    switch (item.type) {
      case 'BAR_CHART':
      case 'DONUT_CHART':
      case 'AGGREGATION_TABLE':
        translatedXAttribute = item.xAttribute
          ? this.$t(item.xAttribute.columnName)
          : this.$t('alarmsReports.vue.placeholder');

        if (item.countMethod === 'DURATION' || item.countMethod === 'OTHER_DURATION') {
          if (item.includeMinMax) {
            data = Array(limit)
              .fill()
              .map((_, i) => ({
                title: `${translatedXAttribute} ${i + 1}`,
                average: i + 1,
                visits: i + 1,
                min: i,
                max: i + 2,
              }));
          } else {
            data = Array(limit)
              .fill()
              .map((_, i) => ({ title: `${translatedXAttribute} ${i + 1}`, average: i + 1, visits: i + 1 }));
          }
        } else {
          data = Array(limit)
            .fill()
            .map((_, i) => ({ title: `${translatedXAttribute} ${i + 1}`, value: i + 1 }));
        }

        if (item.sortField) {
          switch (item.sortField.dataField) {
            case 'ALPHABETICALLY':
              data = NaturalSortService.naturalSortArrayByKeyAndSortOrder(data, 'title', item.sortDesc);
              break;
            case 'NUMERICALLY':
              data = NaturalSortService.naturalSortNumberArrayByKeyAndSortOrder(data, 'value', item.sortDesc);
              break;
          }
        }

        break;
      case 'LINE_CHART':
        translatedXAttribute = item.xAttribute
          ? this.$t(item.xAttribute.columnName)
          : this.$t('alarmsReports.vue.placeholder');

        lineKeys = Array(limit)
          .fill()
          .map((_, i) => `${translatedXAttribute} ${i + 1}`);

        data = ChartPlaceholderDataService.getLineTableData(30, lineKeys);
        break;
      case 'TABLE':
        data = ChartPlaceholderDataService.getLineTableData(limit, lineKeys);
        break;
    }

    return { data, lineKeys };
  }

  static getLineTableData(monthLength, lineKeys) {
    const numbers = {};

    lineKeys.forEach((key) => {
      numbers[key] = this.randomValue();
    });

    return Array(monthLength)
      .fill()
      .map((_, i) => {
        const d = new Date();
        d.setDate(d.getDate() - i);
        return d;
      })
      .map((date) => {
        const toReturn = { date };

        lineKeys.forEach((key) => {
          toReturn[key] = this.randomValueRelatedToLastValue(numbers[key]);
        });

        return toReturn;
      });
  }

  static randomValueRelatedToLastValue(lastNumber) {
    if (Math.random() < 0.4) {
      return lastNumber + Math.floor(Math.random() * 3) + 1;
    } else {
      return lastNumber - Math.floor(Math.random() * 5) + 1;
    }
  }

  static randomValue() {
    return Math.floor(Math.random() * 25) + 1;
  }

  static getCurrentLimitOrMaxLimit(currentLimit, maxLimit) {
    if (currentLimit < 0 || currentLimit > maxLimit) {
      return maxLimit;
    }

    return currentLimit;
  }
}
