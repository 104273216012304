<template>
  <div>
    <div class="panel panel-primary">
      <div
        class="panel-heading yf-panel-heading-default-panel-style pointer"
        :title="panelHeadingTitle"
        @click="toggle"
      >
        <h4 class="panel-title tile-title">
          <yf-panel-heading-multiselect
            v-model="visualizationType"
            :text-before="tile.title"
            :options="visualizationTypes"
            :custom-label="(vType) => $t(`alarmsReports.vue.visualizationTypes.${vType}`)"
            :width="'300px'"
            @input="onVisualizationTypeChange"
          ></yf-panel-heading-multiselect>
        </h4>

        <event-validation-box :is-valid="isValid" :is-submitted="isSubmitted" />

        <button
          type="button"
          class="close action-close"
          :style="{ 'margin-right': isSubmitted ? '25px' : '0' }"
          :title="$t('generic.delete')"
          @click.stop="$emit('delete')"
        >
          <i class="glyphicons glyphicons-remove-2 before-black"></i>
        </button>
      </div>
      <div v-if="isOpen" class="panel-body">
        <form>
          <div v-if="visualizationType === 'CHART'" class="form-group">
            <div class="row">
              <div :class="[tile.countMethod === 'COUNT_UNIQUE' ? 'col-md-2' : 'col-md-4']">
                <label>{{ $t('alarmsReports.vue.countMethod') }}</label>
                <multiselect
                  v-model="tile.countMethod"
                  :options="countMethods"
                  :custom-label="translateCountMethod"
                  :class="{ 'has-error': errors.countMethod && isSubmitted }"
                  :allow-empty="false"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                  data-cy="reportTemplateCount"
                  @input="onCountMethodChange"
                >
                </multiselect>
                <label
                  v-if="
                    (tile.countMethod === 'DURATION' || tile.countMethod === 'OTHER_DURATION') &&
                      (tile.type === 'BAR_CHART' || tile.type === 'AGGREGATION_TABLE')
                  "
                  id="includeMinMax"
                  class="inline-checkbox"
                >
                  <div>{{ $t('alarmsReports.vue.includeMinMax') }}</div>
                  <input v-model="tile.includeMinMax" type="checkbox" />
                </label>
              </div>

              <div v-if="tile.countMethod === 'COUNT_UNIQUE'" id="countAttribute" class="col-md-2">
                <label>{{ $t('Count attribute') }}</label>
                <multiselect
                  v-if="tile.countMethod === 'COUNT_UNIQUE'"
                  v-model="tile.countAttribute"
                  :options="countAttributes"
                  track-by="dataField"
                  :custom-label="({ columnName }) => $t(columnName)"
                  :class="{ 'has-error': errors.countAttribute && isSubmitted }"
                  group-values="columns"
                  group-label="groupedBy"
                  :multiple="false"
                  :taggable="false"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allow-empty="false"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                >
                </multiselect>
              </div>

              <div class="col-md-4 form-group">
                <label> {{ $t('alarmsReports.vue.attributes') }}</label>
                <multiselect
                  id="attributes"
                  v-model="chosenAttributes"
                  :options="attributes"
                  track-by="dataField"
                  :custom-label="({ columnName }) => $t(columnName)"
                  :class="{ 'has-error': errors.chosenAttributes && isSubmitted }"
                  group-values="columns"
                  group-label="groupedBy"
                  :multiple="false"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allow-empty="false"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                  data-cy="reportTemplateAttribute"
                  @input="onAttributeChange"
                >
                </multiselect>
              </div>

              <div class="col-md-4 form-group">
                <label>
                  {{ $t('alarmsReports.vue.type') }}
                </label>
                <multiselect
                  v-model="tile.type"
                  :options="types"
                  :custom-label="translateType"
                  :allow-empty="false"
                  :class="{ 'has-error': errors.type && isSubmitted }"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                  data-cy="reportTemplateType"
                  @input="onTypeChange"
                >
                </multiselect>
                <label
                  v-show="tile.type === 'BAR_CHART' || tile.type === 'DONUT_CHART'"
                  id="includeTable"
                  class="inline-checkbox"
                >
                  <div>{{ $t('alarmsReports.vue.includeAggregatedTable') }}</div>
                  <input v-model="tile.includeTable" type="checkbox" />
                </label>

                <label v-show="tile.type === 'BAR_CHART'" id="rotate" class="inline-checkbox has-small-margin-left">
                  <div>{{ $t('alarmsReports.vue.rotate') }}</div>
                  <input v-model="tile.rotate" type="checkbox" />
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4" :class="{ 'has-error': errors.limit && isSubmitted }">
                <label>{{ $t('alarmsReports.vue.limit') }}</label>

                <input
                  v-model="tile.limit"
                  class="form-control"
                  min="1"
                  :max="maxLimit"
                  type="number"
                  :title="limitTitle"
                  data-cy="reportTemplateLimit"
                />
              </div>

              <div v-show="tile.sortField" id="sortBy" class="col-md-4">
                <label>{{ $t('alarmsReports.vue.sortField') }}</label>
                <multiselect
                  v-model="tile.sortField"
                  :options="chartSortAttributes"
                  :custom-label="({ columnName }) => $t(columnName)"
                  :class="{ 'has-error': errors.sortField && isSubmitted }"
                  :allow-empty="false"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                >
                  <template slot="noOptions">{{ $t('alarmsReports.vue.noAttributesChosen') }}</template>
                </multiselect>
                <label class="inline-checkbox">
                  <div>{{ $t('alarmsReports.vue.sortDesc') }}</div>
                  <input v-model="tile.sortDesc" type="checkbox" />
                </label>
              </div>

              <div class="col-md-4" :class="{ 'has-error': errors.title && isSubmitted }">
                <label>
                  {{ $t('alarmsReports.vue.title') }}
                </label>
                <input id="chartTitle" v-model="tile.title" class="form-control" type="text" />
                <span v-if="errors.invalidTitle && isSubmitted" class="help-block error-text">
                  {{ $t('settings.bad.name') }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="visualizationType === 'TABLE' || visualizationType === 'CSV'" class="form-group">
            <div class="row">
              <div class="col-md-12 form-group">
                <label> {{ $t('alarmsReports.vue.attributes') }}</label>
                <multiselect
                  v-model="chosenAttributes"
                  :options="tableAttributes"
                  track-by="dataField"
                  :custom-label="({ columnName }) => $t(columnName)"
                  :class="{
                    'has-error': errors.chosenAttributes && isSubmitted,
                  }"
                  group-values="columns"
                  group-label="groupedBy"
                  :multiple="true"
                  :taggable="true"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :hide-selected="true"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                  data-cy="reportTemplateAttribute"
                  @remove="onRemoveAttributeTag"
                >
                </multiselect>

                <div v-show="isGeofenceAreaChosen || isGeofenceTypeChosen" id="geofenceRadio">
                  <label class="radio-inline radio-inline-label">{{ $t('geofence.level') }}</label>

                  <label class="radio-inline">
                    <input v-model="tile.geofenceLevel" type="radio" name="geofenceLevel" value="1" @input="setDefaultSortBy()" /> 1
                  </label>

                  <label class="radio-inline">
                    <input v-model="tile.geofenceLevel" type="radio" name="geofenceLevel" value="2" @input="setDefaultSortBy()" /> 2
                  </label>

                  <label class="radio-inline">
                    <input v-model="tile.geofenceLevel" type="radio" name="geofenceLevel" value="3" @input="setDefaultSortBy()" /> 3
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4" :class="{ 'has-error': errors.limit && isSubmitted }">
                <label>{{ $t('alarmsReports.vue.limit') }}</label>

                <input
                  v-if="visualizationType === 'TABLE'"
                  v-model="tile.limit"
                  class="form-control"
                  min="1"
                  :max="maxLimit"
                  type="number"
                  :title="limitTitle"
                  data-cy="reportTemplateLimit"
                />

                <input
                  v-if="visualizationType === 'CSV'"
                  v-model="tile.limit"
                  class="form-control"
                  min="1"
                  :max="maxLimit"
                  type="number"
                  :title="limitTitle"
                  data-cy="reportTemplateLimit"
                />
              </div>

              <div class="col-md-4">
                <label>{{ $t('alarmsReports.vue.sortField') }}</label>
                <multiselect
                  v-model="tile.sortField"
                  :options="sortAttributes"
                  :custom-label="({ columnName }) => $t(columnName)"
                  :class="{ 'has-error': errors.sortField && isSubmitted }"
                  :allow-empty="false"
                  group-values="columns"
                  group-label="groupedBy"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  placeholder=""
                  data-cy="reportTemplateSortBy"
                >
                  <template slot="noOptions">{{ $t('alarmsReports.vue.noAttributesChosen') }}</template>
                </multiselect>
                <label class="inline-checkbox">
                  <div>{{ $t('alarmsReports.vue.sortDesc') }}</div>
                  <input v-model="tile.sortDesc" type="checkbox" />
                </label>
              </div>

              <div class="col-md-4" :class="{ 'has-error': errors.title && isSubmitted }">
                <label>
                  {{ $t('alarmsReports.vue.title') }}
                </label>
                <input :id="'tableTitle-' + tile.i" v-model="tile.title" class="form-control" type="text" />
                <span v-if="errors.invalidTitle && isSubmitted" class="help-block error-text">
                  {{ $t('settings.bad.name') }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="isTable || isCsv" class="form-group">
            <label>{{ $t('alarmsReports.vue.preview') }}</label>

            <div class="table-wrapper">
              <table class="table table-striped table-hover table-condensed table-bordered table-no-break">
                <thead>
                  <report-template-preview-table-header :item="tile" :attributes="tablePreviewAttributes" />
                </thead>
                <tbody>
                  <tr>
                    <th>{{ $t('alarmsReports.vue.placeholder') }}</th>
                    <th>{{ $t('alarmsReports.vue.placeholder') }}</th>
                    <th>{{ $t('alarmsReports.vue.placeholder') }}</th>

                    <th v-for="attribute in tablePreviewAttributes" :key="attribute.columnName">
                      {{ $t('alarmsReports.vue.placeholder') }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { deepClone, isOnlyDigits, NaturalSortService } from '../utils/Utils';
import Multiselect from 'vue-multiselect';
import YfPanelHeadingMultiselect from '../yf-components/YfPanelHeadingMultiselect';
import { GeofenceFieldsService } from '../services/GeofenceFieldsService';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import ChartPlaceholderDataService from '../services/ChartPlaceholderDataService';
import {
  alphabetically,
  defaultChartTile,
  numerically,
  UNICODE_ALPHANUMERIC_EXTENDED_XRegExp,
} from '../utils/Constants';
import EventValidationBox from '../event/EventValidationBox';
import ReportTemplatePreviewTableHeader from './ReportTemplatePreviewTableHeader';
import XRegExp from 'xregexp';

const ToggleMixin = {
  props: {
    isOpenInitially: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isOpen: this.isOpenInitially,
    };
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};

const RedrawChartMixin = {
  computed: {
    _chartAttributes() {
      return (
        this.isChart && this.countMethod && this.xAttribute && this.type && this.isCountAttributeValid && this.sortField
      );
    },

    title() {
      return this.tile && this.tile.title;
    },
    type() {
      return this.tile && this.tile.type;
    },
    countMethod() {
      return this.tile && this.tile.countMethod;
    },
    countAttribute() {
      return this.tile && this.tile.countAttribute;
    },
    limit() {
      return this.tile && this.tile.limit;
    },
    xAttribute() {
      return this.tile && this.tile.xAttribute;
    },
    includeMinMax() {
      return this.tile && this.tile.includeMinMax;
    },
    rotate() {
      return this.tile && this.tile.rotate;
    },
    sortField() {
      if (this.tile.type === 'BAR_CHART' || this.tile.type === 'AGGREGATION_TABLE') {
        return this.tile && this.tile.sortField;
      }

      return true;
    },
    sortDesc() {
      return this.tile && this.tile.sortDesc;
    },
  },

  methods: {
    calculateDefaultValuesForTile() {
      this.tile.data = { ...ChartPlaceholderDataService.defaultValues.bind(this)(this.tile) };
      this.$emit('redraw-chart', this.tile);
    },
  },

  watch: {
    countMethod() {
      if (this._chartAttributes) {
        this.calculateDefaultValuesForTile();
      }
    },
    countAttribute() {
      if (this._chartAttributes) {
        this.calculateDefaultValuesForTile();
      }
    },
    xAttribute() {
      if (this._chartAttributes) {
        this.calculateDefaultValuesForTile();
      }
    },
    type() {
      if (this._chartAttributes) {
        this.calculateDefaultValuesForTile();
      }
    },
    sortField() {
      if (this._chartAttributes) {
        this.calculateDefaultValuesForTile();
      }
    },
    sortDesc() {
      if (this._chartAttributes) {
        this.calculateDefaultValuesForTile();
      }
    },
    limit() {
      this.calculateDefaultValuesForTile();
    },
    includeMinMax() {
      this.calculateDefaultValuesForTile();
    },
    rotate() {
      this.calculateDefaultValuesForTile();
    },
  },
};

export default {
  name: 'ReportTemplateTileCreate',

  components: {
    YfPanelHeadingMultiselect,
    Multiselect,
    EventValidationBox,
    ReportTemplatePreviewTableHeader,
  },

  mixins: [ToggleMixin, RedrawChartMixin, EventBlockValidationMixin],

  props: {
    tile: {
      type: Object,
      required: false,
      default: null,
    },
    availableAttributes: {
      type: Array,
      required: true,
    },
    tableAdded: {
      type: Boolean,
      default: false,
    },
    csvAdded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chosenAttributes: [],
      chosenTableAttributes: [],
      loading: false,
      visualizationType: 'CHART',
    };
  },

  computed: {
    panelHeadingTitle() {
      if (this.isOpen) {
        return this.$t('alarmsReports.vue.hide');
      } else {
        return this.$t('alarmsReports.vue.show');
      }
    },

    visualizationTypes() {
      let visualizationTypes = ['CHART', 'TABLE', 'CSV'];

      if (this.tableAdded) {
        visualizationTypes = visualizationTypes.filter((t) => t !== 'TABLE');
      }

      if (this.csvAdded) {
        visualizationTypes = visualizationTypes.filter((t) => t !== 'CSV');
      }

      return visualizationTypes;
    },

    chartSortAttributes() {
      return [{ ...alphabetically }, { ...numerically }];
    },

    sortAttributes() {
      return [
        {
          groupedBy: this.$t('alarmsReports.vue.groupedBy.defaultAttributes'),
          columns: [
            {
              columnName: 'header.assetId',
              dataField: 'name',
            },
            {
              columnName: 'header.date',
              dataField: 'time',
            },
          ]
        },
        {
          groupedBy: this.$t('alarmsReports.vue.groupedBy.addedAttributes'),
          columns: [
            ...this.tablePreviewAttributes
              .filter((attribute) => attribute.columnName !== 'header.time')
              .filter((attribute) => attribute.dataField !== 'longLat'),
          ]
        }
      ]
    },

    tablePreviewAttributes() {
      let tablePreviewAttributes = this.chosenAttributes ? [...this.chosenAttributes] : [];

      if (this.isGeofenceAreaChosen && this.isGeofenceTypeChosen) {
        tablePreviewAttributes = [
          ...tablePreviewAttributes.filter((attribute) => {
            return attribute.dataField !== 'geofenceArea' && attribute.dataField !== 'geofenceType';
          }),
          ...GeofenceFieldsService.generateGeofenceFields(this.tile.geofenceLevel),
        ];
      } else if (this.isGeofenceAreaChosen) {
        tablePreviewAttributes = [
          ...tablePreviewAttributes.filter((attribute) => attribute.dataField !== 'geofenceArea'),
          ...GeofenceFieldsService.generateGeofenceAreaFields(this.tile.geofenceLevel),
        ];
      } else if (this.isGeofenceTypeChosen) {
        tablePreviewAttributes = [
          ...tablePreviewAttributes.filter((attribute) => attribute.dataField !== 'geofenceType'),
          ...GeofenceFieldsService.generateGeofenceTypeFields(this.tile.geofenceLevel),
        ];
      }
      tablePreviewAttributes.sort((a, b) => {
        return NaturalSortService.naturalSort(this.$t(a.columnName).toLowerCase(), this.$t(b.columnName).toLowerCase());
      });
      return tablePreviewAttributes;
    },

    tableAttributes() {
      return [
        ...this.availableAttributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => {
              return column.supportedChartTypes.includes('TABLE');
            }),
          };
        }),
      ];
    },

    attributes() {
      let attributes = [
        ...this.availableAttributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => {
              return column.supportedChartTypes.length !== 0 && column.supportedCountMethods.length !== 0;
            }),
          };
        }),
      ];

      if (this.tile.countMethod) {
        attributes = attributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => column.supportedCountMethods.includes(this.tile.countMethod)),
          };
        });
      }

      if (this.tile.type) {
        attributes = attributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => column.supportedChartTypes.includes(this.tile.type)),
          };
        });
      }

      if (this.tile.countAttribute) {
        attributes = attributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => column.dataField !== this.tile.countAttribute.dataField),
          };
        });
      }

      return attributes;
    },

    countAttributes() {
      let countAttributes = [
        ...this.availableAttributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => {
              return column.supportedChartTypes.length !== 0 && column.supportedCountMethods.length !== 0;
            }),
          };
        }),
      ];

      if (this.tile.countMethod) {
        countAttributes = countAttributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => column.supportedCountMethods.includes(this.tile.countMethod)),
          };
        });
      }

      if (this.tile.type) {
        countAttributes = countAttributes.map((attribute) => {
          return {
            ...attribute,
            columns: attribute.columns.filter((column) => column.supportedChartTypes.includes(this.tile.type)),
          };
        });
      }

      let chosenAttribute = this.chosenAttributes && this.chosenAttributes.length ? this.chosenAttributes[0] : false;

      if (chosenAttribute) {
        countAttributes = countAttributes.map((countAttribute) => {
          return {
            ...countAttribute,
            columns: countAttribute.columns.filter((column) => column !== chosenAttribute),
          };
        });
      }

      return countAttributes;
    },

    countMethods() {
      let defaultCountMethods = ['SUM', 'COUNT_UNIQUE', 'VISITS', 'DURATION', 'OTHER_DURATION'];

      switch (this.tile.type) {
        case 'DONUT_CHART':
          defaultCountMethods = ['SUM', 'COUNT_UNIQUE', 'VISITS'];
          break;
        case 'LINE_CHART':
          defaultCountMethods = ['SUM', 'COUNT_UNIQUE'];
          break;
      }

      if (this.chosenAttributes && this.chosenAttributes.length === 1) {
        defaultCountMethods = defaultCountMethods.filter((countMethod) =>
          this.chosenAttributes[0].supportedCountMethods.includes(countMethod)
        );
      }

      return defaultCountMethods;
    },

    types() {
      let types = ['BAR_CHART', 'DONUT_CHART', 'LINE_CHART', 'AGGREGATION_TABLE'];

      switch (this.tile.countMethod) {
        case 'SUM':
        case 'COUNT_UNIQUE':
          types = ['BAR_CHART', 'DONUT_CHART', 'LINE_CHART', 'AGGREGATION_TABLE'];
          break;
        case 'VISITS':
          types = ['BAR_CHART', 'DONUT_CHART', 'AGGREGATION_TABLE'];
          break;
        case 'DURATION':
        case 'OTHER_DURATION':
          types = ['BAR_CHART', 'AGGREGATION_TABLE'];
          break;
      }

      if (this.chosenAttributes && this.chosenAttributes.length === 1) {
        types = types.filter((type) => this.chosenAttributes[0].supportedChartTypes.includes(type));
      }

      return types;
    },

    isGeofenceAreaChosen() {
      return this.chosenAttributes && this.chosenAttributes.some((attribute) => attribute.dataField === 'geofenceArea');
    },

    isGeofenceTypeChosen() {
      return this.chosenAttributes && this.chosenAttributes.some((attribute) => attribute.dataField === 'geofenceType');
    },

    isValid() {
      if (this.isSubmitted) {
        this.showErrors();
      }

      if (this.isTable || this.isCsv) {
        return (
          !!this.title &&
          this.isChosenAttributesValid &&
          !!this.type &&
          this.isCountAttributeValid &&
          this.isLimitValid &&
          this.isSortFieldValid &&
          this.isTitleValid
        );
      }
      return (
        !!this.title &&
        this.isChosenAttributesValid &&
        !!this.type &&
        this.isCountAttributeValid &&
        !!this.countMethod &&
        this.isLimitValid &&
        this.isTitleValid
      );
    },

    isTable() {
      return this.tile.type === 'TABLE';
    },

    isCsv() {
      return this.tile.type === 'CSV';
    },

    isChart() {
      return (
        this.tile.type === 'BAR_CHART' ||
        this.tile.type === 'LINE_CHART' ||
        this.tile.type === 'DONUT_CHART' ||
        this.tile.type === 'AGGREGATION_TABLE'
      );
    },

    isChosenAttributesValid() {
      return this.chosenAttributes ? this.chosenAttributes.length > 0 : false;
    },

    isCountAttributeValid() {
      if (this.tile.countMethod === 'COUNT_UNIQUE') {
        return this.tile.countAttribute;
      } else {
        return !this.tile.countAttribute;
      }
    },

    maxLimit() {
      switch (this.visualizationType) {
        case 'CHART':
          return this.maxLimitForChart;
        case 'TABLE':
          return 100;
        case 'CSV':
          return 3000;
        default:
          return 1;
      }
    },

    maxLimitForChart() {
      switch (this.tile.type) {
        case 'LINE_CHART':
          return 7;
        case 'DONUT_CHART':
          return 15;
        case 'BAR_CHART':
        case 'AGGREGATION_TABLE':
        default:
          if (this.tile.includeMinMax) {
            return 15;
          }
          return 50;
      }
    },

    isLimitValid() {
      return (
        this.tile.limit &&
        this.tile.limit !== '' &&
        isOnlyDigits(this.tile.limit) &&
        Number(this.tile.limit) >= 1 &&
        Number(this.tile.limit) <= this.maxLimit
      );
    },

    isSortFieldValid() {
      if (!this.tile.sortField) {
        return true;
      }

      return [
        {
          columnName: 'header.assetId',
          dataField: 'name',
        },
        {
          columnName: 'header.date',
          dataField: 'time',
        },
        ...this.tablePreviewAttributes.filter((attribute) => attribute.columnName !== 'header.time'),
      ].some((attribute) => {
        return attribute.dataField === this.tile.sortField.dataField;
      });
    },
    limitTitle() {
      const mouseoverText = this.$t('alarmsReports.vue.dynamicReportDesignMaxLimit', { number: this.maxLimit });
      return this.tile.limit > this.maxLimit ? mouseoverText : '';
    },
    isTitleValid() {
      return XRegExp.test(this.tile.title, UNICODE_ALPHANUMERIC_EXTENDED_XRegExp);
    },
  },

  watch: {
    tablePreviewAttributes() {
      this.tile.tableAttributes = this.tablePreviewAttributes;
    },
  },

  mounted() {
    this.setVisualizationType();
    if (this.tile.type === 'TABLE' || this.tile.type === 'CSV') {
      const includesGeofenceArea = (attribute) => attribute.dataField.includes('geofenceArea');
      const geofenceAreas = (attribute) => !attribute.dataField.includes('geofenceArea');
      const includesGeofenceType = (attribute) => attribute.dataField.includes('geofenceType');
      const geofenceTypes = (attribute) => !attribute.dataField.includes('geofenceType');

      let tableAttributes = deepClone(this.tile.tableAttributes);

      if (tableAttributes.some(includesGeofenceArea)) {
        tableAttributes = [
          ...tableAttributes.filter(geofenceAreas),
          {
            columnName: 'geofenceArea.base',
            dataField: 'geofenceArea',
          },
        ];
      }

      if (tableAttributes.some(includesGeofenceType)) {
        tableAttributes = [
          ...tableAttributes.filter(geofenceTypes),
          {
            columnName: 'geofenceType.base',
            dataField: 'geofenceType',
          },
        ];
      }

      this.chosenAttributes = deepClone(tableAttributes);
    } else {
      this.chosenAttributes = this.attributes //
        .flatMap((attributeGroup) => attributeGroup.columns) //
        .filter((column) => {
          return this.tile && this.tile.xAttribute && column.dataField === this.tile.xAttribute.dataField;
        });
    }

    if (this.tile && this.tile.title !== '') {
      this.calculateDefaultValuesForTile();
    }

    if (this.tile.type === 'BAR_CHART' || this.tile.type === 'AGGREGATION_TABLE') {
      this.tile.sortField = this.getCurrentSortFieldOrDefault();
      this.tile.sortDesc = this.getCurrentSortDescOrDefault();
    }
  },

  methods: {
    translateType(type) {
      return this.$t(`alarmsReports.vue.${type}`);
    },

    translateCountMethod(countMethod) {
      return this.$t(`alarmsReports.vue.countMethods.${countMethod}`);
    },

    onCountMethodChange() {
      switch (this.tile.countMethod) {
        case 'SUM':
        case 'COUNT_UNIQUE':
        case 'VISITS':
          this.tile.includeMinMax = false;
          break;
        default:
          break;
      }

      if (this.tile.countMethod === 'COUNT_UNIQUE') {
        this.tile.countAttribute = this.countAttributes[0].columns[0];
      } else {
        this.tile.countAttribute = null;
      }
    },

    onTypeChange() {
      this.tile.limit = defaultChartTile.limit;
      this.tile.rotate = false;

      switch (this.tile.type) {
        case 'DONUT_CHART':
          this.tile.sortField = defaultChartTile.sortField;
          this.tile.sortDesc = defaultChartTile.sortDesc;
          this.tile.includeMinMax = false;
          break;

        case 'LINE_CHART':
          this.tile.sortField = defaultChartTile.sortField;
          this.tile.sortDesc = defaultChartTile.sortDesc;
          this.tile.includeTable = false;
          this.tile.includeMinMax = false;
          this.tile.limit = 3;
          break;

        case 'AGGREGATION_TABLE':
          this.tile.includeTable = false;
          this.tile.sortField = this.getCurrentSortFieldOrDefault();
          this.tile.sortDesc = this.getCurrentSortDescOrDefault();
          break;

        case 'BAR_CHART':
          this.tile.sortField = this.getCurrentSortFieldOrDefault();
          this.tile.sortDesc = this.getCurrentSortDescOrDefault();
          break;
      }
    },

    getCurrentSortFieldOrDefault() {
      if (!this.tile.sortField) {
        return { ...numerically };
      }

      return this.tile.sortField;
    },

    getCurrentSortDescOrDefault() {
      if (this.tile.sortDesc === null || this.tile.sortDesc === undefined) {
        return defaultChartTile.sortDesc;
      }

      return this.tile.sortDesc;
    },

    setVisualizationType() {
      switch (this.tile.type) {
        case 'BAR_CHART':
        case 'DONUT_CHART':
        case 'LINE_CHART':
        case 'AGGREGATION_TABLE':
          this.visualizationType = 'CHART';
          break;

        case 'TABLE':
          this.visualizationType = 'TABLE';
          break;

        case 'CSV':
          this.visualizationType = 'CSV';
          break;
      }
    },

    onVisualizationTypeChange() {
      // Vue do not like it when this.tile is overridden so instead
      // override each attribute..
      const defaultChartTileKeys = Object.keys(defaultChartTile);
      const previousChartTile = deepClone(this.tile);

      defaultChartTileKeys.forEach((key) => {
        this.tile[key] = defaultChartTile[key];
      });

      this.tile.title = previousChartTile.title;

      this.chosenAttributes = [];
      this.errors = {};

      if (this.visualizationType === 'TABLE' || this.visualizationType === 'CSV') {
        this.tile.type = deepClone(this.visualizationType);
        this.tile.sortField = {
          columnName: 'header.date',
          dataField: 'time',
        };
      } else {
        this.tile.sortField = { ...numerically };
      }

      this.tile.sortDesc = true;
      this.isOpen = true;
    },

    onAttributeChange() {
      if (!this.chosenAttributes) {
        this.tile.xAttribute = '';
        return;
      }

      if (!Array.isArray(this.chosenAttributes)) {
        this.chosenAttributes = [this.chosenAttributes];
      }

      if (this.chosenAttributes.length === 1) {
        const types = this.chosenAttributes[0].supportedChartTypes.filter((chartType) => chartType !== 'TABLE');
        this.tile.xAttribute = this.chosenAttributes[0];

        if (!types.includes(this.tile.type)) {
          this.tile.type = types[0];
          this.onTypeChange();
        }

        return;
      }

      this.tile.type = 'TABLE';
    },

    showErrors() {
      const errors = {};

      errors.type = !this.tile.type;
      errors.chosenAttributes = this.chosenAttributes ? this.chosenAttributes.length < 1 : true;
      errors.countAttribute = !this.isCountAttributeValid;
      errors.limit = !this.isLimitValid;
      errors.title = !this.tile.title || !this.isTitleValid;
      errors.invalidTitle = !this.isTitleValid;

      if (this.isTable || this.isCsv) {
        errors.sortField = !this.isSortFieldValid;
      } else {
        errors.countMethod = !this.tile.countMethod;
      }

      this.errors = errors;
    },
    setDefaultSortBy() {
      this.tile.sortField = { columnName: 'header.date', dataField: 'time', };
    },
    onRemoveAttributeTag(attribute) {
      if (this.tile.sortField.dataField.includes(attribute.dataField)) {
        this.setDefaultSortBy();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.is-tile-create {
  .panel-heading {
    height: 39px; // same width as other panel headings
    padding-top: 5px;
  }
}

.radio-inline-label {
  padding-left: 0;
}

.list-item .panel {
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 15px;
  }
}

.form-group div[class^='col-'] {
  padding-right: 0;

  &:first-child {
    padding-left: 0;
  }
}

.table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.glyphicons.glyphicons-bin {
  margin-top: -20px;
}

.chart-icon {
  width: 30px;
  height: 30px;
  background: white;
}

.has-margin-right {
  margin-right: $margin;
}

.inline-checkbox {
  margin-top: 5px;
  margin-bottom: 0;

  div,
  input {
    float: left;
  }

  input {
    margin-left: 5px;
  }
}

.panel-heading {
  position: relative;

  span {
    line-height: 30px;
  }

  .panel-heading-icon {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

.tile-title {
  height: 28px;
}

.error-text {
  color: #f04124;
}
</style>
