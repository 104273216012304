import axios from 'axios';

const http = axios.create({ withCredentials: true });

const allowedErrorMessages = ['Event not found', 'EventTemplate not found'];

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (allowedErrorMessages.includes(error.response.data.message)) {
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 401:
        window.location.replace('/login.html');
        return;
      case 404:
        window.location.replace('/error_pages/404.html');
        return;
      case 500:
        redirectTo500ErrorPage(error);
        return;
      case 502:
      case 503:
        window.location.replace(`/error_pages/503.html?url=${encodeURIComponent(window.location.toString())}`);
        return;
      default:
        return Promise.reject(error);
    }
  }
);

function redirectTo500ErrorPage(error) {
  let message = error.response.data.message;
  const errorId = message.substring(message.indexOf('ID ') + 3, message.indexOf(').'));

  window.location.replace(`/error_pages/500.html?id=${errorId}`);
}

export default http;
