<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h4 class="panel-title">
        {{ $t('assetClassification.vue.assetClassificationAdministration') }}
      </h4>
    </div>
    <div class="panel-body">
      <div v-if="loading" class="classification-zone">
        <div class="loader">{{ $t('generic.loading') }}</div>
      </div>
      <div v-else>
        <div class="col-md-12 has-no-padding-left has-no-padding-right">
          <label>
            {{ $t('assetInformation.vue.assetClassification1') }}
          </label>
          <multiselect
            v-model="selectedAssetClassification.assetClassification1"
            :options="assetClassification.classification1"
            :class="{ 'has-error': err.assetClassification1 }"
            label="classification"
            track-by="classification"
            :allow-empty="false"
            select-label=""
            selected-label=""
            deselect-label=""
            placeholder=""
            open-direction="bottom"
            style="flex: 2 0 0"
            data-cy="createClassificationSelectClassification1"
            @input="onAssetClassification1Input"
          >
          </multiselect>

          <label>
            {{ $t('assetInformation.vue.assetClassification2') }}
          </label>
          <multiselect
            v-model="selectedAssetClassification.assetClassification2"
            :class="{ 'has-error': err.assetClassification2 }"
            :options="localAssetClassification2"
            :disabled="localAssetClassification2.length === 0"
            label="classification"
            track-by="classification"
            :allow-empty="false"
            select-label=""
            selected-label=""
            deselect-label=""
            placeholder=""
            open-direction="bottom"
            style="flex: 2 0 0"
            data-cy="createClassificationSelectClassification2"
            @input="onAssetClassification2Input"
          >
          </multiselect>

          <label>
            {{ $t('assetInformation.vue.assetClassification3') }}
          </label>
          <div class="input-button-same-row">
            <multiselect
              v-model="selectedAssetClassification.assetClassification3"
              :class="{ 'has-error': err.assetClassification3 }"
              :options="localAssetClassification3"
              :disabled="localAssetClassification3.length === 0"
              label="classification"
              track-by="classification"
              :allow-empty="false"
              select-label=""
              selected-label=""
              deselect-label=""
              placeholder=""
              open-direction="bottom"
              style="flex: 2 0 0;"
              data-cy="createClassificationSelectClassification3"
              @input="onAssetClassification3Input"
            >
            </multiselect>

            <v-popover offset="16" placement="right">
              <button
                :disabled="!selectedAssetClassification.assetClassification3"
                class="tooltip-target btn pull-right btn-danger"
                data-cy="createClassificationRemoveButton"
              >
                <i class="glyphicons glyphicons-minus" :title="$t('assetClassification.vue.removeClassification')"></i>
              </button>
              <template slot="popover">
                <h3 class="popover-title break-word">
                  {{ deleteTitle }}
                </h3>
                <div class="popover-content">
                  <button v-close-popover="true" class="btn btn-fm-std inline-block right-buffer">
                    {{ $t('value.false') }}
                  </button>
                  <button
                    class="btn btn-fm-std inline-block"
                    data-cy="popoverSubmitButton"
                    @click="removeClassification()"
                  >
                    {{ $t('value.true') }}
                  </button>
                </div>
              </template>
            </v-popover>
          </div>
        </div>

        <form @submit.prevent="addNewAssetClassification">
          <label for="newClassification">{{ $t('assetClassification.vue.newClassification3') }}</label>
          <div class="input-button-same-row">
            <input
              id="newClassification"
              v-model="newAssetClassification"
              autocomplete="off"
              type="text"
              maxlength="45"
              :placeholder="$t('generic.maxLength45')"
              :class="{ 'has-error': err.newAssetClassification, 'form-control': true }"
              data-cy="createClassificationInput"
              @focusin="onNewAssetClassificationInputFocus"
            />
            <button
              class="btn pull-right btn-success"
              :title="$t('assetClassification.vue.createClassification')"
              :delay="1000"
              data-cy="createClassificationSubmitButton"
            >
              <i class="glyphicons glyphicons-plus"></i>
            </button>
          </div>
          <div v-if="err.newAssetClassification && newAssetClassification" class="error-message">
            {{ $t('assetClassification.vue.duplicateClassification') }}
          </div>
        </form>
      </div>
      <div @click="closeModal">
        <AssetInformationClassificationDeleteModal
          v-if="showDeleteModal"
          :assets-used="deleteClassificationList"
          :style="{ display: showDeleteModal ? 'block' : 'none' }"
          @closeModal="closeModal"
        ></AssetInformationClassificationDeleteModal>
      </div>
    </div>
  </div>
</template>

<script>
import AssetInformationService from '@/services/AssetInformationService';
import { CLASSIFICATION_LEVEL } from '@/utils/Constants';
import Multiselect from 'vue-multiselect';
import { VPopover } from 'v-tooltip';
import AssetInformationClassificationDeleteModal from '@/asset-classification/AssetInformationClassificationDeleteModal';

export default {
  name: 'CreateAssetInformationClassification',
  components: {
    AssetInformationClassificationDeleteModal,
    Multiselect,
    VPopover,
  },
  props: {
    assetClassification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingSaveClassification: false,
      localAssetClassification1: [],
      localAssetClassification2: [],
      localAssetClassification3: [],
      selectedAssetClassification: {
        assetClassification1: null,
        assetClassification2: null,
        assetClassification3: null,
      },
      newAssetClassification: '',
      deleteClassificationList: [],
      showDeleteModal: false,
      loading: false,
      err: {
        assetClassification2: false,
        assetClassification3: false,
        newAssetClassification: false,
      },
    };
  },
  computed: {
    deleteTitle() {
      if (this.selectedAssetClassification.assetClassification3) {
        return this.$t('generic.delete') + ': ' + this.selectedAssetClassification.assetClassification3.classification;
      }
      return this.$t('generic.delete');
    },
    isNewClassificationDuplicate() {
      return this.localAssetClassification3.find(
        (classificationObj) =>
          classificationObj.classification.toLowerCase() === this.newAssetClassification.toLowerCase()
      );
    },
  },
  methods: {
    onAssetClassification1Input() {
      this.err.newAssetClassification = false;
      this.err.assetClassification1 = false;
      this.localAssetClassification2 = [];
      this.localAssetClassification3 = [];
      this.selectedAssetClassification.assetClassification2 = null;
      this.selectedAssetClassification.assetClassification3 = null;
      this.localAssetClassification2 = this.assetClassification.classification2.filter(
        (classification) => classification.parentId === this.selectedAssetClassification.assetClassification1.id
      );
    },
    onAssetClassification2Input() {
      this.err.newAssetClassification = false;
      this.err.assetClassification1 = false;
      this.err.assetClassification2 = false;
      this.localAssetClassification3 = [];
      this.selectedAssetClassification.assetClassification3 = null;
      this.localAssetClassification3 = this.assetClassification.classification3.filter(
        (classification) => classification.parentId === this.selectedAssetClassification.assetClassification2.id
      );
      this.localAssetClassification3.sort((a, b) =>
        a.classification.toLowerCase().localeCompare(b.classification.toLowerCase())
      );
    },
    onAssetClassification3Input() {
      this.err.assetClassification3 = false;
    },
    async addNewAssetClassification() {
      if (!this.selectedAssetClassification.assetClassification2) {
        this.err.assetClassification1 = true;
        this.err.assetClassification2 = true;
        return;
      }
      if (!this.newAssetClassification || this.isNewClassificationDuplicate) {
        this.err.newAssetClassification = true;
        return;
      }
      this.loading = true;
      let assetClassification = {
        classification: this.newAssetClassification.trim(),
        parentId: this.selectedAssetClassification.assetClassification2.id,
        classificationLevel: CLASSIFICATION_LEVEL.LEVEL_3,
      };
      let newAssetClassificationId = await AssetInformationService.saveAssetInformationClassification(
        assetClassification
      ).then((res) => res.data);
      assetClassification.id = newAssetClassificationId;
      this.$emit('addClassification3', assetClassification);
      this.resetAllValues();
      setTimeout(() => (this.loading = false), 1000);
    },
    async removeClassification() {
      if (!this.selectedAssetClassification.assetClassification3) {
        this.err.assetClassification3 = true;
        return;
      }
      try {
        this.loading = true;
        await AssetInformationService.deleteAssetInformationClassification(
          this.selectedAssetClassification.assetClassification3.id
        );
        this.$emit('removeClassification3', this.selectedAssetClassification.assetClassification3);
      } catch (e) {
        this.showDeleteModal = true;
        this.deleteClassificationList = e.response.data;
      }
      this.resetAllValues();
      setTimeout(() => (this.loading = false), 1000);
    },
    onNewAssetClassificationInputFocus() {
      this.err.newAssetClassification = false;
    },
    resetAllValues() {
      this.newAssetClassification = '';
      this.localAssetClassification2 = [];
      this.localAssetClassification3 = [];
      this.selectedAssetClassification.assetClassification1 = null;
      this.selectedAssetClassification.assetClassification2 = null;
      this.selectedAssetClassification.assetClassification3 = null;
    },
    closeModal() {
      this.showDeleteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.classification-zone {
  height: 224px;
  position: relative;
}
.btn {
  padding: 3px 5px;
}
.input-button-same-row {
  display: flex;
  justify-content: space-between;
  margin: -10px;
  & > * {
    margin: 10px;
  }
  input {
    color: black;
  }
}

@import '../styles/variables';

.has-error {
  border: none !important;
  box-shadow: inset 0 0 0 3px $danger;
  opacity: 1;
}
.error-message {
  margin-top: 10px;
  color: $danger;
}

/*
  Overridden css to make the vue-multiselect look like angular-ui-select
*/
.multiselect {
  color: black !important;
  background: white;
  &.multiselect--disabled {
    opacity: 1 !important;
    pointer-events: none;
  }
  &.has-error {
    .multiselect__tags {
      border: none !important;
      box-shadow: inset 0 0 0 3px $danger;

      .multiselect__single {
        background: white;
        margin-top: 3px !important;
        margin-left: 3px !important;
        line-height: 16px;
      }
    }
  }
}
</style>
