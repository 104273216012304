<template>
  <ul v-if="pages.length >= 2" class="pagination">
    <li>
      <a
        class="previous"
        :class="{ disabled: currentPage === 1 }"
        :title="$t('alarmsReports.vue.previous')"
        @click="setPage(currentPage - 1)"
        >&laquo;</a
      >
    </li>
    <li v-for="(page, index) in pages" :key="index" :class="{ active: page === currentPage || page === '...' }">
      <a :title="page" @click="setPage(page)">{{ page }}</a>
    </li>
    <li>
      <a
        class="next"
        :class="{ disabled: currentPage === maxPage }"
        :title="$t('alarmsReports.vue.next')"
        @click="setPage(currentPage + 1)"
        >&raquo;</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'YfPaginator',

  props: {
    items: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 16,
    },
  },

  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    pages: function() {
      let paginationButtons = 9;
      let asideButtons = Math.floor((paginationButtons - 1) / 2);
      let start = Math.max(1, this.currentPage - asideButtons);
      let end = Math.min(this.maxPage, start + asideButtons * 2); //inclusive
      start = Math.max(1, end - asideButtons * 2);
      let pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      pages[0] = 1;
      pages[pages.length - 1] = this.maxPage;

      if (pages[1] && pages[1] !== 2) {
        pages[1] = '...';
      }
      if (pages[pages.length - 2] && pages[pages.length - 2] !== this.maxPage - 1) {
        pages[pages.length - 2] = '...';
      }
      return pages;
    },
    maxPage: function() {
      return Math.ceil(this.items.length / this.pageSize);
    },
  },
  watch: {
    items() {
      //Dont refactor this code to join the code below, if the new items is empty the if statement in setPage stops the emit
      this.currentPage = 1;
      this.$emit('paginated-items', this.getPaginatedItems());
    },
  },
  created() {
    this.setPage(this.currentPage);
  },
  methods: {
    setPage(page) {
      if (page === '...' || page < 1 || page > this.maxPage) {
        return;
      }
      this.currentPage = page;
      this.$emit('paginated-items', this.getPaginatedItems());
    },
    getPaginatedItems: function() {
      let items = this.items.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
      return items;
    },
  },
};
</script>
