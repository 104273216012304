<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else class="row report">
      <div v-if="report" class="col-md-10 col-md-offset-1">
        <div id="ifCondition" ref="ifCondition" class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.iWouldLikeAReport') }}</h4>

            <event-validation-box :is-valid="isIfConditionsValid" :is-submitted="isSubmitted" />
          </div>

          <div v-if="!hideTimeConditions" class="panel-body">
            <div v-for="(ifCondition, index) in report.ifCondition" :key="index">
              <report-time-condition-block
                :id="`reportTimeConditionBlock${index}`"
                :time-condition="ifCondition"
                :is-submitted="isSubmitted"
                :index="index"
                :text-type="report.ifCondition.length > 1 ? 'DELETE' : 'RESET'"
                @delete-block="deleteTimeCondition(index)"
                @validation-changed="
                  (isConditionBlockValid) => $set(ifConditionsValidation, index, isConditionBlockValid)
                "
              ></report-time-condition-block>
            </div>
          </div>
        </div>

        <div
          v-if="!hideTimeConditions && report.andConditions && report.andConditions.length > 0"
          class="panel panel-primary"
        >
          <div ref="reportAndConditionBlock" class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.withTheFollowingConditions') }}</h4>

            <event-validation-box :is-valid="isAndConditionsValid" :is-submitted="isSubmitted" />

            <button
              type="button"
              class="close action-close"
              :style="{ 'margin-right': isSubmitted ? '25px' : '0' }"
              :title="$t('generic.delete')"
              @click="deleteOrCondition(0)"
            >
              <i class="glyphicons glyphicons-remove-2 before-black"></i>
            </button>
          </div>

          <div
            v-for="(andCondition, index) in report.andConditions"
            :id="`reportAndConditionBlock${index}`"
            :key="index"
            class="panel-body"
          >
            <div
              v-for="(condition, childIndex) in andCondition"
              :id="`reportAndConditionBlock${index}${childIndex}`"
              :key="childIndex"
            >
              <report-and-condition-block
                :condition="condition"
                :columns="columns"
                :trigger-type="report.triggerType"
                :dimension="false"
                :is-submitted="isSubmitted"
                :index="childIndex"
                :text-type="andCondition.length > 1 ? 'DELETE' : 'RESET'"
                @delete-block="deleteCondition(index, childIndex)"
                @validation-changed="
                  (isConditionBlockValid) => $set(andConditionsValidation[index], childIndex, isConditionBlockValid)
                "
              ></report-and-condition-block>

              <hr />
            </div>

            <button
              class="btn btn-fm-std btn-sm btn-sm-with-text"
              :title="$t('alarmsReports.vue.addACondition')"
              @click="addAndConditionBlockTo(index)"
            >
              <i class="glyphicons glyphicons-plus"></i>
              {{ $t('alarmsReports.vue.addACondition') }}
            </button>
          </div>
        </div>

        <div
          v-if="report.andConditions && report.andConditions.length === 0"
          class="text-center add-or-condition-button"
        >
          <button
            type="button"
            class="btn btn-fm-std"
            :title="$t('alarmsReports.vue.addCondition')"
            @click="addOrConditionBlock"
          >
            <i class="glyphicons glyphicons-plus"></i>
            {{ $t('alarmsReports.vue.addCondition') }}
          </button>
        </div>

        <div ref="reportActionBlock" class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.thenSendTheReportTo') }}</h4>

            <event-validation-box :is-valid="isActionsValid" :is-submitted="isSubmitted" />
          </div>

          <div class="panel-body">
            <report-action-block
              v-for="(action, actionIndex) in report.actions"
              :id="`reportActionBlock${actionIndex}`"
              :key="actionIndex"
              :action="action"
              :is-submitted="isSubmitted"
              @delete-block="deleteAction(actionIndex)"
              @validation-changed="(isActionBlockValid) => $set(actionsValidation, actionIndex, isActionBlockValid)"
            />
          </div>
        </div>

        <div id="visualization" class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.withDesign') }}</h4>

            <event-validation-box :is-valid="isVisualizationValid" :is-submitted="isSubmitted" />
          </div>

          <div ref="withDesign" class="panel-body">
            <div class="form-line has-no-margin-bottom">
              <multiselect
                v-model="report.reportDesign"
                :disabled="isReportTilesLoading"
                :options="reportTemplates"
                :class="{ 'has-error': !isVisualizationValid && isSubmitted }"
                label="name"
                track-by="name"
                :allow-empty="false"
                select-label
                deselect-label
                open-direction="bottom"
                placeholder
                style="width: 400px"
                :style="{
                  'pointer-events': isReportTilesLoading ? 'none' : '',
                  opacity: isReportTilesLoading ? '0.5' : 1,
                }"
                data-cy="reportWithDesign"
                @input="getReportTemplateTilesForReport"
              ></multiselect>

              <button
                v-if="report.reportDesign && report.reportDesign.oneTime"
                style="padding: 4px 5px;"
                class="btn btn-fm-std btn-sm align-sm-input has-no-margin-bottom report-design-edit-button"
                :title="$t('alarmsReports.vue.editOneTimeDesign', { designName: report.reportDesign.name })"
                @click="showReportTemplateCreateModal(report.reportDesign, true)"
              >
                <i class="glyphicons glyphicons-pencil"></i>
              </button>

              <report-template-popover
                :report-templates="reportTemplates"
                :one-time="true"
                :disable-click="isReportTilesLoading"
                @on-submit="showReportTemplateCreateModal"
              >
                <button
                  type="reset"
                  class="btn btn-fm-std btn-sm align-sm-input has-no-margin-bottom report-design-create-button"
                  :title="$t('generic.new')"
                >
                  <i class="glyphicons glyphicons-plus" :title="$t('generic.new')"></i>
                </button>
              </report-template-popover>
            </div>
          </div>
        </div>

        <report-template-preview
          class="has-panel-margin-bottom not-wide"
          :report-name="report.name"
          :report-tiles="reportTemplateTiles"
          :is-loading="isReportTilesLoading"
          :mock-data="true"
        ></report-template-preview>

        <div class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('alarmsReports.vue.generalReportInformation') }}</h4>

            <event-validation-box :is-valid="generalInformationValidation" :is-submitted="isSubmitted" />
          </div>

          <div id="generalInformation" ref="generalInformation" class="panel-body event-general-info-panel-body">
            <event-general-info
              :event="report"
              :original-event="originalReport"
              :is-template="isCreateReportFromScenarioView"
              :is-submitted="isSubmitted"
              @validation-changed="setGeneralInformationValidation"
            ></event-general-info>
          </div>
        </div>

        <div class="button-group text-right">
          <yf-ladda
            id="saveReport"
            button-class="btn col-md-1 pull-right btn-success"
            :loading="loading"
            :title="$t('alarmsReports.vue.saveReport')"
            :delay="1000"
            data-cy="saveReport"
            @click="saveReport"
          >
            <i class="glyphicons glyphicons-ok-2"></i>
          </yf-ladda>
          <a
            id="backToMainMenu"
            href="#/vue/reports"
            class="btn pull-right col-md-1 btn-danger pull-left"
            :title="$t('alarmsReports.vue.backToMainMenu')"
          >
            <i class="glyphicons glyphicons-remove-2" :title="$t('alarmsReports.vue.backToMainMenu')"></i>
          </a>
        </div>
      </div>
    </div>

    <report-template-create-modal
      v-if="reportTemplateCreateModal"
      :style="{ display: reportTemplateCreateModal ? 'block' : 'none' }"
      :existing-report-template="reportTemplate"
      :report="report"
      :one-time="true"
      page="REPORT"
      @submit="modalSubmitted"
      @close="closeModal"
    ></report-template-create-modal>
  </div>
</template>
<script>
import EventValidationBox from '../event/EventValidationBox';
import EventGeneralInfo from '../event/EventGeneralInfo';
import EventMixin from '../mixins/EventMixin';
import EventService from '../services/EventService';
import ReportActionBlock from './ReportActionBlock';
import ReportAndConditionBlock from './ReportAndConditionBlock';
import ReportTemplateCreateModal from '../report-template/ReportTemplateCreateModal';
import ReportTemplatePopover from '../report-template/ReportTemplatePopover.vue';
import ReportTemplatePreview from '../report-template/ReportTemplatePreview';
import ReportTimeConditionBlock from './ReportTimeConditionBlock';
import {
  defaultReport,
  emptyCondition,
  emptyReportTimeCondition,
  eventTypes,
  getDefaultEndDate,
  getDefaultStartDate,
} from '../utils/Constants';
import { deepClone, NaturalSortService } from '../utils/Utils';
import Multiselect from 'vue-multiselect';
import YfLadda from '../yf-components/YfLadda';
import ChartPlaceholderDataService from '../services/ChartPlaceholderDataService';

export default {
  name: 'Report',

  components: {
    EventValidationBox,
    ReportAndConditionBlock,
    EventGeneralInfo,
    ReportTimeConditionBlock,
    ReportActionBlock,
    ReportTemplateCreateModal,
    ReportTemplatePreview,
    ReportTemplatePopover,
    Multiselect,
    YfLadda,
  },

  mixins: [EventMixin],

  data() {
    return {
      isLoading: false,
      isReportTilesLoading: false,
      currentPage: 0,
      report: {},
      originalReport: {},
      reportTemplate: {},
      reportTemplates: [],
      reportTemplateTiles: [],
      reportTemplateCreateModal: false,
      loading: false,
    };
  },

  computed: {
    isUpdateReportView() {
      return this.$route && this.$route.params && this.$route.params.reportId + '' !== '0';
    },
    isCreateReportFromScenarioView() {
      return (
        this.$route &&
        this.$route.params &&
        this.$route.params.reportId + '' === '0' &&
        this.$route.params.templateId &&
        this.$route.params.templateId + '' !== '0'
      );
    },
    isVisualizationValid() {
      return !!this.report.reportDesign;
    },
    isValid() {
      return (
        this.generalInformationValidation &&
        this.isIfConditionsValid &&
        this.isAndConditionsValid &&
        this.isActionsValid &&
        this.isVisualizationValid
      );
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.getReport();
    await this.getReportDesigns();
    await this.getColumnDefinitions();
    this.isLoading = false;
  },

  methods: {
    getTiles() {
      return this.report.reportDesign.data.map((tile) => ({
        ...tile,
        data: { ...ChartPlaceholderDataService.defaultValues.bind(this)(tile) },
      }));
    },
    showReportTemplateCreateModal(reportTemplate, edit) {
      this.reportTemplate = reportTemplate;
      this.reportTemplate.edit = edit;
      this.reportTemplateCreateModal = true;
    },
    async getReport() {
      try {
        if (this.isUpdateReportView) {
          this.report = await EventService.getReport(this.$route.params.reportId);
          this.report.startDate = new Date(this.report.startDate);
          this.report.endDate = new Date(this.report.endDate);
          this.constructValidationArrays(this.report);
          this.getReportTemplateTilesForReport();
          this.isSubmitted = true;
        } else {
          if (this.isCreateReportFromScenarioView) {
            const chosenReportScenario = await EventService.getEventTemplate(
              eventTypes.REPORT,
              this.$route.params.templateId
            );

            const chosenReportScenarioWithCorrectDates = {
              ...chosenReportScenario.data,
              reportDesign: deepClone(chosenReportScenario.reportDesign),
              startDate: getDefaultStartDate(),
              endDate: getDefaultEndDate(),
            };

            this.report = deepClone(chosenReportScenarioWithCorrectDates);
            this.constructValidationArrays(this.report);
            this.getReportTemplateTilesForReport();
            this.isSubmitted = true;
          } else {
            this.report = deepClone(defaultReport);
            this.constructInitialValidationArrays(this.report);
          }

          this.report.startDate = new Date(this.report.startDate);
          this.report.endDate = new Date(this.report.endDate);
          const timezone = await EventService.getMeTimezone();
          this.report.timezone = timezone.id;
        }

        this.originalReport = deepClone(this.report);
      } catch (err) {
        window.location.replace('/#/vue/reports/0');
      }
    },
    async getReportDesigns() {
      const reportTemplates = await EventService.getReportDesigns();

      this.reportTemplates = NaturalSortService.naturalSortArrayByKey(reportTemplates, 'name');
    },
    async getReportTemplateTilesForReport() {
      this.isReportTilesLoading = true;

      if (typeof this.report.reportDesign.data === 'string') {
        this.report.reportDesign.data = JSON.parse(this.report.reportDesign.data);
      }

      this.reportTemplateTiles = this.getTiles();

      this.$nextTick(() => {
        this.isReportTilesLoading = false;
      });
    },
    addTimeConditionBlock() {
      this.report.ifCondition.push(deepClone(emptyReportTimeCondition));
      this.ifConditionsValidation.push(true);
    },
    deleteTimeCondition(index) {
      this.$delete(this.report.ifCondition, index);
      this.$delete(this.ifConditionsValidation, index);

      if (this.report.ifCondition.length === 0) {
        this.addTimeConditionBlock();
      }

      // For some reason the timeconditions are not rerendered when removing a time block.
      this.hideTimeConditions = true;
      this.$nextTick(() => {
        this.hideTimeConditions = false;
      });
    },
    addOrConditionBlock() {
      if (!this.report.andConditions) {
        this.report.andConditions = [];
      }

      this.report.andConditions.push([{ ...emptyCondition }]);
      this.andConditionsValidation.push([null]);
    },
    deleteOrCondition(index) {
      this.$delete(this.report.andConditions, index);
      this.$delete(this.andConditionsValidation, index);

      // For some reason the timeconditions are not rerendered when removing a time block.
      this.hideTimeConditions = true;
      this.$nextTick(() => {
        this.hideTimeConditions = false;
      });
    },
    addAndConditionBlockTo(andConditionIndex) {
      this.report.andConditions[andConditionIndex].push({ ...emptyCondition });
      this.andConditionsValidation[andConditionIndex].push(null);
    },
    deleteCondition(index, childIndex) {
      this.$delete(this.report.andConditions[index], childIndex);
      this.$delete(this.andConditionsValidation[index], childIndex);

      if (this.report.andConditions[index].length === 0) {
        this.$delete(this.report.andConditions, index);
        this.$delete(this.andConditionsValidation, index);
      }

      if (this.report.andConditions.length === 0) {
        this.addOrConditionBlock();
      }

      this.hideTimeConditions = true;
      this.$nextTick(() => {
        this.hideTimeConditions = false;
      });
    },
    async modalSubmitted(reportDesign) {
      this.isReportTilesLoading = true;
      this.report.reportDesign = reportDesign;
      this.getReportTemplateTilesForReport();
      this.closeModal();

      this.$nextTick(() => {
        this.isReportTilesLoading = false;
      });
    },
    closeModal() {
      this.reportTemplateCreateModal = false;
    },
    deleteAction(actionIndex) {
      this.$delete(this.report.actions, actionIndex);
      this.$delete(this.actionsValidation, actionIndex);
    },
    isLastAndCondition(index) {
      return this.report.andConditions.length - 1 === index;
    },
    async saveReport() {
      this.isSubmitted = true;
      this.loading = true;

      if (this.isValid) {
        delete this.report.reportDesign.edit;
        let report = deepClone(this.report);
        report.reportDesign.data = JSON.stringify(report.reportDesign.data);
        report.name = report.name.trim();
        if (this.isUpdateReportView) {
          await EventService.updateEvent(report);
        } else {
          await EventService.createEvent(report);
        }
        this.loading = false;
        window.location.replace('/#/vue/reports');
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.scrollToTheElement();
      }
    },
    scrollToTheElement() {
      let elementToScrollTo;
      if (!this.isIfConditionsValid) {
        elementToScrollTo = 'ifCondition';
      } else if (!this.isAndConditionsValid) {
        elementToScrollTo = 'reportAndConditionBlock';
      } else if (!this.isActionsValid) {
        elementToScrollTo = 'reportActionBlock';
      } else if (!this.isVisualizationValid) {
        elementToScrollTo = 'withDesign';
      } else if (!this.generalInformationValidation) {
        elementToScrollTo = 'generalInformation';
      } else {
        return;
      }

      this.$refs[elementToScrollTo].scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.has-panel-margin-bottom {
  margin-bottom: 21px;
}

.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.report-design-edit-button,
.report-design-create-button {
  padding: 4px 5px;
}

// to align buttons with eachother
.report-design-create-button {
  margin-top: -1px;
}
</style>
