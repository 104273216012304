<template>
  <form class="form-horizontal">
    <div class="form-line nowrap">
      {{ $t('alarmsReports.vue.where') }} <event-space></event-space>

      <multiselect
        v-model="condition.column"
        :class="{ 'has-error': errors.column && isSubmitted }"
        :options="columns"
        :custom-label="({ columnName }) => $t(columnName)"
        group-values="columns"
        group-label="groupedBy"
        label="columnName"
        track-by="dataField"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="flex: 2 0 0"
        data-cy="alarmConditionBlockGroupedBy"
        @input="resetOperator"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        ref="operatorRef"
        v-model="condition.operator"
        :options="columnConditions"
        :custom-label="({ label }) => (label ? $t('alarmsReports.vue.' + label) : '')"
        :allow-empty="false"
        :disabled="columnConditions.length === 0"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="flex: 2"
        data-cy="alarmConditionBlockCondition"
        @input="focusOnValue"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        v-if="!isAlarmTypeText && !isAlarmTypeSuggestion && !isAlarmTypeApi"
        :options="[]"
        :disabled="columnConditions.length === 0"
        placeholder=""
        class="value-multiselect"
      >
      </multiselect>

      <multiselect
        v-show="isAlarmTypeText"
        ref="valueRefText"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :clear-on-select="true"
        :close-on-select="true"
        :allow-empty="false"
        label="value"
        track-by="value"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        :placeholder="placeholder"
        class="value-multiselect"
        @search-change="addQueryAsOnlyResult"
      >
        <template slot="noOptions"></template>
      </multiselect>

      <multiselect
        v-show="isAlarmTypeSuggestion"
        ref="valueRefSuggestion"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :loading="isLoading"
        :clear-on-select="true"
        :close-on-select="true"
        :hide-selected="true"
        open-direction="bottom"
        :custom-label="(value) => $t(value.text)"
        track-by="text"
        :placeholder="placeholder"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        class="value-multiselect"
        @search-change="getSuggestionsForQuery"
      >
      </multiselect>

      <multiselect
        v-show="isAlarmTypeApi"
        ref="valueRefApi"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :loading="isLoading"
        :clear-on-select="true"
        :close-on-select="true"
        :hide-selected="true"
        open-direction="bottom"
        :custom-label="getAlarmTypeApiLabel"
        track-by="name"
        :placeholder="placeholder"
        select-label=""
        selected-label=""
        deselect-label=""
        class="value-multiselect"
        data-cy="alarmConditionBlockValues"
        :show-no-results="false"
        @search-change="searchChangedForApi"
      >
      </multiselect>
    </div>

    <div class="form-line flex-end has-no-margin-bottom">
      <small @click.prevent="deleteBlock()">
        <a href="#" :title="$t('alarmsReports.vue.resetCondition')">{{ $t('alarmsReports.vue.resetCondition') }}</a>
      </small>
    </div>
  </form>
</template>

<script>
import EventBlockMixin from '../mixins/EventBlockMixin';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import EventSpace from '../event/EventSpace';
import Multiselect from 'vue-multiselect';
import EventConditionValueValidationMixin from '../mixins/EventConditionValueValidationMixin';

export default {
  name: 'AlarmConditionBlock',

  components: {
    EventSpace,
    Multiselect,
  },

  mixins: [EventBlockMixin, EventBlockValidationMixin, EventConditionValueValidationMixin],

  computed: {
    isValid() {
      return (
        this.isAlarmTypeSet &&
        !!this.condition.operator.label &&
        this.validateValues(this.condition.values, this.condition.column.columnType)
      );
    },
  },

  methods: {
    showErrors() {
      const errors = {};

      errors.column = !this.condition.column.alarmType;
      errors.values = !this.validateValues(this.condition.values, this.condition.column.columnType);

      this.errors = errors;
    },
    async deleteBlock() {
      await this.$emit('delete-block');
      this.errors.column = !this.condition.column.alarmType;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/variables';

.container {
  display: flex;
  flex-direction: row;
}

.padding-top {
  padding: 15px 0 0 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.button-group {
  .button:not(:last-child) {
    margin-right: 10px;
  }
}

input.form-control {
  height: 30px;
}

.value-multiselect {
  flex: 4;
  max-width: 600px;
}

/*
    Overridden css to make the vue-multiselect look like angular-ui-select
    */
.multiselect {
  min-height: 30px !important;

  &.multiselect--active {
    font-size: 15px !important;
  }

  &.multiselect--disabled {
    color: #cccccc !important;
  }

  &.has-error {
    .multiselect__tags {
      border: none !important;
      box-shadow: inset 0 0 0 3px $danger;

      .multiselect__single {
        background: white;
        margin-top: 3px !important;
        margin-left: 3px !important;
        line-height: 16px;
      }
    }
  }

  &.has-warning {
    .multiselect__tags {
      border: none !important;
      box-shadow: inset 0 0 0 3px $warning;
    }

    .multiselect__single {
      background: transparent;
    }
  }
}

.multiselect__input {
  //width: calc(100% - 32px) !important;
  font-weight: normal !important;
  font-size: 15px !important;
  border-radius: 0 !important;
  color: #6f6f6f !important;
  margin-bottom: 0 !important;
  margin-top: 4px !important;
  background: transparent !important;
}

.multiselect__input::placeholder {
  font-weight: normal !important;
  padding: 0 0 0 5px !important;
  font-size: 15px !important;
  color: #cccccc !important;
}

.multiselect__tags {
  min-height: 30px !important;
  padding: 0 40px 0 0 !important;
  border-radius: 0 !important;
  border: 1px solid #ccc !important;
  overflow: hidden !important;
}

.multiselect__placeholder {
  font-size: 15px !important;
  padding: 0 0 0 5px !important;
  margin-bottom: 0 !important;
  color: #cccccc !important;
}

.multiselect__tag {
  margin: 5px 0 0 5px !important;
  padding: 2px 26px 2px 10px !important;
  overflow: initial !important;
}

.multiselect__tag-icon {
  line-height: 18px !important;
}

.multiselect__single {
  font-size: 15px !important;
  padding-top: 4px !important;
  margin: 0 !important;
  word-break: break-all;
}

.multiselect__option {
  min-height: 30px !important;
  padding: 7px !important;

  &:after {
    line-height: 30px !important;
  }
}

.multiselect__option--highlight {
  background: #428bca !important;

  &:after {
    background: #428bca !important;
  }
}

.multiselect__select {
  height: 28px !important;
  width: 28px !important;
  line-height: 20px !important;
}

.multiselect__spinner {
  width: 42px !important;
  height: 28px !important;
}
</style>
