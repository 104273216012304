<template>
  <div>
    <div
      v-if="isAlarmTriggerTypeObservation && condition.conditionRange && isModeForTheSameAsset"
      class="form-line nowrap"
    >
      <span v-show="condition.conditionRange && condition.conditionRange.type !== 'OBSERVATION'"
        >{{ $t('alarmsReports.vue.forAllObservationsDuringTheLatest') }} <event-space></event-space
      ></span>
      <span v-show="condition.conditionRange && condition.conditionRange.type === 'OBSERVATION'"
        >{{ $t('alarmsReports.vue.forTheLatest') }} <event-space></event-space
      ></span>

      <span :class="{ 'has-error': errors.range }">
        <input
          v-model="condition.conditionRange.range"
          class="form-control"
          min="1"
          type="number"
          style="width: 75px"
        />
      </span>
      <event-space></event-space>
      <multiselect
        v-model="condition.conditionRange.type"
        :options="conditionRangeTypes"
        :custom-label="(value) => $t('alarmsReports.vue.' + value)"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="width: 200px"
      >
      </multiselect>
    </div>
    <div v-if="condition.conditionRange && isModeForAssets" class="form-line nowrap">
      {{ $t('alarmsReports.vue.for') }}
      <event-space></event-space>
      <multiselect
        v-model="condition.conditionRange.operator"
        :options="rangeOperators"
        :multiple="false"
        :taggable="false"
        :clear-on-select="true"
        :close-on-select="true"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        :custom-label="(value) => $t('alarmsReports.vue.rangeOperator.' + value)"
        placeholder=""
        style="width: 200px"
        data-cy="rangePickerBlockRangeOperators"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <span :class="{ 'has-error': errors.range }">
        <input
          v-model="condition.conditionRange.range"
          class="form-control"
          :min="rangeMinimumValue"
          type="number"
          style="width: 75px"
          data-cy="rangePickerBlockRange"
        />
      </span>

      <event-space></event-space>
      {{ $t('alarmsReports.vue.assets') }}
    </div>
  </div>
</template>
<script>
import EventSpace from '../event/EventSpace';
import Multiselect from 'vue-multiselect';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';

export default {
  name: 'AlarmAndConditionRangePickerBlock',

  components: { EventSpace, Multiselect },

  mixins: [EventBlockValidationMixin],

  props: {
    condition: {
      type: Object,
      required: true,
    },
    isAlarmTriggerTypeObservation: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      conditionRangeTypes: ['DAY', 'HOUR', 'OBSERVATION'],
      rangeOperators: ['EQ', 'LT', 'LT_EQ', 'GT', 'GT_EQ', 'NE'],
      lessRangeOperators: ['LT', 'LT_EQ'],
    };
  },

  computed: {
    isModeForTheSameAsset() {
      return this.mode === 'FOR_THE_SAME_ASSET';
    },
    isModeForAssets() {
      return this.mode === 'FOR_ASSETS';
    },
    isModeForTheObservation() {
      return this.mode === 'FOR_THE_OBSERVATION';
    },
    range() {
      return this.condition && this.condition.conditionRange && this.condition.conditionRange.range;
    },
    rangeMinimumValue() {
      if (this.lessRangeOperators.includes(this.condition.conditionRange.operator)) {
        return 1;
      }
      return 0;
    },
    isValid() {
      if (this.isModeForTheObservation) {
        return true;
      }
      if (!this.condition.conditionRange) {
        return false;
      }

      if (this.lessRangeOperators.includes(this.condition.conditionRange.operator)) {
        return !!this.range && this.range >= 1;
      }

      return this.range !== '' && this.range >= 0;
    },
  },

  methods: {
    showErrors() {
      const errors = {};

      errors.range = !this.isValid;

      this.errors = errors;
    },
  },
};
</script>
