<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h4 class="panel-title">{{ $t('assetInformation.vue.detailedInformation') }}</h4>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-offset-2 col-md-8 text-center fm-pagination-md">
              <yf-paginator :items="filteredAssets" :page-size="pageSize" @paginated-items="updateItems"></yf-paginator>
            </div>
            <div class="col-md-2 text-right total small pull-right">
              <div>
                <span>{{ $t('vue.generic.found', { x: filteredAssets.length, y: assets.length }) }}</span>
              </div>
            </div>
          </div>
          <table
            class="table table-striped table-hover table-condensed table-bordered table-no-break event-table"
            data-cy="assetInformationListTable"
          >
            <thead>
              <tr>
                <th class="filter-table-header">
                  <div class="pointer" :title="$t('header.name')" @click="setSort('name')">
                    {{ $t('header.assetId') }}
                    <span
                      v-if="sort === 'name'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input
                    v-model="filters['name']"
                    type="text"
                    class="form-control input-sm inline-block"
                    data-cy="assetInformationListNameInput"
                  />
                  <span v-if="filters['name']" @click="clearFilter('name')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header">
                  <div
                    class="pointer"
                    :title="$t('assetInformation.vue.assetClassification1')"
                    @click="setSort('classification1')"
                  >
                    {{ $t('assetInformation.vue.assetClassification1') }}
                    <span
                      v-if="sort === 'classification1'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['classification1']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['classification1']" @click="clearFilter('classification1')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header">
                  <div
                    class="pointer"
                    :title="$t('assetInformation.vue.assetClassification2')"
                    @click="setSort('classification2')"
                  >
                    {{ $t('assetInformation.vue.assetClassification2') }}
                    <span
                      v-if="sort === 'classification2'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['classification2']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['classification2']" @click="clearFilter('classification2')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header">
                  <div
                    class="pointer"
                    :title="$t('assetInformation.vue.assetClassification3')"
                    @click="setSort('classification3')"
                  >
                    {{ $t('assetInformation.vue.assetClassification3') }}
                    <span
                      v-if="sort === 'classification3'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['classification3']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['classification3']" @click="clearFilter('classification3')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header">
                  <div class="pointer" :title="$t('assetInformation.vue.owner')" @click="setSort('owner')">
                    {{ $t('assetInformation.vue.owner') }}
                    <span
                      v-if="sort === 'owner'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['owner']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['owner']" @click="clearFilter('owner')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header">
                  <div class="pointer" :title="$t('assetInformation.vue.product')" @click="setSort('product')">
                    {{ $t('assetInformation.vue.product') }}
                    <span
                      v-if="sort === 'product'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['product']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['product']" @click="clearFilter('product')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header">
                  <div
                    class="pointer"
                    :title="$t('assetInformation.vue.manufacturer')"
                    @click="setSort('manufacturer')"
                  >
                    {{ $t('assetInformation.vue.manufacturer') }}
                    <span
                      v-if="sort === 'manufacturer'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['manufacturer']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['manufacturer']" @click="clearFilter('manufacturer')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header table-width-5">
                  <div class="pointer" :title="$t('assetInformation.vue.tareWeight')" @click="setSort('tareWeight')">
                    {{ $t('assetInformation.vue.tareWeightShort') }}
                    <span
                      v-if="sort === 'tareWeight'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input
                    v-model="filters['tareWeight']"
                    type="text"
                    class="form-control input-sm inline-block"
                    @keypress="isPressedKeyNumber"
                    @paste="isNumberPasteEvent"
                  />
                  <span v-if="filters['tareWeight']" @click="clearFilter('tareWeight')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header th-min-width">
                  <div
                    class="pointer"
                    :title="$t('assetInformation.vue.maximumGrossWeight')"
                    @click="setSort('maxGrossWeight')"
                  >
                    {{ $t('assetInformation.vue.maximumGrossWeightShort') }}
                    <span
                      v-if="sort === 'maxGrossWeight'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input
                    v-model="filters['maxGrossWeight']"
                    type="text"
                    class="form-control input-sm inline-block"
                    @keypress="isPressedKeyNumber"
                    @paste="isNumberPasteEvent"
                  />
                  <span v-if="filters['maxGrossWeight']" @click="clearFilter('maxGrossWeight')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header th-min-width">
                  <div
                    class="pointer"
                    :title="$t('assetInformation.vue.nextRevision')"
                    @click="setSort('nextRevision')"
                  >
                    {{ $t('assetInformation.vue.nextRevision') }}
                    <span
                      v-if="sort === 'nextRevision'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['nextRevision']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['nextRevision']" @click="clearFilter('nextRevision')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
                <th class="filter-table-header th-min-width">
                  <div class="pointer" :title="$t('assetInformation.vue.buildYear')" @click="setSort('buildYear')">
                    {{ $t('assetInformation.vue.buildYear') }}
                    <span
                      v-if="sort === 'buildYear'"
                      class="fa hidden-print"
                      :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                    >
                    </span>
                  </div>
                  <input v-model="filters['buildYear']" type="text" class="form-control input-sm inline-block" />
                  <span v-if="filters['buildYear']" @click="clearFilter('buildYear')">
                    <i class="fa fa-times"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(asset, index) in paginatedAssets" :key="index">
                <td>
                  <a :href="`#/vue/asset-information/${asset.name}`" :title="asset.name">
                    {{ asset.name }}
                  </a>
                </td>
                <td>{{ asset.classification1 }}</td>
                <td>{{ asset.classification2 }}</td>
                <td>{{ asset.classification3 }}</td>
                <td>{{ asset.owner }}</td>
                <td>{{ asset.product }}</td>
                <td>{{ asset.manufacturer }}</td>
                <td>{{ asset.tareWeight }}</td>
                <td>{{ asset.maxGrossWeight }}</td>
                <td>{{ asset.nextRevision }}</td>
                <td>{{ asset.buildYear }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetInformationMixin from '@/mixins/AssetInformationMixin';
import FilterMixin from '../mixins/FilterMixin';
import YfPaginator from '../yf-components/YfPaginator';
import AssetInformationService from '@/services/AssetInformationService';

export default {
  name: 'AssetInformationList',
  components: {
    YfPaginator,
  },
  mixins: [FilterMixin, AssetInformationMixin],
  data() {
    return {
      isLoading: false,
      assets: [],
      paginatedAssets: [],
      pageSize: 16,
    };
  },
  computed: {
    filteredAssets() {
      return this.filter(this.assets);
    },
  },
  watch: {
    'filters.maxGrossWeight': function(newValue) {
      if (newValue) {
        this.filters['maxGrossWeight'] = this.formatNumberToThousands(newValue);
      }
    },
    'filters.tareWeight': function(newValue) {
      if (newValue) {
        this.filters['tareWeight'] = this.formatNumberToThousands(newValue);
      }
    },
  },
  created() {
    this.isLoading = true;
    this.getAssets();
    this.isLoading = false;
  },
  methods: {
    async getAssets() {
      this.assets = await AssetInformationService.getAssetInformationAssets();
    },
    updateItems(items) {
      this.paginatedAssets = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-table-header {
  & + div {
    width: 100%;
  }
  input {
    width: calc(100% - 15px);
  }
}

.table-width-5 {
  width: 5%;
}

tbody > tr {
  word-break: break-all;
}
</style>
