<template>
  <div>
    <div v-if="assetInformationFileDetails.length === 0" data-cy="fileListNotFound">
      {{ $t('assetInformation.vue.preview.noFiles') }}
    </div>
    <div v-else :class="tableWrapper">
      <table class="table table-striped table-hover table-condensed table-bordered" data-cy="fileListTable">
        <thead>
          <tr>
            <th class="table-width-65">{{ $t('assetInformation.vue.tableHeader.fileName') }}</th>
            <th class="table-width-15">{{ $t('assetInformation.vue.tableHeader.addedOn') }}</th>
            <th class="table-width-20">{{ $t('assetInformation.vue.tableHeader.addedBy') }}</th>
            <th class="th-min-width"></th>
            <th class="th-min-width"></th>
            <th class="th-min-width"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fileDetails, index) in assetInformationFileDetails" :key="index">
            <td class="break-word" :title="$t('header.description') + ': ' + fileDetails.description">
              {{ fileDetails.fileName }}
            </td>
            <td>{{ formatDate(fileDetails.creationDate) }}</td>
            <td>{{ fileDetails.authorId || $t('alarmReports.deletedUser') }}</td>
            <td>
              <span
                v-if="isFilePreviewable(fileDetails)"
                class="preview-button"
                :title="$t('alarmsReports.vue.preview') + ': ' + fileDetails.fileName"
                data-cy="fileListPreviewFileButton"
                @click="triggerModal(fileDetails)"
              >
                <i class="glyphicons glyphicons-search before-black"></i>
              </span>
            </td>
            <td>
              <a
                :class="setDisabledClass"
                :href="'/api/asset-information/file-data/download/' + fileDetails.assetInformationFileDataId"
                :title="$t('assetInformation.vue.download') + ': ' + fileDetails.fileName"
                :download="fileDetails.fileName"
                data-cy="fileListDownloadFileButton"
                @click="triggerDownload"
              >
                <i class="glyphicons glyphicons-download before-black"></i>
              </a>
            </td>
            <td>
              <v-popover v-if="checkPermissions(fileDetails)" offset="16" placement="right">
                <div class="tooltip-target" data-cy="fileListDeleteFileButton">
                  <i
                    class="glyphicons glyphicons-bin before-black pointer"
                    :title="$t('generic.delete') + ': ' + fileDetails.fileName"
                  ></i>
                </div>
                <template slot="popover">
                  <h3 class="popover-title break-word">{{ $t('generic.delete') + ': ' + fileDetails.fileName }}</h3>
                  <div class="popover-content">
                    <button v-close-popover="true" class="btn btn-fm-std inline-block right-buffer">
                      {{ $t('value.false') }}
                    </button>
                    <button
                      class="btn btn-fm-std inline-block"
                      data-cy="popoverSubmitButton"
                      @click="removeFile(fileDetails, index)"
                    >
                      {{ $t('value.true') }}
                    </button>
                  </div>
                </template>
              </v-popover>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div @click="closePreviewModal">
      <AssetInformationPreviewModal
        v-if="isPreviewModal"
        :is-file-loading="isFileLoading"
        :file-details-to-be-previewed="fileDetailsToBePreviewed"
        :style="{ display: isPreviewModal ? 'block' : 'none' }"
        @closePreviewModal="closePreviewModal"
      ></AssetInformationPreviewModal>
    </div>
  </div>
</template>

<script>
import AssetInformationMixin from '../mixins/AssetInformationMixin';
import AssetInformationPreviewModal from './AssetInformationPreviewModal';
import AssetInformationService from '@/services/AssetInformationService';
import { VPopover } from 'v-tooltip';

export default {
  name: 'AssetInformationFileList',
  components: {
    AssetInformationPreviewModal,
    VPopover,
  },
  mixins: [AssetInformationMixin],
  props: {
    assetInformationFileDetails: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
    me: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isDownloadTriggered: false,
      isPreviewModal: false,
      isFileLoading: false,
      fileDetailsToBePreviewed: {},
    };
  },
  computed: {
    tableWrapper() {
      if (this.permissions.isDisabled) {
        return 'scrollable-table-preview';
      }
      return 'scrollable-table';
    },
    setDisabledClass() {
      return this.isDownloadTriggered ? 'disabled' : '';
    },
  },
  methods: {
    removeFile(fileDetails, index) {
      this.$emit('removeFile', fileDetails, index);
    },
    closePreviewModal() {
      this.isPreviewModal = false;
      this.fileDetailsToBePreviewed = {};
      this.$emit('close');
    },
    async triggerModal(file) {
      this.isPreviewModal = true;
      this.isFileLoading = true;
      this.fileDetailsToBePreviewed = file;
      const fileData = await AssetInformationService.getAssetInformationFileDataPreview(
        file.assetInformationFileDataId
      );

      const blob = this.base64toBlob(fileData.base64, fileData.fileType);
      this.fileDetailsToBePreviewed.blobUrl = URL.createObjectURL(blob);
      return setTimeout(() => (this.isFileLoading = false), 1000);
    },
    triggerDownload() {
      this.isDownloadTriggered = true;
      return setTimeout(() => (this.isDownloadTriggered = false), 2000);
    },
    base64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      return new Blob(byteArrays, { type: contentType });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 0;
}
a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.image-preview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 70vh;
}
.file-preview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 70vh;
  border: none;
}
.preview-button {
  cursor: pointer;
}
.scrollable-table {
  max-height: 220px;
  display: block;
  overflow: auto;
}
.scrollable-table-preview {
  max-height: 300px;
  display: block;
  overflow: auto;
}

.table-width-15 {
  width: 15%;
}

.table-width-20 {
  width: 20%;
}

.table-width-65 {
  width: 65%;
}

.break-word {
  word-break: break-word;
}

.btn {
  padding: 3px 6px;
}
</style>
