import Vue from 'vue';
import VueI18n from 'vue-i18n';
import http from './Http';

Vue.use(VueI18n);

const loadedLanguages = [];

const i18n = new VueI18n({
  silentTranslationWarn: true,
  fallbackLocale: 'en',
});

loadLanguageAsync('en');

window.addEventListener('language-change', () => {
  loadLanguageAsync('en');
});

export default i18n;

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return http.get(`/api/language/${lang}`).then((res) => {
        Object.keys(res.data).forEach((key) => {
          res.data[key.toLocaleLowerCase()] = res.data[key];
        });

        i18n.setLocaleMessage(lang, res.data);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}
