<template>
  <div v-show="isSubmitted" class="event-validation-box">
    <span v-if="isValid" class="glyphicons glyphicons-ok-2"></span>
    <span v-else class="glyphicons glyphicons-circle-exclamation-mark"></span>
  </div>
</template>

<script>
export default {
  name: 'EventValidationBox',

  props: {
    isValid: {
      type: Boolean,
      required: false,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.event-validation-box {
  width: 25px;
  height: 25px;
  background: white;
  position: absolute;
  top: 5.5px;
  right: 5px;

  .glyphicons-ok-2:before,
  .glyphicons-circle-exclamation-mark:before {
    position: absolute;
    top: 6px;
    left: 4px;
  }

  .glyphicons-ok-2:before {
    color: $success;
  }

  .glyphicons-circle-exclamation-mark:before {
    color: $danger;
  }
}
</style>
