<template>
  <div>
    <div>
      <p v-if="tableTiles.length > 0" class="include-text">
        {{ $t('alarmsReports.vue.includeTableText', { blockName: tableTiles[0].title }) }}
      </p>
      <p v-if="csvTiles.length > 0" class="include-text">
        {{ $t('alarmsReports.vue.attachCsvText', { blockName: csvTiles[0].title }) }}
      </p>
    </div>

    <div v-if="isPreviewAvailable" class="print-report">
      <p v-if="isLoading">{{ $t('generic.loading') }}...</p>
      <div v-else class="content">
        <div class="navbar-default">
          <img v-if="!me.logoData" class="logo" src="images/FM_OneColor_White_350.png" />
          <img v-if="me.logoData" class="logo" :src="me.logoData" />
          <div class="report-name">{{ reportName }}</div>
          <!-- Invisible logo used for centering text and ensure text overflows correctly -->
          <img v-if="!me.logoData" class="logo invisible" src="images/FM_OneColor_White_350.png" />
          <img v-if="me.logoData" class="logo invisible" :src="me.logoData" />
          <div v-if="me.logoData" class="report-powered-by">
            powered by Fleetmonitor
          </div>
        </div>

        <grid-layout
          :layout.sync="nonTablesTiles"
          :col-num="gridLayoutColumns"
          :row-height="gridLayoutRowHeight"
          :is-draggable="isEditable"
          :is-resizable="isEditable"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[10, 10]"
          :use-css-transforms="false"
        >
          <grid-item
            v-for="item in nonTablesTiles"
            :key="`${item.i}`"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            @resize="onResize"
            @resized="onResized"
          >
            <button
              v-if="isEditable"
              class="btn btn-fm refresh-button"
              :title="$t('generic.titles.refresh')"
              @click="redrawChart(item.i)"
            >
              <i class="glyphicons glyphicons-refresh"></i>
            </button>

            <div v-if="!hasData(item)" class="text-center">
              <h4>{{ item.title }}</h4>
              {{ $t('alarmsReports.vue.noDataForReportCriteria') }}
            </div>
            <div v-else class="text-center chart-item">
              <h4>{{ item.title }}</h4>

              <p v-if="item.i === indexForItemThatIsResizing">{{ $t('generic.loading') }}...</p>

              <div v-if="item.i !== indexForItemThatIsResizing">
                <report-template-bar-chart
                  v-if="item.type === 'BAR_CHART'"
                  :item="item"
                  :resize="resize"
                ></report-template-bar-chart>

                <report-template-line-chart
                  v-if="item.type === 'LINE_CHART'"
                  :item="item"
                  :resize="resize"
                ></report-template-line-chart>

                <report-template-donut-chart
                  v-if="item.type === 'DONUT_CHART'"
                  :item="item"
                  :resize="resize"
                ></report-template-donut-chart>
              </div>
            </div>
          </grid-item>
        </grid-layout>
        <div v-if="nonTablesTiles.length > 0" class="page-break"></div>

        <div v-if="showTables">
          <div
            v-for="(item, tableTileIndex) in aggregationTableTiles"
            :key="tableTileIndex"
            class="all-buffer chart-item text-center"
            style="break-before: page;"
          >
            <h4>{{ item.title }}</h4>

            <report-template-aggregation-table :item="item" />
          </div>
        </div>
        <div v-else>
          <p>{{ $t('generic.loading') }}...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import ReportTemplateLineChart from './ReportTemplateLineChart.vue';
import ReportTemplateBarChart from './ReportTemplateBarChart.vue';
import ReportTemplateDonutChart from './ReportTemplateDonutChart.vue';
import { gridLayoutColumns, gridLayoutRowHeight } from '../utils/Constants';
import EventService from '../services/EventService';
import ReportTemplateAggregationTable from './ReportTemplateAggregationTable';

export default {
  name: 'ReportTemplatePreview',

  components: {
    ReportTemplateAggregationTable,
    ReportTemplateDonutChart,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ReportTemplateBarChart,
    ReportTemplateLineChart,
  },

  props: {
    reportTiles: {
      type: Array,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    reportName: {
      type: String,
      default: '',
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      resize: false,
      indexForItemThatIsResizing: null,
      showTables: true,
      gridLayoutColumns,
      gridLayoutRowHeight,
      me: {},
    };
  },
  computed: {
    nonTablesTiles() {
      return this.reportTiles.filter(
        (tile) => tile.type !== 'TABLE' && tile.type !== 'AGGREGATION_TABLE' && tile.type !== 'CSV'
      );
    },
    aggregationTableTiles() {
      return this.reportTiles.filter((tile) => tile.includeTable || tile.type === 'AGGREGATION_TABLE');
    },
    tableTiles() {
      return this.reportTiles.filter((tile) => tile.type === 'TABLE');
    },
    csvTiles() {
      return this.reportTiles.filter((tile) => tile.type === 'CSV');
    },
    isPreviewAvailable() {
      const previewNotAvailable = ['TABLE', 'CSV'];
      return this.reportTiles.find((tile) => !previewNotAvailable.includes(tile.type));
    },
  },

  async created() {
    this.me = await EventService.getMe();
  },

  methods: {
    onResize(evt) {
      this.indexForItemThatIsResizing = evt;
    },

    onResized() {
      this.resize = !this.resize;
      this.$nextTick().then(() => {
        this.indexForItemThatIsResizing = null;
      });
    },

    hasData(item) {
      return item.data && item.data.data && item.data.data.length > 0;
    },

    redrawChart(itemId) {
      this.indexForItemThatIsResizing = itemId;

      setTimeout(() => {
        this.indexForItemThatIsResizing = null;
      }, 100);
    },

    redrawTables() {
      this.showTables = false;

      setTimeout(() => {
        this.showTables = true;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .include-text {
    display: none;
  }
}

.include-text {
  font-style: italic;
}

.print-report {
  max-width: 289mm;
  margin: 0 auto;
  padding: 30px;
  outline: 2px solid #eeeeee;
  position: relative;

  &.not-wide {
    overflow-x: scroll;
  }

  .header,
  .header-space,
  .footer,
  .footer-space {
    height: 60px;
  }
  .header {
    position: absolute;
    top: 30px;
  }
  .footer {
    position: absolute;
    bottom: 30px;
  }
  .navbar-default {
    position: relative;
    height: 61px;
    display: flex;
    justify-content: space-between;
    .report-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      line-height: 61px;
      vertical-align: bottom;
      font-size: 30px;
      color: #ffffff;
    }
  }
  .report-powered-by {
    font-size: 10px;
    float: right;
    color: #fff;
    margin-right: 5px;
    position: absolute;
    right: 0;
    bottom: 4px;
  }

  .refresh-button {
    position: absolute;
    right: -10px;
  }
}

@media print {
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .print-report {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    .chart-item {
      page-break-inside: avoid;
      display: inline-block;
      width: 100%;
    }
    .page-break {
      clear: both;
      page-break-after: always;
    }
    .navbar-default {
      .report-name {
        color: #ffffff !important;
      }
    }
    .table-striped > tbody > tr:nth-child(odd),
    .table-striped > tbody > tr:nth-child(odd) th {
      background-color: #ededed !important;
    }
    .header,
    .header-space,
    .footer,
    .footer-space {
      height: 60px;
    }
    .header {
      position: fixed;
      top: 0;
    }
    .footer {
      position: fixed;
      bottom: 0;
    }
  }
  * {
    color-adjust: exact;
  }
}
</style>
