<template>
  <div class="report-template-2">
    <div ref="donutChart" class="donut-chart">
      A donut chart
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { gridLayoutRowHeight } from '../utils/Constants';

export default {
  name: 'ReportTemplateDonutChart',

  props: {
    item: {
      type: Object,
      required: true,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: {},
    };
  },

  watch: {
    resize: {
      handler: function() {
        if (this.item && this.item.h && this.item.w) {
          this.chart.element.svgContainer.htmlElement.style.height = this.item.h * gridLayoutRowHeight + 'px';
          this.chart.element.svgContainer.htmlElement.style.width = 100 + '%';
        } else {
          this.chart.element.svgContainer.htmlElement.style.height = 300 + 'px';
          this.chart.element.svgContainer.htmlElement.style.width = 100 + '%';
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.donutChart();
  },

  beforeDestroy() {
    if (this.chart.element) {
      this.chart.element.dispose();
    }
  },

  methods: {
    donutChart() {
      let chart = am4core.create(this.$refs.donutChart, am4charts.PieChart);
      chart.responsive.enabled = false;
      chart.language.locale['_decimalSeparator'] = ',';
      chart.language.locale['_thousandSeparator'] = ' ';

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'title';

      // Let's cut a hole in our Pie chart the size of 30% the radius
      chart.innerRadius = am4core.percent(30);

      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      // change the cursor on hover to make it apparent the object can be interacted with
      pieSeries.slices.template.cursorOverStyle = [
        {
          property: 'cursor',
          value: 'pointer',
        },
      ];
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      let legend = (chart.legend = new am4charts.Legend());
      legend.labels.template.text = "{title}: {value} ({value.percent.formatNumber('#.0')}%)";
      legend.valueLabels.template.disabled = true;

      chart.data = this.item.data.data;

      if (this.item && this.item.h && this.item.w) {
        chart.svgContainer.htmlElement.style.height = this.item.h * gridLayoutRowHeight + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      } else {
        chart.svgContainer.htmlElement.style.height = 300 + 'px';
        chart.svgContainer.htmlElement.style.width = 100 + '%';
      }

      this.chart.element = chart;
    },
  },
};
</script>

<style lang="scss" scoped>
.donut-chart {
  width: 100%;
  height: 250px;
}
</style>
