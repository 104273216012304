export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/* eslint-disable */
function naturalSort(a, b) {
  let re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi,
    sre = /(^[ ]*|[ ]*$)/g,
    dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
    hre = /^0x[0-9a-f]+$/i,
    ore = /^0/,
    i = function(s) {
      return (naturalSort.insensitive && ('' + s).toLowerCase()) || '' + s;
    },
    // convert all to strings strip whitespace
    x = i(a).replace(sre, '') || '',
    y = i(b).replace(sre, '') || '',
    // chunk/tokenize
    xN = x
      .replace(re, '\0$1\0')
      .replace(/\0$/, '')
      .replace(/^\0/, '')
      .split('\0'),
    yN = y
      .replace(re, '\0$1\0')
      .replace(/\0$/, '')
      .replace(/^\0/, '')
      .split('\0'),
    // numeric, hex or date detection
    xD = parseInt(x.match(hre)) || (xN.length != 1 && x.match(dre) && Date.parse(x)),
    yD = parseInt(y.match(hre)) || (xD && y.match(dre) && Date.parse(y)) || null,
    oFxNcL,
    oFyNcL;
  // first try and sort Hex codes or Dates
  if (yD)
    if (xD < yD) return -1;
    else if (xD > yD) return 1;
  // natural sorting through split numeric strings and default strings
  for (let cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
    // find floats not starting with '0', string or 0 if not defined (Clint Priest)
    oFxNcL = (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
    oFyNcL = (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
      return isNaN(oFxNcL) ? 1 : -1;
    }
    // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
    else if (typeof oFxNcL !== typeof oFyNcL) {
      oFxNcL += '';
      oFyNcL += '';
    }
    if (oFxNcL < oFyNcL) return -1;
    if (oFxNcL > oFyNcL) return 1;
  }
  return 0;
}

function normalizedValue(value) {
  value = value.replace(',', '.').toLowerCase();
  const padding = '000000000000000';

  value = value.replace(/(\d+)((\.\d+|\,\d+)+)?/g, function($0, integer, decimal, $3) {
    if (decimal !== $3) {
      return padding.slice(integer.length) + integer + decimal;
    }
    decimal = decimal || '.0';
    return padding.slice(integer.length) + integer + decimal + padding.slice(decimal.length);
  });
  return value;
}

export class NaturalSortService {
  static naturalSort(a, b) {
    return naturalSort(a, b);
  }

  static naturalSortNumberArrayByKey(arrInput, key) {
    const sortDescending = false;
    return this.naturalSortNumberArrayByKeyAndSortOrder(arrInput, key, sortDescending);
  }

  static naturalSortArrayByKey(arrInput, key) {
    const sortDescending = false;
    return this.naturalSortArrayByKeyAndSortOrder(arrInput, key, sortDescending);
  }

  static naturalSortArrayByKeyAndSortOrder(arrInput, key, sortDescending) {
    if (!arrInput) {
      return;
    }

    if (!key) {
      return;
    }

    const keys = key.split('.');

    let array = arrInput.sort(function(a, b) {
      keys.forEach(function(key) {
        a = a[key];
        b = b[key];
      });

      return naturalSort(normalizedValue(a), normalizedValue(b));
    });

    if (sortDescending) {
      array = array.reverse();
    }

    return array;
  }

  static naturalSortArray(arrInput, sortDescending = false) {
    if (!arrInput) {
      return;
    }

    let array = arrInput.sort(function(a, b) {
      return naturalSort(normalizedValue(a), normalizedValue(b));
    });

    if (sortDescending) {
      array = array.reverse();
    }

    return array;
  }

  static naturalSortNumberArrayByKeyAndSortOrder(arrInput, key, sortDescending) {
    if (!arrInput) {
      return;
    }

    if (!key) {
      return;
    }

    const keys = key.split('.');

    let array = arrInput.sort(function(a, b) {
      keys.forEach(function(key) {
        a = a[key];
        b = b[key];
      });

      return naturalSort(a, b);
    });

    if (sortDescending) {
      array = array.reverse();
    }

    return array;
  }
}

export class GroupByService {
  static groupByKey(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}

export function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isOnlyDigits(string) {
  return /^[0-9]+$/.test(string);
}

export function groupedTimezones() {
  return [
    {
      region: 'Africa',
      zones: [
        'Abidjan',
        'Accra',
        'Addis_Ababa',
        'Algiers',
        'Asmera',
        'Bamako',
        'Bangui',
        'Banjul',
        'Bissau',
        'Blantyre',
        'Brazzaville',
        'Bujumbura',
        'Cairo',
        'Casablanca',
        'Ceuta',
        'Conakry',
        'Dakar',
        'Dar_es_Salaam',
        'Djibouti',
        'Douala',
        'El_Aaiun',
        'Freetown',
        'Gaborone',
        'Harare',
        'Johannesburg',
        'Kampala',
        'Khartoum',
        'Kigali',
        'Kinshasa',
        'Lagos',
        'Libreville',
        'Lome',
        'Luanda',
        'Lubumbashi',
        'Lusaka',
        'Malabo',
        'Maputo',
        'Maseru',
        'Mbabane',
        'Mogadishu',
        'Monrovia',
        'Nairobi',
        'Ndjamena',
        'Niamey',
        'Nouakchott',
        'Ouagadougou',
        'Porto-Novo',
        'Sao_Tome',
        'Timbuktu',
        'Tripoli',
        'Tunis',
        'Windhoek',
      ],
    },
    {
      region: 'America',
      zones: [
        'Adak',
        'Anchorage',
        'Anguilla',
        'Antigua',
        'Araguaina',
        'Aruba',
        'Asuncion',
        'Atka',
        'Bahia',
        'Barbados',
        'Belem',
        'Belize',
        'Boa_Vista',
        'Bogota',
        'Boise',
        'Buenos_Aires',
        'Cambridge_Bay',
        'Campo_Grande',
        'Cancun',
        'Caracas',
        'Catamarca',
        'Cayenne',
        'Cayman',
        'Chicago',
        'Chihuahua',
        'Cordoba',
        'Costa_Rica',
        'Cuiaba',
        'Curacao',
        'Danmarkshavn',
        'Dawson',
        'Dawson_Creek',
        'Denver',
        'Detroit',
        'Dominica',
        'Edmonton',
        'Eirunepe',
        'El_Salvador',
        'Ensenada',
        'Fortaleza',
        'Fort_Wayne',
        'Glace_Bay',
        'Godthab',
        'Goose_Bay',
        'Grand_Turk',
        'Grenada',
        'Guadeloupe',
        'Guatemala',
        'Guayaquil',
        'Guyana',
        'Halifax',
        'Havana',
        'Hermosillo',
        'Indiana/Indianapolis',
        'Indiana/Knox',
        'Indiana/Marengo',
        'Indiana/Vevay',
        'Indianapolis',
        'Inuvik',
        'Iqaluit',
        'Jamaica',
        'Jujuy',
        'Juneau',
        'Kentucky/Louisville',
        'Kentucky/Monticello',
        'Knox_IN',
        'La_Paz',
        'Lima',
        'Los_Angeles',
        'Louisville',
        'Maceio',
        'Managua',
        'Manaus',
        'Martinique',
        'Mazatlan',
        'Mendoza',
        'Menominee',
        'Merida',
        'Mexico_City',
        'Miquelon',
        'Monterrey',
        'Montevideo',
        'Montreal',
        'Montserrat',
        'Nassau',
        'New_York',
        'Nipigon',
        'Nome',
        'Noronha',
        'North_Dakota/Center',
        'Panama',
        'Pangnirtung',
        'Paramaribo',
        'Phoenix',
        'Port-au-Prince',
        'Porto_Acre',
        'Porto_Velho',
        'Port_of_Spain',
        'Puerto_Rico',
        'Rainy_River',
        'Rankin_Inlet',
        'Recife',
        'Regina',
        'Rio_Branco',
        'Rosario',
        'Santiago',
        'Santo_Domingo',
        'Sao_Paulo',
        'Scoresbysund',
        'Shiprock',
        'St_Johns',
        'St_Kitts',
        'St_Lucia',
        'St_Thomas',
        'St_Vincent',
        'Swift_Current',
        'Tegucigalpa',
        'Thule',
        'Thunder_Bay',
        'Tijuana',
        'Toronto',
        'Tortola',
        'Vancouver',
        'Virgin',
        'Whitehorse',
        'Winnipeg',
        'Yakutat',
        'Yellowknife',
      ],
    },
    {
      region: 'Antarctica',
      zones: [
        'Casey',
        'Davis',
        'DumontDUrville',
        'Mawson',
        'McMurdo',
        'Palmer',
        'Rothera',
        'South_Pole',
        'Syowa',
        'Vostok',
      ],
    },
    {
      region: 'Arctic',
      zones: ['Longyearbyen'],
    },
    {
      region: 'Asia',
      zones: [
        'Aden',
        'Almaty',
        'Amman',
        'Anadyr',
        'Aqtau',
        'Aqtobe',
        'Ashgabat',
        'Ashkhabad',
        'Baghdad',
        'Bahrain',
        'Baku',
        'Bangkok',
        'Beirut',
        'Bishkek',
        'Brunei',
        'Calcutta',
        'Choibalsan',
        'Chongqing',
        'Chungking',
        'Colombo',
        'Dacca',
        'Damascus',
        'Dhaka',
        'Dili',
        'Dubai',
        'Dushanbe',
        'Gaza',
        'Harbin',
        'Hong_Kong',
        'Hovd',
        'Irkutsk',
        'Istanbul',
        'Jakarta',
        'Jayapura',
        'Jerusalem',
        'Kabul',
        'Kamchatka',
        'Karachi',
        'Kashgar',
        'Katmandu',
        'Krasnoyarsk',
        'Kuala_Lumpur',
        'Kuching',
        'Kuwait',
        'Macao',
        'Macau',
        'Magadan',
        'Makassar',
        'Manila',
        'Muscat',
        'Nicosia',
        'Novosibirsk',
        'Omsk',
        'Oral',
        'Phnom_Penh',
        'Pontianak',
        'Pyongyang',
        'Qatar',
        'Qyzylorda',
        'Rangoon',
        'Riyadh',
        'Riyadh87',
        'Riyadh88',
        'Riyadh89',
        'Saigon',
        'Sakhalin',
        'Samarkand',
        'Seoul',
        'Shanghai',
        'Singapore',
        'Taipei',
        'Tashkent',
        'Tbilisi',
        'Tehran',
        'Tel_Aviv',
        'Thimbu',
        'Thimphu',
        'Tokyo',
        'Ujung_Pandang',
        'Ulaanbaatar',
        'Ulan_Bator',
        'Urumqi',
        'Vientiane',
        'Vladivostok',
        'Yakutsk',
        'Yekaterinburg',
        'Yerevan',
      ],
    },
    {
      region: 'Atlantic',
      zones: [
        'Azores',
        'Bermuda',
        'Canary',
        'Cape_Verde',
        'Faeroe',
        'Jan_Mayen',
        'Madeira',
        'Reykjavik',
        'South_Georgia',
        'Stanley',
        'St_Helena',
      ],
    },
    {
      region: 'Australia',
      zones: [
        'ACT',
        'Adelaide',
        'Brisbane',
        'Broken_Hill',
        'Canberra',
        'Darwin',
        'Hobart',
        'LHI',
        'Lindeman',
        'Lord_Howe',
        'Melbourne',
        'North',
        'NSW',
        'Perth',
        'Queensland',
        'South',
        'Sydney',
        'Tasmania',
        'Victoria',
        'West',
        'Yancowinna',
      ],
    },
    {
      region: 'Brazil',
      zones: ['Acre', 'DeNoronha', 'East', 'West'],
    },
    {
      region: 'Canada',
      zones: [
        'Atlantic',
        'Central',
        'East-Saskatchewan',
        'Eastern',
        'Mountain',
        'Newfoundland',
        'Pacific',
        'Saskatchewan',
        'Yukon',
      ],
    },
    {
      region: 'Chile',
      zones: ['Continental', 'EasterIsland'],
    },
    {
      region: 'Etc',
      zones: ['GMT'],
    },
    {
      region: 'Europe',
      zones: [
        'Amsterdam',
        'Andorra',
        'Athens',
        'Belfast',
        'Belgrade',
        'Berlin',
        'Bratislava',
        'Brussels',
        'Bucharest',
        'Budapest',
        'Chisinau',
        'Copenhagen',
        'Dublin',
        'Gibraltar',
        'Helsinki',
        'Istanbul',
        'Kaliningrad',
        'Kiev',
        'Lisbon',
        'Ljubljana',
        'London',
        'Luxembourg',
        'Madrid',
        'Malta',
        'Minsk',
        'Monaco',
        'Moscow',
        'Nicosia',
        'Oslo',
        'Paris',
        'Prague',
        'Riga',
        'Rome',
        'Samara',
        'San_Marino',
        'Sarajevo',
        'Simferopol',
        'Skopje',
        'Sofia',
        'Stockholm',
        'Tallinn',
        'Tirane',
        'Tiraspol',
        'Uzhgorod',
        'Vaduz',
        'Vatican',
        'Vienna',
        'Vilnius',
        'Warsaw',
        'Zagreb',
        'Zaporozhye',
        'Zurich',
      ],
    },
    {
      region: 'Indian',
      zones: [
        'Antananarivo',
        'Chagos',
        'Christmas',
        'Cocos',
        'Comoro',
        'Kerguelen',
        'Mahe',
        'Maldives',
        'Mauritius',
        'Mayotte',
        'Reunion',
      ],
    },
    {
      region: 'Mexico',
      zones: ['BajaNorte', 'BajaSur', 'General'],
    },
    {
      region: 'Pacific',
      zones: [
        'Apia',
        'Auckland',
        'Chatham',
        'Easter',
        'Efate',
        'Enderbury',
        'Fakaofo',
        'Fiji',
        'Funafuti',
        'Galapagos',
        'Gambier',
        'Guadalcanal',
        'Guam',
        'Honolulu',
        'Johnston',
        'Kiritimati',
        'Kosrae',
        'Kwajalein',
        'Majuro',
        'Marquesas',
        'Midway',
        'Nauru',
        'Niue',
        'Norfolk',
        'Noumea',
        'Pago_Pago',
        'Palau',
        'Pitcairn',
        'Ponape',
        'Port_Moresby',
        'Rarotonga',
        'Saipan',
        'Samoa',
        'Tahiti',
        'Tarawa',
        'Tongatapu',
        'Truk',
        'Wake',
        'Wallis',
        'Yap',
      ],
    },
    {
      region: 'US',
      zones: [
        'Alaska',
        'Aleutian',
        'Arizona',
        'Central',
        'East-Indiana',
        'Eastern',
        'Hawaii',
        'Indiana-Starke',
        'Michigan',
        'Mountain',
        'Pacific',
        'Pacific-New',
        'Samoa',
      ],
    },
  ];
}

export function timezones() {
  return [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Aruba',
    'America/Asuncion',
    'America/Atka',
    'America/Bahia',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fortaleza',
    'America/Fort_Wayne',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Vevay',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Center',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Port_of_Spain',
    'America/Puerto_Rico',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Katmandu',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Riyadh87',
    'Asia/Riyadh88',
    'Asia/Riyadh89',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Atlantic/St_Helena',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Darwin',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/North',
    'Australia/NSW',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Brazil/Acre',
    'Brazil/DeNoronha',
    'Brazil/East',
    'Brazil/West',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/East-Saskatchewan',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'Chile/Continental',
    'Chile/EasterIsland',
    'Etc/GMT',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Mexico/BajaNorte',
    'Mexico/BajaSur',
    'Mexico/General',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Pacific-New',
    'US/Samoa',
  ];
}
