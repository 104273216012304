<template>
  <yf-modal modal-size="modal-dialog-md" modal-type="panel-danger">
    <div class="modal-header panel-heading">
      <button type="button" class="close" @click="closeModal">×</button>
      <h4 class="modal-title">
        <i class="glyphicons glyphicons-warning-sign"></i> {{ $t('generic.operationNotAllowed') }}
      </h4>
    </div>
    <div class="modal-body">
      <p>{{ $t('alarmsReports.vue.usedIn') }}</p>
      <ul>
        <li v-for="report in reportsUsed" :key="report">{{ report }}</li>
      </ul>
      <p>{{ $t('alarmsReports.vue.removeBefore') }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" @click="closeModal">
        <i class="glyphicons glyphicons-ok-2"></i>
      </button>
    </div>
  </yf-modal>
</template>

<script>
import YfModal from '../yf-components/YfModal';

export default {
  name: 'ReportTemplateDeleteModal',

  components: {
    YfModal,
  },

  props: {
    reportsUsed: {
      type: Array,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
