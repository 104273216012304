<template>
  <div
    :class="[dragReference ? 'dropzone-over' : '']"
    @dragover.prevent
    @dragenter="dragEnter"
    @dragleave="dragLeave"
    @drop="drop"
  >
    <div v-if="dragReference" class="dropzone-text" data-cy="dropAndDragDropzone">
      <span class="dropzone-title">{{ $t(title) }}</span>
      <br />
      <span class="dropzone-subtitle">{{ $t(subTitle) }}</span>
    </div>

    <div :class="dragReference ? 'hide-content' : ''">
      <slot name="content"></slot>
    </div>

    <div v-if="isPreview" :class="footerClasses">
      <label :for="filePurpose" :class="inputLabelClasses" :title="$t(uploadText)" data-cy="dropAndDragFileLabel">
        <i class="fa fa-cloud-upload"></i>
        {{ $t(uploadText) }}
      </label>
      <input
        :id="filePurpose"
        ref="fileUpload"
        type="file"
        :accept="acceptedFileTypes"
        tabindex="-1"
        data-cy="dropAndDragFileInput"
        @change="onFileChange"
      />
      <slot name="LEVEL_1"></slot>
      <slot name="LEVEL_2"></slot>
    </div>
    <div v-if="imageError && !dragReference" class="position-center image-is-to-big" data-cy="dropAndDragImageError">
      {{ $t(imageError) }}
    </div>
    <div v-if="fileError" class="file-is-to-big" data-cy="dropAndDragFileError">{{ $t(fileError) }}</div>
    <div v-if="assetImageFile && !dragReference" class="image-is-selected">{{ assetImageFile.name }}</div>
  </div>
</template>

<script>
import { FILE_PURPOSE, ACCEPTED_IMAGE_ENDINGS, ACCEPTED_FILE_ENDINGS } from '@/utils/Constants';
import AssetInformationMixin from '@/mixins/AssetInformationMixin';

export default {
  name: 'AssetInformationDropAndDrag',
  mixins: [AssetInformationMixin],
  props: {
    filePurpose: {
      type: String,
      required: true,
    },
    availableFileSpace: {
      type: Number,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
    fileError: {
      type: String,
      required: false,
      default: '',
    },
    imageError: {
      type: String,
      required: false,
      default: '',
    },
    assetImageFile: {
      type: File,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dragReference: null,
    };
  },
  computed: {
    isPreview() {
      if (this.permissions.isDisabled) {
        return false;
      } else if (this.permissions.isReadOnly && this.isOnlyImageAllowed()) {
        return false;
      }
      return true;
    },
    acceptedFileTypes() {
      if (this.filePurpose === FILE_PURPOSE.ASSET_IMAGE) {
        return ACCEPTED_IMAGE_ENDINGS;
      }
      return ACCEPTED_FILE_ENDINGS + ', ' + ACCEPTED_IMAGE_ENDINGS;
    },
    title() {
      if (this.filePurpose === FILE_PURPOSE.ASSET_IMAGE) {
        return 'assetInformation.vue.imageDropAndDrag.title';
      }
      return 'assetInformation.vue.fileDropAndDrag.title';
    },
    subTitle() {
      if (this.filePurpose === FILE_PURPOSE.ASSET_IMAGE) {
        return 'assetInformation.vue.imageDropAndDrag.subTitle';
      }
      return 'assetInformation.vue.fileDropAndDrag.subTitle';
    },
    uploadText() {
      if (this.filePurpose === FILE_PURPOSE.ASSET_IMAGE) {
        return 'assetInformation.vue.uploadImage';
      }
      return 'assetInformation.vue.attachFile';
    },
    footerClasses() {
      let classes = ['upload-container'];
      if (this.isOnlyImageAllowed()) {
        classes.push('upload-label-no-bottom-margin');
        classes.push('input-button-same-row');
      }
      if (this.dragReference) {
        classes.push('hide-content');
      }
      return classes;
    },
    inputLabelClasses() {
      let classes = ['upload-label'];
      if (this.isOnlyImageAllowed()) {
        classes.push('upload-label-no-bottom-margin');
      }
      if (this.fileError || this.imageError) {
        classes.push('file-not-selected');
      }
      return classes;
    },
  },
  methods: {
    isFileToBig(allowedSize, file) {
      return allowedSize * 1024 * 1024 < file.size;
    },
    isOnlyImageAllowed() {
      return this.filePurpose === FILE_PURPOSE.ASSET_IMAGE;
    },
    isAnyFileAllowed() {
      return this.filePurpose === FILE_PURPOSE.FILE_LIST;
    },
    dragEnter(e) {
      if (this.isPreview && !this.dragReference) {
        this.dragReference = e.currentTarget;
      }
    },
    dragLeave(e) {
      if (this.isPreview && this.dragReference === e.target) {
        this.dragReference = null;
      }
    },
    drop(e) {
      e.preventDefault();
      if (this.isPreview) {
        this.onFileChange({ target: e.dataTransfer });
      }
    },
    onFileChange(e) {
      const files = Object.values(e.target.files);
      this.$refs.fileUpload.value = null;
      this.dragReference = null;
      if (files.length > 1) {
        if (this.isOnlyImageAllowed()) {
          this.$emit('imageError', 'assetInformation.vue.error.onlyOneFileAllowed');
        }
        this.$emit('fileError', 'assetInformation.vue.error.onlyOneFileAllowed');
        return;
      }

      const file = files[0];
      this.assetImageDragAndDrop(file);
      this.fileListDragAndDrop(file);
    },
    assetImageDragAndDrop(file) {
      if (this.isOnlyImageAllowed()) {
        if (this.isFileAllowedImage(file.name)) {
          if (this.isFileToBig(5, file)) {
            this.$emit('imageError', 'assetInformation.vue.imageDropAndDrag.subTitle');
            return;
          }
          this.$emit('setAssetImageFile', file);
        } else {
          this.$emit('imageError', 'assetInformation.vue.error.imageTypesAllowed');
        }
      }
    },
    fileListDragAndDrop(file) {
      if (this.isAnyFileAllowed() && this.isFileAllowed(file.name)) {
        if (this.isFileToBig(15, file)) {
          this.$emit('fileError', 'assetInformation.vue.error.fileIsToBig');
          return;
        }
        if (this.availableFileSpace < file.size) {
          this.$emit('fileError', 'assetInformation.vue.error.notEnoughFileSpace');
          return;
        }
        this.$emit('updateGeneralFile', file);
      } else {
        this.$emit('fileError', 'assetInformation.vue.error.fileTypesAllowed');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone-text {
  position: relative;
  top: 20%;
  text-align: center;
  transform: translate(0, -50%);
  width: 100%;
  color: white;
}
.dropzone-title {
  font-size: 20px;
  font-weight: bold;
}
.dropzone-subtitle {
  font-size: 15px;
  font-weight: 100;
}
.dropzone-over {
  border: 3px dashed black;
  opacity: 0.9;
  background: grey;
}
.dropzone-over * {
  pointer-events: none;
}
.hide-content {
  display: none;
}
.upload-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 7px;
}
.position-center {
  text-align: center;
}
.upload-label {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  line-height: 1;
}
.upload-label-no-bottom-margin {
  margin-bottom: 0;
}
.upload-label:hover {
  background-color: #2aaae2;
  color: #ffffff;
}
input[type='file'] {
  display: none;
}
.file-not-selected {
  outline: 1px solid red !important;
  border-color: red !important;
}
.image-is-to-big {
  font-size: 12px;
  color: red;
  margin-top: 65px;
}
.image-is-selected {
  font-size: 12px;
  color: #26aee8;
  margin-top: 65px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}
.file-is-to-big {
  font-size: 12px;
  color: red;
  margin-left: 110px;
  position: absolute;
  bottom: 0;
  margin-bottom: 54px;
}
.input-button-same-row {
  display: flex;
  justify-content: center;
  line-height: 1;
  & > * {
    margin: 10px;
  }
}
</style>
