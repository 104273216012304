<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <AssetInformationCreateClassification
              :asset-classification="assetClassification"
              @addClassification3="addClassification3"
              @removeClassification3="removeClassification3"
            ></AssetInformationCreateClassification>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetInformationCreateClassification from '@/asset-classification/AssetInformationCreateClassification';
import AssetInformationMixin from '@/mixins/AssetInformationMixin';

export default {
  name: 'AssetClassification',
  components: {
    AssetInformationCreateClassification,
  },
  mixins: [AssetInformationMixin],
  data() {
    return {
      isLoading: false,
      assetClassification: {},
    };
  },
  async created() {
    this.isLoading = true;
    await this.getAssetInformationClassification();
    this.isLoading = false;
  },
  methods: {
    addClassification3(assetClassification) {
      this.assetClassification.classification3.push(assetClassification);
    },
    removeClassification3(assetClassification) {
      const index = this.assetClassification.classification3.findIndex((obj) => obj.id === assetClassification.id);
      this.assetClassification.classification3.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
