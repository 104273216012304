<template>
  <div v-if="errorMessage" class="alert alert-danger">
    <strong>{{ $t(errorMessage) }}</strong>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeErrorAlert">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AssetInformationErrorAlert',
  props: {
    errorMessage: String,
  },
  methods: {
    closeErrorAlert() {
      this.$emit('closeErrorAlert');
    },
  },
};
</script>

<style lang="scss" scoped></style>
