<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else>
      <div class="panel-body">
        <AssetInformationErrorAlert
          :error-message="errorMessage"
          @closeErrorAlert="closeErrorAlert"
        ></AssetInformationErrorAlert>
        <div class="row">
          <div class="col-md-3">
            <div class="panel panel-primary">
              <div class="panel-heading">
                <h4 class="panel-title">
                  {{ $t('assetInformation.vue.assetImage') }}
                  {{ $route.params.assetName }}
                </h4>
              </div>
              <div class="panel-body">
                <div v-if="isAssetImageUploading" class="image-dropzone-area-loader">
                  <div class="loader">{{ $t('generic.loading') }}</div>
                </div>

                <div v-else>
                  <AssetInformationDropAndDrag
                    class="image-dropzone-area"
                    :file-purpose="FILE_PURPOSE.ASSET_IMAGE"
                    :available-file-space="availableFileSpace"
                    :permissions="permissions"
                    :image-error="imageError"
                    :asset-image-file="assetImageFile"
                    data-cy="dropAndDragImage"
                    @setAssetImageFile="setAssetImageFile"
                    @imageError="changeImageErrorMessage"
                  >
                    <img
                      slot="content"
                      class="asset-image-preview"
                      :src="showAssetImagePreview"
                      :alt="$t(showAssetImageAlt)"
                      data-cy="assetInformationAssetImage"
                    />
                    <button
                      slot="LEVEL_1"
                      class="btn btn-success"
                      data-cy="assetImageSubmitButton"
                      :title="$t('assetInformation.vue.saveImage')"
                      @click="saveAssetImage"
                    >
                      <i class="glyphicons glyphicons-plus"></i>
                    </button>
                    <v-popover
                      v-if="isObjectEmpty(assetInformation.assetImageDetails)"
                      slot="LEVEL_2"
                      offset="16"
                      placement="right"
                    >
                      <button class="tooltip-target btn btn-danger" data-cy="assetImageDeleteButton">
                        <i class="glyphicons glyphicons-minus" :title="$t('assetInformation.vue.deleteImage')"></i>
                      </button>
                      <template slot="popover">
                        <h3 class="popover-title break-word">
                          {{ $t('generic.delete') + ': ' + assetInformation.assetImageDetails.fileName }}
                        </h3>
                        <div class="popover-content">
                          <button v-close-popover="true" class="btn btn-fm-std inline-block right-buffer">
                            {{ $t('value.false') }}
                          </button>
                          <button
                            class="btn btn-fm-std inline-block"
                            data-cy="popoverSubmitButton"
                            @click="removeFile(assetInformation.assetImageDetails)"
                          >
                            {{ $t('value.true') }}
                          </button>
                        </div>
                      </template>
                    </v-popover>
                  </AssetInformationDropAndDrag>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="panel panel-primary">
              <div class="panel-heading">
                <h4 class="panel-title">
                  {{ $t('assetInformation.vue.fileList') }}
                  <i
                    class="fa fa-question-circle"
                    :title="$t('assetInformation.vue.fileList.infoIcon', { number: availableFileSpaceToMb })"
                  ></i>
                </h4>
              </div>
              <div class="panel-body">
                <div v-if="loadingGeneralFileButton" class="image-dropzone-area-loader">
                  <div class="loader">{{ $t('generic.loading') }}</div>
                </div>

                <form v-else class="row" @submit.prevent="saveGeneralFile">
                  <AssetInformationDropAndDrag
                    class="file-dropzone-area"
                    :file-purpose="FILE_PURPOSE.FILE_LIST"
                    :available-file-space="availableFileSpace"
                    :permissions="permissions"
                    :file-error="fileError"
                    data-cy="dropAndDragFile"
                    @updateGeneralFile="updateGeneralFile"
                    @fileError="changeFileErrorMessage"
                  >
                    <div slot="content">
                      <AssetInformationFileList
                        :asset-information-file-details="assetInformation.assetInformationFileDetails"
                        :me="me"
                        :permissions="permissions"
                        @removeFile="removeFile"
                      ></AssetInformationFileList>
                    </div>
                    <label v-if="generalFile" slot="LEVEL_1" class="general-file-label" data-cy="fileListSelectedFile">
                      {{ generalFile.name }}
                    </label>
                    <div slot="LEVEL_2" class="has-no-padding-left input-button-same-row">
                      <input
                        id="description"
                        v-model="generalFileDescription"
                        autocomplete="off"
                        :placeholder="$t('generic.maxLength256')"
                        type="text"
                        class="form-control"
                        maxlength="256"
                        data-cy="fileListDescriptionInput"
                      />
                      <button
                        class="btn pull-right btn-success"
                        :title="$t('assetInformation.vue.saveFile')"
                        data-cy="fileListSubmitButton"
                      >
                        <i class="glyphicons glyphicons-plus"></i>
                      </button>
                    </div>
                  </AssetInformationDropAndDrag>
                </form>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <AssetInformationComment
              :comments="assetInformation.assetInformationComments"
              :me="me"
              :permissions="permissions"
              @saveComment="saveComment"
              @removeComment="removeComment"
            ></AssetInformationComment>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7">
            <AssetInformationGeneralInfo
              :general-info="assetInformation.generalAssetInformation"
              :asset-classification="assetClassification"
              :permissions="permissions"
              @saveGeneralInfo="saveGeneralInfo"
            ></AssetInformationGeneralInfo>
          </div>

          <div class="col-md-5">
            <AssetInformationLatestObservations :latest-observations="latestObservations" :me="me">
            </AssetInformationLatestObservations>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetInformationFileList from './AssetInformationFileList';
import AssetInformationDropAndDrag from './AssetInformationDropAndDrag';
import AssetInformationComment from './AssetInformationComment';
import AssetInformationGeneralInfo from './AssetInformationGeneralInfo';
import AssetInformationErrorAlert from '@/asset-information/AssetInformationErrorAlert';
import { FILE_PURPOSE } from '@/utils/Constants';
import AssetInformationLatestObservations from '@/asset-information/AssetInformationLatestObservations';
import defaultImage from '@/resources/images/defaultImage.png';
import EventService from '@/services/EventService';
import AssetInformationService from '@/services/AssetInformationService';
import AssetInformationMixin from '@/mixins/AssetInformationMixin';
import { VPopover } from 'v-tooltip';

export default {
  name: 'AssetInformation',
  components: {
    AssetInformationDropAndDrag,
    AssetInformationFileList,
    AssetInformationComment,
    AssetInformationGeneralInfo,
    AssetInformationLatestObservations,
    AssetInformationErrorAlert,
    VPopover,
  },
  mixins: [AssetInformationMixin],
  data() {
    return {
      FILE_PURPOSE,
      isLoading: false,
      loadingGeneralFileButton: false,
      me: {
        accesses: [],
      },
      assetInformation: {
        generalAssetInformation: {},
        assetInformationComments: [],
        assetInformationFileDetails: [],
        assetImageDetails: {},
      },
      assetClassification: {
        classification1: [],
        classification2: [],
        classification3: [],
      },
      latestObservations: [],
      assetImageFile: null,
      generalFile: null,
      generalFileDescription: '',
      isAssetImageUploading: false,
      errorMessage: '',
      permissions: {
        isReadOnly: false,
        isDisabled: false,
      },
      imageError: '',
      fileError: '',
    };
  },
  computed: {
    availableFileSpace() {
      const maximumFileSpace = 25 * 1024 * 1024;
      if (this.assetInformation.assetInformationFileDetails.length > 0) {
        let totalFileSize = this.assetInformation.assetInformationFileDetails.reduce(
          (fileSize1, fileDetails2) => fileSize1 + fileDetails2.fileSize,
          0
        );
        return maximumFileSpace - totalFileSize;
      }
      return maximumFileSpace;
    },
    availableFileSpaceToMb() {
      return (this.availableFileSpace / (1024 * 1024)).toFixed(1);
    },
    showAssetImagePreview() {
      if (this.assetInformation.assetImageDetails && this.assetInformation.assetImageDetails.fileData) {
        if (this.assetInformation.assetImageDetails.fileData.base64) {
          return (
            `data:${this.assetInformation.assetImageDetails.fileData.fileType};base64,` +
            this.assetInformation.assetImageDetails.fileData.base64
          );
        }
        return this.assetInformation.assetImageDetails.fileData;
      }
      return defaultImage;
    },
    showAssetImageAlt() {
      if (this.assetInformation.assetImageDetails && this.assetInformation.assetImageDetails.fileName) {
        return this.assetInformation.assetImageDetails.fileName;
      }
      return 'assetInformation.vue.defaultImage';
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getMe();
      await this.setPermissions();
      await this.getAssetInformation();
      await this.getLatestObservation();
      await this.getAssetInformationClassification();
    } catch (e) {}
    this.isLoading = false;
  },
  methods: {
    async getMe() {
      this.me = await EventService.getMe();
    },
    async getAssetInformation() {
      this.assetInformation = await AssetInformationService.getAssetInformation(this.$route.params.assetName);
      this.isAssetImageUploading = true;
      if (
        this.assetInformation.assetImageDetails &&
        this.assetInformation.assetImageDetails.assetInformationFileDataId
      ) {
        this.assetInformation.assetImageDetails.fileData = await AssetInformationService.getAssetInformationFileDataPreview(
          this.assetInformation.assetImageDetails.assetInformationFileDataId
        );
      }
      this.isAssetImageUploading = false;
    },
    async setPermissions() {
      try {
        const testMode = 1;
        const assetMode = await AssetInformationService.getAssetMode(this.$route.params.assetName);
        if (assetMode === testMode) {
          this.permissions.isDisabled = true;
        }
        this.permissions.isReadOnly = !this.checkPermissions();
      } catch (e) {
        window.location.replace(`#/vue/asset-information`);
        throw new Error('Asset does not exist');
      }
    },
    async getLatestObservation() {
      let latestObservation = await AssetInformationService.getLatestAssetObservationInformation(
        this.$route.params.assetName
      );
      this.latestObservations = latestObservation.data.result;
    },
    saveFile(file, purpose, fileDescription = null) {
      return AssetInformationService.saveAssetInformationFile(this.setFormData(file, purpose, fileDescription))
        .then((savedFileId) => {
          return savedFileId;
        })
        .catch((err) => {
          if (err.response.status === 413) {
            this.errorMessage = 'assetInformation.vue.error.fileIsToBig';
            throw new Error('FILE_TO_BIG');
          }
          this.errorMessage = 'assetInformation.vue.error.Unforeseen';
          throw new Error('UNFORSEEN_ERROR');
        });
    },
    setFormData(file, purpose, fileDescription) {
      let details = {
        fileName: file.name,
        description: fileDescription,
        fileSize: file.size,
        purpose: purpose,
        assetInformationId: this.assetInformation.generalAssetInformation.id,
      };

      if (this.assetInformation.assetImageDetails) {
        if (this.assetInformation.assetImageDetails.assetInformationFileDataId) {
          details.assetInformationFileDataId = this.assetInformation.assetImageDetails.assetInformationFileDataId;
        }
        if (this.assetInformation.assetImageDetails.id) {
          details.id = this.assetInformation.assetImageDetails.id;
        }
      }

      let formData = new FormData();
      formData.append('file', file);
      formData.append('details', JSON.stringify(details));
      formData.append('fileType', file.type);
      return formData;
    },
    async saveAssetImage() {
      if (!this.assetImageFile) {
        this.imageError = 'assetInformation.vue.error.fileIsNotSelected';
        return;
      }
      try {
        this.isAssetImageUploading = true;
        const savedFileId = await this.saveFile(this.assetImageFile, FILE_PURPOSE.ASSET_IMAGE);
        this.assetInformation.assetImageDetails = await AssetInformationService.getAssetInformationFileDetails(
          savedFileId
        );
        this.assetInformation.assetImageDetails.fileData = URL.createObjectURL(this.assetImageFile);
        this.assetImageFile = null;
        setTimeout(() => (this.isAssetImageUploading = false), 1000);
      } catch (error) {
        return error;
      }
    },
    updateGeneralFile(file) {
      this.fileError = '';
      this.generalFile = file;
    },
    async saveGeneralFile() {
      if (!this.generalFile) {
        this.fileError = 'assetInformation.vue.error.fileIsNotSelected';
        return;
      }
      try {
        this.loadingGeneralFileButton = true;
        const savedFileId = await this.saveFile(
          this.generalFile,
          FILE_PURPOSE.FILE_LIST,
          this.generalFileDescription.trim()
        );
        this.generalFile = null;
        this.generalFileDescription = '';
        setTimeout(() => (this.loadingGeneralFileButton = false), 1000);

        const savedFileDetails = await AssetInformationService.getAssetInformationFileDetails(savedFileId);
        this.assetInformation.assetInformationFileDetails.splice(0, 0, savedFileDetails);
      } catch (error) {
        return error;
      }
    },
    async saveGeneralInfo(generalInfo) {
      await AssetInformationService.updateAssetInformation(generalInfo);
    },
    async saveComment(commentText) {
      let comment = {
        commentText: commentText,
        assetInformationId: this.assetInformation.generalAssetInformation.id,
      };
      const commentId = await AssetInformationService.saveAssetInformationComment(comment);
      let newComment = await AssetInformationService.getAssetInformationComment(commentId);
      this.assetInformation.assetInformationComments.splice(0, 0, newComment);
    },
    async removeComment(index, commentId) {
      await AssetInformationService.deleteAssetInformationComment(commentId);
      this.assetInformation.assetInformationComments.splice(index, 1);
    },
    async removeFile(fileDetails, index = null) {
      if (index === null) {
        this.isAssetImageUploading = true;
      } else {
        this.loadingGeneralFileButton = true;
      }
      await AssetInformationService.deleteAssetInformationFileDetails(
        fileDetails.id,
        fileDetails.assetInformationFileDataId
      );
      if (index === null) {
        setTimeout(() => (this.isAssetImageUploading = false), 1000);
        this.assetInformation.assetImageDetails = {};
        this.imageError = '';
        this.assetImageFile = null;
      } else {
        this.fileError = '';
        this.generalFile = null;
        this.assetInformation.assetInformationFileDetails.splice(index, 1);
        setTimeout(() => (this.loadingGeneralFileButton = false), 1000);
      }
    },
    closeErrorAlert() {
      this.errorMessage = '';
    },
    changeFileErrorMessage(errorMessage) {
      this.generalFile = null;
      this.fileError = errorMessage;
    },
    changeImageErrorMessage(errorMessage) {
      this.assetImageFile = null;
      this.imageError = errorMessage;
    },
    isObjectEmpty(object) {
      return object && Object.keys(object).length !== 0;
    },
    setAssetImageFile(file) {
      this.imageError = '';
      this.assetImageFile = file;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-dropzone-area {
  height: 300px;
  position: relative;
}
.file-dropzone-area {
  height: 300px;
  position: relative;
}
.asset-image-preview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 230px;
  object-fit: contain;
  transform: translateZ(0);
}
.image-dropzone-area-loader {
  height: 300px;
  position: relative;
}
.comment-area-loader {
  height: 300px;
  position: relative;
}
.btn {
  padding: 3px 6px;
}
.general-file-label {
  color: #26aee8;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 65%;
  margin-bottom: -5px;
}
.input-button-same-row {
  display: flex;
  justify-content: space-between;
  margin: -7px -7px -7px -10px;
  & > * {
    margin: 10px;
  }
  input {
    color: black;
  }
}
</style>
