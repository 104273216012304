export class GeofenceFieldsService {
  static generateGeofenceFields(geofenceLevel) {
    return this.generateGeofenceLevelsArray(geofenceLevel)
      .map((level) => {
        const levelText = level > 1 ? 'Lv' + level : '';

        return [
          {
            columnName: 'header.geofenceArea' + levelText,
            dataField: 'geofenceArea' + levelText,
            originalDataField: 'geofenceArea' + levelText,
          },
          {
            columnName: 'header.geofenceType' + levelText,
            dataField: 'geofenceType' + levelText,
            originalDataField: 'geofenceType' + levelText,
          },
        ];
      })
      .flatMap((x) => x);
  }

  static generateGeofenceAreaFields(geofenceLevel) {
    return this.generateGeofenceLevelsArray(geofenceLevel).map((level) => {
      const levelText = level > 1 ? 'Lv' + level : '';

      return {
        columnName: 'header.geofenceArea' + levelText,
        dataField: 'geofenceArea' + levelText,
        originalDataField: 'geofenceArea' + levelText,
      };
    });
  }

  static generateGeofenceTypeFields(geofenceLevel) {
    return this.generateGeofenceLevelsArray(geofenceLevel).map((level) => {
      const levelText = level > 1 ? 'Lv' + level : '';

      return {
        columnName: 'header.geofenceType' + levelText,
        dataField: 'geofenceType' + levelText,
        originalDataField: 'geofenceType' + levelText,
      };
    });
  }

  static generateGeofenceLevelsArray(geofenceLevel) {
    const numberGeofenceLevel = Number(geofenceLevel);

    const geofenceLevels = [];
    for (let i = 1; i <= numberGeofenceLevel; i++) {
      geofenceLevels.push(i);
    }
    return geofenceLevels;
  }

  static generateGeofenceLevelFromGeofenceFields(geofenceFields) {
    const fields = geofenceFields.map((field) => field.columnName);

    if (fields.some((field) => field.includes('Lv3'))) {
      return 3;
    }

    if (fields.some((field) => field.includes('Lv2'))) {
      return 2;
    }

    if (fields.some((field) => field.includes('base'))) {
      return 1;
    }

    return 0;
  }
}
