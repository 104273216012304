<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else>
      <div class="col-md-11">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('menu.vue.alarms') }}</h4>
          </div>
          <div class="panel-body">
            <div>
              <div class="row">
                <div class="col-md-2 no-padding">
                  <alarm-popover id="createAlarmPopover" @on-submit="redirectToCreateAlarm">
                    <button id="createAlarm" class="btn btn-fm-std btn-sm bottom-buffer" :title="$t('generic.new')">
                      <i class="glyphicons glyphicons-plus"></i>
                    </button>
                  </alarm-popover>
                </div>

                <div class="col-md-8 text-center fm-pagination-md">
                  <yf-paginator :items="filteredAlarms" @paginated-items="updateItems"></yf-paginator>
                </div>
                <div class="col-md-2 text-right total small pull-right">
                  <span>
                    <div>{{ $t('vue.generic.found', { x: filteredAlarms.length, y: alarms.length }) }}</div>
                  </span>
                </div>
              </div>
              <table
                v-tooltip="deleteEventMessageOptions('alarmsReports.vue.notAllowedToDeleteAlarm')"
                class="table table-striped table-hover table-condensed table-bordered table-no-break event-table"
                data-cy="alarmListTable"
              >
                <thead>
                  <tr>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('header.name')" @click="setSort('name')">
                        {{ $t('header.name') }}
                        <span
                          v-if="sort === 'name'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input
                        v-model="filters['name']"
                        type="text"
                        class="form-control input-sm inline-block"
                        data-cy="alarmListNameSearchField"
                      />
                      <span v-if="filters['name']" @click="clearFilter('name')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('generic.endDate')" @click="setSort('endDate')">
                        {{ $t('generic.endDate') }}
                        <span
                          v-if="sort === 'endDate'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input v-model="filters['endDate']" type="text" class="form-control input-sm inline-block" />
                      <span v-if="filters['endDate']" @click="clearFilter('endDate')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th>
                      {{ $t('header.description') }}
                    </th>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('header.createdBy')" @click="setSort('author')">
                        {{ $t('header.createdBy') }}
                        <span
                          v-if="sort === 'author'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input v-model="filters['author']" type="text" class="form-control input-sm inline-block" />
                      <span v-if="filters['author']" @click="clearFilter('author')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('header.editedBy')" @click="setSort('editedBy')">
                        {{ $t('header.editedBy') }}
                        <span
                          v-if="sort === 'editedBy'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input v-model="filters['editedBy']" type="text" class="form-control input-sm inline-block" />
                      <span v-if="filters['editedBy']" @click="clearFilter('editedBy')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th class="th-min-width"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="alarm in paginatedAlarms" :key="alarm.id" :class="{ 'fm-disabled': !alarm.active }">
                    <td>
                      <a :href="`#/vue/alarms/${alarm.id}`" :title="alarm.name">
                        {{ alarm.name }}
                      </a>
                    </td>
                    <td>{{ alarm.endDate }}</td>
                    <td>{{ alarm.description }}</td>
                    <td>{{ $t(alarm.author) }}</td>
                    <td>{{ $t(alarm.editedBy) }}</td>
                    <td class="button-area">
                      <yf-popover
                        v-if="isAlarmReactive(alarm)"
                        :title="$t('alarmsReports.reactivate') + ': ' + alarm.name"
                        @onSubmit="reactivateAlarm(alarm)"
                      >
                        <i
                          class="glyphicons glyphicons-restart before-black pointer right-buffer"
                          :title="$t('alarmsReports.reactivate') + ': ' + alarm.name"
                        ></i>
                      </yf-popover>
                      <yf-popover
                        :title="$t('generic.delete') + ': ' + alarm.name"
                        data-cy="alarmListDeleteButton"
                        @onSubmit="deleteAlarm(alarm)"
                      >
                        <i
                          class="glyphicons glyphicons-bin before-black pointer"
                          :title="$t('generic.delete') + ': ' + alarm.name"
                        ></i>
                      </yf-popover>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <button
        id="showLog"
        class="btn btn-fm-std pull-right right-buffer"
        :title="$t('alarmsReports.log')"
        @click="showLog()"
      >
        <i class="glyphicons glyphicons-chevron-left"></i> {{ $t('alarmsReports.log') }}
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.filter-table-header {
  & + div {
    width: 100%;
  }

  input {
    width: calc(100% - 15px);
  }
}

.button-area {
  white-space: nowrap;
  div {
    display: table-cell;
  }
  div:only-child {
    float: right;
  }
}
</style>
<script>
import EventService from '../services/EventService';
import { eventTypes, alarmDelivery } from '../utils/Constants';
import YfPopover from '../yf-components/YfPopover';
import YfPaginator from '../yf-components/YfPaginator';
import FilterMixin from '../mixins/FilterMixin';
import AlarmPopover from './AlarmPopover';
import EventMixin from '../mixins/EventMixin';

export default {
  name: 'AlarmList',

  components: {
    AlarmPopover,
    YfPopover,
    YfPaginator,
  },

  mixins: [FilterMixin, EventMixin],

  data() {
    return {
      isLoading: false,
      alarms: [],
      paginatedAlarms: [],
    };
  },
  computed: {
    filteredAlarms: function() {
      return this.filter(this.alarms);
    },
  },

  async created() {
    this.isLoading = true;
    await this.getAlarms();
    this.isLoading = false;
  },

  methods: {
    redirectToCreateAlarm(event) {
      switch (event.action) {
        case 'CREATE_NEW':
          window.location.replace('#/vue/alarms/0');
          break;

        case 'CREATE_NEW_FROM_SCENARIO':
          window.location.replace(`#/vue/alarms/0/templates/${event.alarmScenario.id}`);
          break;

        default:
          throw new Error('Unknown action: ' + event.action);
      }
    },
    async getAlarms() {
      this.alarms = await EventService.getEvents(eventTypes.ALARM);
      this.alarms.forEach((a) => (a.endDate = new Date(a.endDate).toLocaleDateString('sv-SE')));
    },
    async deleteAlarm(alarm) {
      try {
        await EventService.deleteEvent(alarm.id);
        this.isLoading = true;
        if (this.hasFilter) {
          this.filters = {};
        }
        await this.getAlarms();
        this.updateItems(this.alarms);
      } catch (e) {
        if (e.response.status === 403) {
          this.showDeleteEventMessage();
        }
      }
      this.isLoading = false;
    },
    showLog() {
      document.dispatchEvent(new CustomEvent('fm-open-alarm-log'));
    },
    updateItems(items) {
      this.paginatedAlarms = items;
    },
    dateNow() {
      return new Date();
    },
    hasEndDatePassed(alarm) {
      const nowInAlarmTimezone = this.dateNow().toLocaleDateString('sv-SE', { timeZone: alarm.timezone });
      return new Date(nowInAlarmTimezone).setHours(0, 0, 0, 0) > new Date(alarm.endDate).setHours(0, 0, 0, 0);
    },
    isAlarmReactive(alarm) {
      if (alarm.active || alarm.triggerDate === null || this.hasEndDatePassed(alarm)) {
        return false;
      }
      const allowedDeliveryTypes = [alarmDelivery.ONCE, alarmDelivery.ONCE_PER_DAY];
      return allowedDeliveryTypes.includes(alarm.alarmDelivery);
    },
    async reactivateAlarm(alarm) {
      await EventService.reactivateAlarm(alarm);
      await this.getAlarms();
    },
  },
};
</script>
