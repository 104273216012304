<template>
  <form>
    <div class="row collapsible">
      <div class="form-group col-md-4 has-no-padding-right" :class="{ 'has-error': errors.name && isSubmitted }">
        <label>
          {{ $t('generic.name') }}
        </label>
        <input v-model="event.name" type="text" class="form-control" data-cy="eventName" />
        <span v-if="errors.duplicateName && isSubmitted" class="help-block">
          {{ $t('alarmsReports.vue.eventDuplicateNameErrorText') }}
        </span>
      </div>
      <div class="form-group col-md-4 has-no-padding-right" :class="{ 'has-error': errors.startDate && isSubmitted }">
        <label>
          {{ $t('generic.startDate') }}
        </label>
        <datepicker
          v-model="event.startDate"
          placeholder=""
          :monday-first="true"
          :input-class="'form-control'"
        ></datepicker>
      </div>
      <div class="form-group col-md-4" :class="{ 'has-error': errors.endDate && isSubmitted }">
        <label>
          {{ $t('generic.endDate') }}
        </label>
        <datepicker
          v-model="event.endDate"
          placeholder=""
          :monday-first="true"
          :input-class="'form-control'"
        ></datepicker>
      </div>
    </div>
    <div class="row collapsible">
      <div v-show="event.type === 'REPORT' && !isReportDesignOnlyTableInMail" class="form-group col-md-4 has-no-padding-right" :class="{ 'has-error': errors.fileName && isSubmitted }">
        <label>
          {{ $t('generic.fileName') }}
          <i class="fa fa-question-circle" :title="$t('alarmsReports.vue.fileName.info')"></i>
        </label>
        <input v-model="event.fileName" type="text" class="form-control" data-cy="eventFileName" maxlength="45" :placeholder="$t('generic.maxLength45')"/>
        <span v-if="errors.invalidFileName && isSubmitted" class="help-block">
          {{ $t('settings.bad.name') }}
        </span>
        <span v-if="fileName.length === 45" class="help-block error">
          {{ $t('generic.maxLength45') }}
        </span>
      </div>
      <div class="form-group col-md-4 has-no-padding-right">
        <label>
          {{ $t('generic.description') }}
        </label>
        <textarea v-model="event.description" class="form-control" cols="30" rows="3"></textarea>
      </div>
      <div class="form-group col-md-4">
        <label>
          {{ $t('settings.timezone') }}
        </label>
        <multiselect
          v-model="timezone"
          :options="timezones"
          group-values="zones"
          group-label="region"
          :allow-empty="false"
          placeholder=""
          select-label=""
          selected-label=""
          deselect-label=""
        />
      </div>
      <div v-show="event.type === 'ALARM'" class="form-group col-md-4">
        <label>
          {{ $t('alarmReports.triggerMode') }}
        </label>
        <multiselect
          v-model="event.alarmDelivery"
          :options="eventDeliveryOptions"
          :custom-label="(value) => $t('alarmsReports.vue.' + value)"
          :allow-empty="false"
          placeholder=""
          select-label=""
          selected-label=""
          deselect-label=""
        >
        </multiselect>
      </div>
      <div :class="notifyExpiryClass">
        <label>
          <span>{{
            event.type === 'ALARM' ? $t('alarmReports.notifyAlarmExpiry') : $t('alarmReports.notifyReportExpiry')
          }}</span
          >&nbsp;
          <input v-model="event.expiryNotification" type="checkbox" class="expiry-notification-checkbox" />
        </label>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import { groupedTimezones, timezones } from '../utils/Utils';
import {
  defaultAlarm,
  alarmDelivery,
  eventTriggerTypes,
  UNICODE_ALPHANUMERIC_EXTENDED_XRegExp,
} from '../utils/Constants';
import EventService from '../services/EventService';
import XRegExp from 'xregexp';

export default {
  name: 'EventGeneralInfo',

  components: {
    Multiselect,
    Datepicker,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
    originalEvent: {
      type: Object,
      required: true,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      timezones: groupedTimezones(),
      errors: {},
      debounce: {},
      eventNames: [],
    };
  },

  computed: {
    eventDeliveryOptions() {
      let eventDeliveryOptions = [alarmDelivery.ALWAYS, alarmDelivery.ONCE];
      if (this.event.triggerType !== eventTriggerTypes.TIME) {
        eventDeliveryOptions.push(alarmDelivery.ONCE_PER_DAY);
        return eventDeliveryOptions;
      }
      return eventDeliveryOptions;
    },
    name() {
      return this.event && this.event.name ? this.event.name.trim() : '';
    },
    fileName() {
      return this.event && this.event.fileName ? this.event.fileName.trim() : '';
    },
    type() {
      return this.event.type;
    },
    isValid() {
      const defaultValid = this.name.length >= 1 && this.name.length <= 256 && !this.isNameDuplicate() &&
        this.isStartDateBeforeEndDate;

      if (this.event.type === 'REPORT' && !this.isReportDesignOnlyTableInMail) {
        return (
          defaultValid &&
          this.fileName.length >= 1 &&
          this.fileName.length <= 45 &&
          this.isFileNameValid
        );
      }
      return (defaultValid);
    },
    isFileNameValid() {
      return XRegExp.test(this.event.fileName, UNICODE_ALPHANUMERIC_EXTENDED_XRegExp);
    },
    isStartDateBeforeEndDate() {
      if (!this.event || !this.event.startDate || !this.event.endDate) {
        return false;
      }

      return this.event.startDate.getTime() < this.event.endDate.getTime();
    },
    isReportDesignOnlyTableInMail() {
      if (this.event.reportDesign && this.event.reportDesign.data && this.event.reportDesign.data.length === 1) {
        return this.event.reportDesign.data[0].type === 'TABLE';
      }
      return false;
    },
    timezone: {
      get() {
        if (this.event.timezone) {
          let region = this.event.timezone.split('/')[0];
          let zone = this.event.timezone.split('/')[1];
          region = groupedTimezones().find((groupedTz) => groupedTz.region === region);
          return region.zones.find((z) => z === zone);
        }
        return defaultAlarm.timezone;
      },
      set(val) {
        this.event.timezone = timezones().find((tz) => tz.indexOf(val) > -1);
      },
    },
    notifyExpiryClass() {
      let classes = ['form-group'];
      if (this.event.type === 'ALARM' || this.isReportDesignOnlyTableInMail) {
        classes.push('col-md-8');
      } else {
        classes.push('col-md-4')
      }
      return classes;
    }
  },

  watch: {
    name() {
      this.showErrors();
    },
    fileName() {
      this.showErrors();
    },
    async type() {
      this.eventNames = await EventService.getEventNames(this.type);
    },
    isStartDateBeforeEndDate() {
      this.showErrors();
    },
    isSubmitted() {
      this.showErrors();
    },
    isValid() {
      this.$emit('validation-changed', this.isValid);
    },
    'event.triggerType': function(newValue) {
      if (newValue === eventTriggerTypes.TIME && this.event.alarmDelivery === alarmDelivery.ONCE_PER_DAY) {
        this.event.alarmDelivery = alarmDelivery.ALWAYS;
      }
    },
    'event.reportDesign': function() {
      this.showErrors();
    }
  },

  async created() {
    if (this.event.type) {
      this.eventNames = await EventService.getEventNames(this.type);
    }

    if (this.isTemplate) {
      this.showErrors();
      this.$emit('validation-changed', this.isValid);
    }
  },

  methods: {
    async showErrors() {
      const errors = {};

      errors.name = this.name.length < 1 || this.name.length > 256 || this.isNameDuplicate();
      errors.duplicateName = this.isNameDuplicate();
      errors.fileName = this.fileName.length < 1 || this.fileName.length > 45 || !this.isFileNameValid;
      errors.fileNameLength = this.fileName.length > 45;
      errors.invalidFileName = !this.isFileNameValid;
      errors.startDate = !this.isStartDateBeforeEndDate;
      errors.endDate = !this.isStartDateBeforeEndDate;

      this.errors = errors;
    },
    isNameDuplicate() {
      if (!this.event || !this.name) {
        return false;
      }

      const name = this.name.toLowerCase();

      if (this.originalEvent && this.originalEvent.name) {
        const originalName = this.originalEvent.name.toLowerCase();

        if (originalName === name) {
          return this.isTemplate;
        }
      }

      return this.eventNames //
        .map((eventName) => eventName.toLowerCase())
        .some((eventName) => eventName === name);
    },
  },
};
</script>

<style>
.expiry-notification-checkbox {
  vertical-align: bottom;
}
</style>
