<template>
  <div>
    <div v-if="isLoading" class="loader">{{ $t('generic.loading') }}</div>

    <div v-else>
      <div class="col-md-11">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h4 class="panel-title">{{ $t('menu.vue.reports') }}</h4>
          </div>
          <div class="panel-body">
            <div>
              <div class="row">
                <div class="col-md-2 no-padding">
                  <report-popover id="createReportPopover" @on-submit="redirectToCreateReport">
                    <button id="createReport" class="btn btn-fm-std btn-sm bottom-buffer" :title="$t('generic.new')">
                      <i class="glyphicons glyphicons-plus"></i>
                    </button>
                  </report-popover>
                </div>

                <div class="col-md-8 text-center fm-pagination-md">
                  <yf-paginator :items="filteredReports" @paginated-items="updateItems"></yf-paginator>
                </div>
                <div class="col-md-2 text-right total small pull-right">
                  <span>
                    <div>{{ $t('vue.generic.found', { x: filteredReports.length, y: reports.length }) }}</div>
                  </span>
                </div>
              </div>
              <table
                v-tooltip="deleteEventMessageOptions('alarmsReports.vue.notAllowedToDeleteReport')"
                class="table table-striped table-hover table-condensed table-bordered table-no-break event-table"
                data-cy="reportListTable"
              >
                <thead>
                  <tr>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('header.name')" @click="setSort('name')">
                        {{ $t('header.name') }}
                        <span
                          v-if="sort === 'name'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input
                        v-model="filters['name']"
                        type="text"
                        class="form-control input-sm inline-block"
                        data-cy="reportListNameSearchField"
                      />
                      <span v-if="filters['name']" @click="clearFilter('name')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('generic.endDate')" @click="setSort('endDate')">
                        {{ $t('generic.endDate') }}
                        <span
                          v-if="sort === 'endDate'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input v-model="filters['endDate']" type="text" class="form-control input-sm inline-block" />
                      <span v-if="filters['endDate']" @click="clearFilter('endDate')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th>
                      {{ $t('header.description') }}
                    </th>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('header.createdBy')" @click="setSort('author')">
                        {{ $t('header.createdBy') }}
                        <span
                          v-if="sort === 'author'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input v-model="filters['author']" type="text" class="form-control input-sm inline-block" />
                      <span v-if="filters['author']" @click="clearFilter('author')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th class="filter-table-header">
                      <div class="pointer" :title="$t('header.editedBy')" @click="setSort('editedBy')">
                        {{ $t('header.editedBy') }}
                        <span
                          v-if="sort === 'editedBy'"
                          class="fa hidden-print"
                          :class="{ 'fa-chevron-down': desc, 'fa-chevron-up': !desc }"
                        >
                        </span>
                      </div>
                      <input v-model="filters['editedBy']" type="text" class="form-control input-sm inline-block" />
                      <span v-if="filters['editedBy']" @click="clearFilter('editedBy')">
                        <i class="fa fa-times"></i>
                      </span>
                    </th>
                    <th class="th-min-width"></th>
                    <th v-if="me.accesses.includes('superadmin')" class="th-min-width"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="report in paginatedReports" :key="report.id" :class="{ 'fm-disabled': !report.active }">
                    <td>
                      <a :href="`#/vue/reports/${report.id}`" :title="report.name">
                        {{ report.name }}
                      </a>
                    </td>
                    <td>{{ report.endDate }}</td>
                    <td>{{ report.description }}</td>
                    <td>{{ $t(report.author) }}</td>
                    <td>{{ $t(report.editedBy) }}</td>
                    <td>
                      <yf-popover
                        :title="$t('generic.delete') + ': ' + report.name"
                        data-cy="reportListDeleteButton"
                        @onSubmit="deleteReport(report)"
                      >
                        <i
                          class="glyphicons glyphicons-bin before-black pointer"
                          :title="$t('generic.delete') + ': ' + report.name"
                        ></i>
                      </yf-popover>
                    </td>
                    <td v-if="me.accesses.includes('superadmin')">
                      <span
                        v-if="!isPreviewAvailable(report)"
                        class="preview-disabled"
                        :title="$t('alarmsReports.vue.noPreviewAvailable')"
                      >
                        <i class="glyphicons glyphicons-search before-black"></i>
                      </span>
                      <a
                        v-else
                        :href="`#/vue/preview/${report.id}`"
                        :title="$t('alarmsReports.vue.preview') + ': ' + report.name"
                      >
                        <i class="glyphicons glyphicons-search before-black"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <button
        id="showLog"
        class="btn btn-fm-std pull-right right-buffer"
        :title="$t('alarmsReports.log')"
        @click="showLog()"
      >
        <i class="glyphicons glyphicons-chevron-left"></i> {{ $t('alarmsReports.log') }}
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.filter-table-header {
  & + div {
    width: 100%;
  }

  input {
    width: calc(100% - 15px);
  }
}
.preview-disabled {
  opacity: 0.2;
}
</style>
<script>
import EventService from '../services/EventService';
import { eventTypes } from '../utils/Constants';
import YfPopover from '../yf-components/YfPopover.vue';
import FilterMixin from '../mixins/FilterMixin';
import YfPaginator from '../yf-components/YfPaginator';
import ReportPopover from './ReportPopover';
import EventMixin from '../mixins/EventMixin';

export default {
  name: 'ReportList',

  components: {
    ReportPopover,
    YfPaginator,
    YfPopover,
  },
  mixins: [FilterMixin, EventMixin],

  data() {
    return {
      isLoading: false,
      reports: [],
      paginatedReports: [],
      me: {},
    };
  },

  computed: {
    filteredReports: function() {
      return this.filter(this.reports);
    },
  },

  async created() {
    this.isLoading = true;
    this.me = await EventService.getMe();
    await this.getReports();
    this.isLoading = false;
  },

  methods: {
    redirectToCreateReport(event) {
      switch (event.action) {
        case 'CREATE_NEW':
          window.location.replace('#/vue/reports/0');
          break;

        case 'CREATE_NEW_FROM_SCENARIO':
          window.location.replace(`#/vue/reports/0/templates/${event.reportScenario.id}`);
          break;

        default:
          throw new Error('Unknown action: ' + event.action);
      }
    },

    async getReports() {
      this.reports = await EventService.getEvents(eventTypes.REPORT);
      this.reports.forEach((a) => (a.endDate = new Date(a.endDate).toLocaleDateString('sv-SE')));
    },

    async deleteReport(report) {
      try {
        await EventService.deleteEvent(report.id);
        this.isLoading = true;
        if (this.hasFilter) {
          this.filters = {};
        }
        await this.getReports();
        this.updateItems(this.alarms);
      } catch (e) {
        if (e.response.status === 403) {
          this.showDeleteEventMessage();
        }
      }
      this.isLoading = false;
    },
    showLog() {
      document.dispatchEvent(new CustomEvent('fm-open-alarm-log'));
    },
    updateItems(items) {
      this.paginatedReports = items;
    },
    isPreviewAvailable(report) {
      const previewNotAvailable = ['TABLE', 'CSV'];
      const parsedDesignData = JSON.parse(report.reportDesign.data);
      return parsedDesignData.find((data) => !previewNotAvailable.includes(data.type));
    },
  },
};
</script>
