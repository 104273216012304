<template>
  <span class="space"></span>
</template>

<script>
export default {
  name: 'EventSpace',
};
</script>

<style lang="scss">
$spacing: 3px;

.space {
  margin-left: $spacing;
  margin-right: $spacing;
}
</style>
