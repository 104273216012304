<template>
  <form class="form-horizontal">
    <div v-if="isAlarmTriggerTypeObservation" class="form-line nowrap">
      <span v-if="index !== 0">{{ $t('alarmsReports.vue.and') }}<event-space></event-space></span>

      <multiselect
        v-model="mode"
        :options="modes"
        :custom-label="(aMode) => $t('alarmsReports.vue.' + aMode)"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        open-direction="bottom"
        placeholder=""
        style="width: 365px"
        data-cy="andConditionBlockModes"
        @input="onChangeMode"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>
      {{ $t('alarmsReports.vue.where') }}
    </div>

    <div class="form-line">
      <span v-if="isAlarmTriggerTypeTime && index !== 0"
        >{{ $t('alarmsReports.vue.and') }}<event-space></event-space
      ></span>

      <multiselect
        ref="columnRef"
        v-model="condition.column"
        :class="{ 'has-error': errors.column && isSubmitted }"
        :options="columns"
        :custom-label="({ columnName }) => $t(columnName)"
        group-values="columns"
        group-label="groupedBy"
        label="columnName"
        track-by="dataField"
        :allow-empty="false"
        select-label=""
        selected-label=""
        deselect-label=""
        placeholder=""
        style="flex: 2 0 0"
        data-cy="alarmAndConditionBlockGroupedBy"
        @input="resetOperator"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        ref="operatorRef"
        v-model="condition.operator"
        :options="columnConditions"
        :custom-label="({ label }) => (label ? $t('alarmsReports.vue.' + label) : '')"
        :allow-empty="false"
        :disabled="columnConditions.length === 0"
        select-label=""
        deselect-label=""
        selected-label=""
        placeholder=""
        style="flex: 2"
        @input="onOperatorChange"
      >
      </multiselect>

      <event-space></event-space>
      <event-space></event-space>

      <multiselect
        v-if="!isAlarmTypeText && !isAlarmTypeSuggestion && !isAlarmTypeApi"
        :options="columnConditions"
        :disabled="columnConditions.length === 0"
        placeholder=""
        class="value-multiselect"
      >
      </multiselect>

      <multiselect
        v-show="isAlarmTypeText"
        ref="valueRefText"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :multiple="isValueMultiple"
        :taggable="isValueMultiple"
        :clear-on-select="isValueMultiple"
        :close-on-select="!isValueMultiple"
        :allow-empty="false"
        label="value"
        track-by="value"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        :placeholder="placeholder"
        class="value-multiselect"
        @select="onTextValueChange"
        @search-change="addQueryAsOnlyResult"
      >
      </multiselect>

      <span
        v-tooltip="{
          content: $t('categoriesGroups.maxSelected'),
          show: assetIdLimit.messageOpen,
          trigger: 'manual',
          placement: 'left',
          classes: 'tooltip-info',
          offset: '10',
        }"
      ></span>

      <multiselect
        v-show="isAlarmTypeSuggestion"
        ref="valueRefSuggestion"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :loading="isLoading"
        :multiple="isValueMultiple"
        :taggable="isValueMultiple"
        :clear-on-select="isValueMultiple"
        :close-on-select="!isValueMultiple"
        :hide-selected="true"
        open-direction="bottom"
        :custom-label="(value) => $t(value.text)"
        track-by="text"
        :placeholder="placeholder"
        select-label=""
        selected-label=""
        deselect-label=""
        :show-no-results="false"
        class="value-multiselect"
        @select="onValueChange"
        @search-change="getSuggestionsForQuery"
      >
      </multiselect>

      <multiselect
        v-show="isAlarmTypeApi"
        ref="valueRefApi"
        v-model="condition.values"
        :class="{ 'has-error': errors.values && isSubmitted }"
        :options="options"
        :loading="isLoading"
        :multiple="isValueMultiple"
        :taggable="isValueMultiple"
        :clear-on-select="isValueMultiple"
        :close-on-select="!isValueMultiple"
        :hide-selected="true"
        open-direction="bottom"
        :custom-label="getAlarmTypeApiLabel"
        track-by="name"
        :show-no-results="false"
        :placeholder="placeholder"
        select-label=""
        selected-label=""
        deselect-label=""
        class="value-multiselect"
        data-cy="alarmAndConditionBlockValues"
        @select="onValueChange"
        @search-change="searchChangedForApi"
      >
      </multiselect>
    </div>

    <alarm-and-condition-range-picker-block
      v-if="isAlarmTriggerTypeObservation"
      :condition="condition"
      :is-alarm-trigger-type-observation="isAlarmTriggerTypeObservation"
      :mode="mode"
      @validation-changed="setRangeValidity"
    />

    <div class="form-line flex-end">
      <small @click.prevent="$emit('delete-block')">
        <a v-show="isReset" href="#" :title="$t('alarmsReports.vue.resetCondition')">
          {{ $t('alarmsReports.vue.resetCondition') }}
        </a>
        <a v-show="isDelete" href="#" :title="$t('alarmsReports.vue.deleteCondition')" data-cy="alarmDeleteCondition">
          {{ $t('alarmsReports.vue.deleteCondition') }}
        </a>
      </small>
    </div>
  </form>
</template>

<script>
import EventService from '../services/EventService';
import { deepClone, NaturalSortService } from '../utils/Utils';
import EventBlockValidationMixin from '../mixins/EventBlockValidationMixin';
import EventSpace from '../event/EventSpace';
import Multiselect from 'vue-multiselect';
import {
  emptyForAssetsConditionRange,
  emptyForTheSameAssetConditionRange,
  eventTriggerTypes,
} from '../utils/Constants';
import AlarmAndConditionRangePickerBlock from './AlarmAndConditionRangePickerBlock';
import EventConditionValueValidationMixin from '../mixins/EventConditionValueValidationMixin';
import EventBlockTextTypeMixin from '../mixins/EventBlockTextTypeMixin';

export default {
  name: 'AlarmAndConditionBlock',

  components: {
    AlarmAndConditionRangePickerBlock,
    EventSpace,
    Multiselect,
  },

  mixins: [EventBlockValidationMixin, EventConditionValueValidationMixin, EventBlockTextTypeMixin],

  props: {
    condition: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    triggerType: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      mode: 'FOR_THE_OBSERVATION',
      modes: ['FOR_THE_OBSERVATION', 'FOR_THE_SAME_ASSET', 'FOR_ASSETS'],
      options: [],
      originalOptions: [],
      isLoading: false,
      debounceTimeout: null,
      addingNewCondition: false,
      isRangeValid: true,
      isValueMultiple: true,
    };
  },

  computed: {
    isAlarmTriggerTypeObservation() {
      return this.triggerType === eventTriggerTypes.OBSERVATION;
    },
    isAlarmTriggerTypeTime() {
      return this.triggerType === eventTriggerTypes.TIME;
    },
    columnConditions() {
      if (this.condition.column && this.condition.column.conditions) {
        return this.condition.column.conditions;
      }
      return [];
    },
    isAssetColumn() {
      if (!this.condition.column || !this.condition.column.originalDataField) {
        return false;
      }

      return this.condition.column.originalDataField === 'name';
    },
    isCategoryColumn() {
      if (!this.condition.column || !this.condition.column.originalDataField) {
        return false;
      }

      return (
        this.condition.column.originalDataField.includes('group') &&
        this.condition.column.originalDataField.includes('_name')
      );
    },
    isGeofenceAreaColumn() {
      if (!this.condition.column || !this.condition.column.originalDataField) {
        return false;
      }

      return this.condition.column.originalDataField.includes('geofenceArea');
    },
    hasExistingConditionRange() {
      return !!this.condition && !!this.condition.conditionRange;
    },
    isConditionColumnDefined() {
      return this.condition && this.condition.column;
    },
    isAlarmTypeApi() {
      return this.isConditionColumnDefined && this.condition.column.alarmType === 'Api';
    },
    isAlarmTypeSuggestion() {
      return this.isConditionColumnDefined && this.condition.column.alarmType === 'Suggestion';
    },
    isAlarmTypeText() {
      return this.isConditionColumnDefined && this.condition.column.alarmType === 'Text';
    },
    isAlarmTypeSet() {
      return !!this.condition.column.alarmType;
    },
    isValid() {
      return (
        this.isAlarmTypeSet &&
        !!this.condition.operator.label &&
        this.validateValues(this.condition.values, this.condition.column.columnType) &&
        this.isRangeValid
      );
    },
    placeholder() {
      if (this.isAlarmTypeText) {
        if (this.condition.column.columnType === 'Number') {
          return this.$t('alarmsReports.vue.numberAndTextPlaceholder');
        }

        return this.$t('alarmsReports.vue.stringAndTextPlaceholder');
      }

      if (this.isAlarmTypeApi) {
        return this.$t('alarmsReports.vue.apiPlaceholder');
      }

      if (this.isAlarmTypeSuggestion) {
        return this.$t('alarmsReports.vue.suggestionPlaceholder');
      }

      return '';
    },
  },

  async mounted() {
    this.initMode();
    this.resetOptions();
    this.removeListIsEmptyLiFromMultiSelects();

    if (this.isCategoryColumn) {
      this.condition.values = this.condition.values.map((value) => {
        return { ...value, name: value.groupName };
      });
    }

    this.condition.values = this.condition.values.map((conditionValue) => {
      const text = conditionValue.text ? conditionValue.text.replace(/\*/g, '') : null;
      const name = conditionValue.name ? conditionValue.name.replace(/\*/g, '') : null;
      const value = conditionValue.value ? conditionValue.value.replace(/\*/g, '') : null;

      return { ...conditionValue, text, name, value };
    });

    this.$nextTick(() => {
      this.onOperatorChange();
      this.checkIfGeofenceAnyIsSelected();
    });
  },

  methods: {
    onTextValueChange() {
      this.$nextTick(() => {
        switch (this.condition.operator.operator) {
          case '<':
          case '<=':
          case '>':
          case '>=':
            this.condition.values = [{ ...this.condition.values }];
            break;
          default:
            break;
        }
      });
    },
    onValueChange() {
      this.$nextTick(() => {
        this.validateAssetIdLimit();

        if (this.isValueMultiple) {
          this.checkIfGeofenceAnyIsSelected();
        } else {
          this.isValueMultiple = true;
          this.condition.values = [{ ...this.condition.values }];
          this.focusOnValue();
        }
      });
    },
    checkIfGeofenceAnyIsSelected() {
      let geofenceFieldAttribute;

      switch (this.condition.column.dataField) {
        case 'geofenceArea':
          geofenceFieldAttribute = 'name';
          break;
        case 'geofenceType':
          geofenceFieldAttribute = 'text';
          break;
        default:
          return;
      }

      const geofenceFieldValueWithAny = this.condition.values.filter((conditionValue) => {
        return conditionValue[geofenceFieldAttribute] === 'generic.any';
      })[0];

      if (geofenceFieldValueWithAny && geofenceFieldValueWithAny[geofenceFieldAttribute]) {
        this.condition.values = [{ ...geofenceFieldValueWithAny }];
        this.blurValue();

        this.$nextTick(() => {
          this.isValueMultiple = false;
        });
      }
    },
    searchChangedForApi(query) {
      if (query && query.trim() !== '') {
        clearTimeout(this.debounceTimeout);

        const tag = {
          groupId: 0,
          groupName: query,
          name: query,
        };

        const multiselectFilteredOptions = this.$refs.valueRefApi.filteredOptions;

        if (multiselectFilteredOptions.some((option) => option.name === tag.name.trim())) {
          this.options = [...this.originalOptions];
        } else {
          this.options = [{ ...tag }, ...this.originalOptions];
        }
      } else {
        this.options = [...this.originalOptions];
      }
    },
    setRangeValidity(updatedValue) {
      this.isRangeValid = updatedValue;
    },
    onChangeMode() {
      this.isRangeValid = true;
      switch (this.mode) {
        case 'FOR_THE_OBSERVATION':
          this.condition.conditionRange = null;
          break;
        case 'FOR_THE_SAME_ASSET':
          this.condition.conditionRange = { ...emptyForTheSameAssetConditionRange };
          break;
        case 'FOR_ASSETS':
          this.condition.conditionRange = { ...emptyForAssetsConditionRange };
          break;
        default:
          throw Error('Unknown mode ' + this.mode);
      }
      this.$nextTick(() => {
        this.$refs.columnRef.$el.focus();
      });
    },
    initMode() {
      if (!this.condition.conditionRange) {
        this.mode = 'FOR_THE_OBSERVATION';
      } else if (
        this.condition.conditionRange.type === 'OBSERVATION' ||
        this.condition.conditionRange.type === 'DAY' ||
        this.condition.conditionRange.type === 'HOUR'
      ) {
        this.mode = 'FOR_THE_SAME_ASSET';
      } else if (this.condition.conditionRange.type === 'SUM') {
        this.mode = 'FOR_ASSETS';
      } else {
        throw Error('initMode failed');
      }
    },
    showErrors() {
      const errors = {};

      errors.column = !this.condition.column.alarmType;
      errors.values = !this.validateValues(this.condition.values, this.condition.column.columnType);

      this.errors = deepClone(errors);
    },
    getSuggestionsForQuery(query) {
      this.isLoading = true;

      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        await this.getSuggestions(this.condition.column, query);
        this.isLoading = false;
      }, 200);
    },
    async getSuggestions(column, query) {
      const suggestions = await EventService.getColumnSuggestions(column, query);
      const textKey = 'text';

      this.options = NaturalSortService.naturalSortArrayByKey(
        suggestions.filter((suggestion) => suggestion.text !== ''),
        textKey
      );

      if (column.dataField === 'geofenceType') {
        this.options.unshift({
          text: this.$t('generic.any'),
          filters: ['geofenceType=generic.any'],
        });
      }

      this.options = this.options.map((option) => {
        let translateKey = option.filters[0].split('=')[1];

        return {
          text: translateKey.replace(/\*/g, ''),
          filters: option.filters,
        };
      });
    },
    resetOperator() {
      this.showErrors();
      this.isValueMultiple = true;
      this.condition.operator = this.columnConditions[0];
      this.condition.values = [];
      this.resetOptions();
    },
    onOperatorChange() {
      this.$nextTick(() => {
        switch (this.condition.operator.operator) {
          case '<':
          case '<=':
          case '>':
          case '>=':
            this.isValueMultiple = false;
            if (this.condition.values.length > 0) {
              this.condition.values = [{ ...this.condition.values[0] }];
            }
            break;
          default:
            this.isValueMultiple = true;
            break;
        }
      });
    },
    focusOnValue() {
      let el;

      switch (this.condition.column.alarmType) {
        case 'Suggestion':
          el = this.$refs.valueRefSuggestion.$el;
          break;
        case 'Api':
          el = this.$refs.valueRefApi.$el;
          break;
        case 'Text':
        default:
          el = this.$refs.valueRefText.$el;
          break;
      }

      this.$nextTick(() => {
        el.focus();
      });
    },
    blurValue() {
      let el;

      switch (this.condition.column.alarmType) {
        case 'Suggestion':
          el = this.$refs.valueRefSuggestion;
          break;
        case 'Api':
          el = this.$refs.valueRefApi;
          break;
        case 'Text':
        default:
          el = this.$refs.valueRefText;
          break;
      }

      this.$nextTick(() => {
        el.$refs.search.blur();
      });
    },
    async resetOptions() {
      this.options = [];
      this.isLoading = true;
      switch (this.condition.column.alarmType) {
        case 'Suggestion':
          await this.getSuggestions(this.condition.column, '');
          break;

        case 'Api':
          await this.getApiOperationOptions(this.condition.column, '');
          break;

        case 'Text':
        default:
          break;
      }
      this.isLoading = false;
    },
    addTag(newTag) {
      const tag = {
        groupId: 0,
        groupName: newTag,
        name: newTag,
      };

      this.options.push(tag);
      this.condition.values.push(tag);
    },
    async getApiOperationOptions(column) {
      if (this.isAssetColumn) {
        this.options = await EventService.getAssets();
      } else if (this.isCategoryColumn) {
        const categoryIndex = this.getCategoryIndexFromColumn(column);
        const data = await EventService.getCategoryGroups(categoryIndex);
        this.options = data.groups.map((group) => {
          return { ...group, name: group.groupName };
        });
      } else if (this.isGeofenceAreaColumn) {
        const data = await EventService.getGeofenceAreas();
        const dataWithLabel = data.map((item) => ({ ...item, label: item.name }));

        this.options = NaturalSortService.naturalSortArrayByKey(dataWithLabel, 'name');
        this.options.unshift({
          name: 'generic.any',
        });
      }
      this.originalOptions = deepClone(this.options);
    },
    getAlarmTypeApiLabel(item) {
      return this.$t(item.name);
    },
    getCategoryIndexFromColumn(column) {
      let categoryIndex = column.originalDataField.replace('group', '');
      categoryIndex = categoryIndex.replace('_name', '');
      return parseInt(categoryIndex);
    },
    removeListIsEmptyLiFromMultiSelects() {
      const valueRefs = [this.$refs.valueRefSuggestion, this.$refs.valueRefText, this.$refs.valueRefApi];
      const listSelector = 'div.multiselect__content-wrapper ul';
      const listItemLastChildSelector = 'li:last-child';

      valueRefs.forEach((valueRef) => {
        const multiselect = valueRef.$el;
        const parentUl = multiselect.querySelector(listSelector);
        const emptyListItemLi = parentUl.querySelector(listItemLastChildSelector);
        parentUl.removeChild(emptyListItemLi);
      });
    },
    addQueryAsOnlyResult(query) {
      if (query === '') {
        this.options = [];
      } else {
        this.options = [{ value: query }];
      }
    },
    onDelete() {
      this.$emit('delete-condition');
    },
  },
};
</script>
