<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h4 class="panel-title">
        {{ $t('assetInformation.vue.comments') }}
      </h4>
    </div>
    <div class="panel-body">
      <div v-if="loading" class="comments-zone">
        <div class="loader">{{ $t('generic.loading') }}</div>
      </div>
      <div v-else class="comments-zone">
        <div v-if="comments.length === 0" data-cy="commentsNotFound">
          {{ $t('assetInformation.vue.preview.noComments') }}
        </div>
        <div v-else :class="tableWrapper">
          <table class="table table-striped table-hover table-condensed table-bordered" data-cy="commentsTable">
            <thead>
              <tr>
                <th>{{ $t('assetInformation.vue.tableHeader.comment') }}</th>
                <th class="table-width-12">{{ $t('assetInformation.vue.tableHeader.addedOn') }}</th>
                <th class="table-width-20">{{ $t('assetInformation.vue.tableHeader.addedBy') }}</th>
                <th class="th-min-width"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(comm, index) in comments" :key="index">
                <td class="break-word">{{ comm.commentText }}</td>
                <td>{{ formatDate(comm.creationDate) }}</td>
                <td>{{ comm.authorId || $t('alarmReports.deletedUser') }}</td>
                <td>
                  <v-popover v-if="checkPermissions(comm)" offset="16" placement="right">
                    <div class="tooltip-target" data-cy="commentDeleteButton">
                      <i
                        class="glyphicons glyphicons-bin before-black pointer"
                        :title="$t('generic.delete') + ': ' + deleteText(comm)"
                      ></i>
                    </div>
                    <template slot="popover">
                      <h3 class="popover-title">
                        {{ $t('generic.delete') + ': ' + deleteText(comm) }}
                      </h3>
                      <div class="popover-content">
                        <button v-close-popover="true" class="btn btn-fm-std inline-block right-buffer">
                          {{ $t('value.false') }}
                        </button>
                        <button
                          class="btn btn-fm-std inline-block"
                          data-cy="popoverSubmitButton"
                          @click="removeComment(index, comm.id)"
                        >
                          {{ $t('value.true') }}
                        </button>
                      </div>
                    </template>
                  </v-popover>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <form v-if="!permissions.isDisabled" class="footer" @submit.prevent="saveComment">
          <div class="has-no-padding-left input-button-same-row">
            <input
              id="comment"
              v-model="commentText"
              autocomplete="off"
              :placeholder="$t('generic.maxLength256')"
              type="text"
              :class="['form-control', commentRequired ? 'comment-not-selected' : '']"
              maxlength="256"
              data-cy="commentInput"
            />
            <button
              class="btn pull-right btn-success"
              :title="$t('assetInformation.vue.saveComment')"
              data-cy="commentSubmitButton"
            >
              <i class="glyphicons glyphicons-plus"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AssetInformationMixin from '../mixins/AssetInformationMixin';
import { VPopover } from 'v-tooltip';

export default {
  name: 'AssetInformationComment',
  components: {
    VPopover,
  },
  mixins: [AssetInformationMixin],
  props: {
    comments: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
    me: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      commentText: '',
      loading: false,
      commentRequired: false,
    };
  },
  computed: {
    tableWrapper() {
      if (this.permissions.isDisabled) {
        return 'scrollable-table-preview';
      }
      return 'scrollable-table';
    },
  },
  watch: {
    commentText(newValue) {
      if (newValue) {
        this.commentRequired = false;
      }
      return newValue;
    },
  },
  methods: {
    deleteText(comment) {
      let text = comment.commentText.substr(0, 10);
      if (text.length > 9) {
        text = text + '...';
      }
      return text;
    },
    async saveComment() {
      if (!this.commentText) {
        this.commentRequired = true;
        return;
      }
      this.loading = true;
      await this.$emit('saveComment', this.commentText.trim());
      this.commentText = '';
      setTimeout(() => (this.loading = false), 1000);
    },
    removeComment(index, commentId) {
      this.loading = true;
      this.$emit('removeComment', index, commentId);
      setTimeout(() => (this.loading = false), 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 0;
}
.break-word {
  word-break: break-word;
}
.scrollable-table {
  max-height: 255px;
  display: block;
  overflow: auto;
}
.scrollable-table-preview {
  max-height: 300px;
  display: block;
  overflow: auto;
}
.btn {
  padding: 3px 6px;
}
.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 7px;
}
.comments-zone {
  height: 300px;
  position: relative;
}
.comment-not-selected {
  outline: 1px solid red !important;
  border-color: red !important;
}
.input-button-same-row {
  display: flex;
  justify-content: space-between;
  margin: -10px;
  & > * {
    margin: 10px;
  }
}

.table-width-12 {
  width: 12%;
}

.table-width-20 {
  width: 20%;
}

input {
  color: black;
}
</style>
